import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Group, Loader, Stack, Text, useMantineTheme, } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { CheckGreen } from "@hl/shared-features/lib/assets/icons.generated";
import { TransactionStatus, TransactionType, } from "../../apollo/graphql.generated";
import { buildTxUrl, networkLookup } from "../../utils/blockExplorer";
import ApproveTransferDescription from "./Description/ApproveTransfer";
import ApproveTransferWrappedDescription from "./Description/ApproveTransferWrapped";
import AuctionTransactionDescription from "./Description/AuctionTransaction";
import BidDescription from "./Description/Bid";
import CrosschainBurn from "./Description/CrosschainBurn";
import CurrencyAllowance from "./Description/CurrencyAllowance";
import DeployDescription from "./Description/Deploy";
import DepositFunds from "./Description/DepositFunds";
import MintDescription from "./Description/Mint";
import Royalty from "./Description/Royalty";
import SaleDescription from "./Description/Sale";
import SponsoredMintDescription from "./Description/SponsoredMintDescription";
import SwapCurrencyDescription from "./Description/SwapCurrency";
import TransferDescription from "./Description/Transfer";
import UpdateMetadataDescription from "./Description/UpdateMetadata";
const TxHistoryItem = ({ operation, manageCollectionUrl, mintTokenUrl, }) => {
    const theme = useMantineTheme();
    const status = ({ status }) => {
        if (status === "PENDING") {
            return (_jsx(Text, { size: "xs", weight: 500, color: STATUS_COLOR.WARNING, children: "Pending" }));
        }
        else if (status === "MINED") {
            return (_jsx(Text, { size: "xs", weight: 500, color: STATUS_COLOR.AFFIRMATIVE, children: "Completed" }));
        }
        else if (status === "REJECTED") {
            return (_jsx(Text, { size: "xs", weight: 500, color: STATUS_COLOR.ERROR, children: "Rejected" }));
        }
        else {
            return _jsx(Text, { children: "Unknown" });
        }
    };
    const timestamp = ({ status, createdAt, updatedAt }) => {
        if (status === "PENDING") {
            const date = new Date(Date.parse(createdAt));
            return (_jsxs(_Fragment, { children: ["Started", " ", _jsx(ReactTimeAgo, { date: date, timeStyle: "round-minute", locale: "en-US" })] }));
        }
        else if (status === "MINED") {
            const date = new Date(Date.parse(updatedAt));
            return (_jsxs(_Fragment, { children: ["Completed", " ", _jsx(ReactTimeAgo, { date: date, timeStyle: "round-minute", locale: "en-US" })] }));
        }
        else if (status === "REJECTED") {
            const date = new Date(Date.parse(updatedAt));
            return (_jsxs(_Fragment, { children: ["Failed", " ", _jsx(ReactTimeAgo, { date: date, timeStyle: "round-minute", locale: "en-US" })] }));
        }
    };
    const description = ({ status, chainId, transaction, evmDeployCollectionMetadata, evm721MintMetadata, evm721BidMetadata, evm721AuctionMetadata, evmUpdateRoyaltyMetadata, evmUpdateCollectionMetadata, evmMintCreatorReservesMetadata, evmTransferMetadata, evmDepositFundsMetadata, evmApproveTransferMetadata, evmApproveTransferWrappedMetadata, evmSaleMetadata, evmCurrencyAllowanceMetadata, evmSponsoredMintMetadata, evmCrosschainBurnMetadata, evmSwapCurrencyMetadata, type, }) => {
        var _a, _b, _c;
        if (evmDeployCollectionMetadata === null || evmDeployCollectionMetadata === void 0 ? void 0 : evmDeployCollectionMetadata.collectionId) {
            return (_jsx(DeployDescription, { chainId: chainId, collectionId: evmDeployCollectionMetadata.collectionId, status: status }));
        }
        else if (evm721MintMetadata === null || evm721MintMetadata === void 0 ? void 0 : evm721MintMetadata.collectionId) {
            return (_jsx(MintDescription, { chainId: chainId, collectionId: evm721MintMetadata.collectionId, status: status }));
        }
        else if (evm721BidMetadata === null || evm721BidMetadata === void 0 ? void 0 : evm721BidMetadata.collectionId) {
            return (_jsx(BidDescription, { chainId: chainId, collectionId: evm721BidMetadata.collectionId, status: status }));
        }
        else if (evm721AuctionMetadata === null || evm721AuctionMetadata === void 0 ? void 0 : evm721AuctionMetadata.collectionId) {
            return (_jsx(AuctionTransactionDescription, { chainId: chainId, collectionId: evm721AuctionMetadata.collectionId, status: status }));
        }
        else if (evmUpdateRoyaltyMetadata === null || evmUpdateRoyaltyMetadata === void 0 ? void 0 : evmUpdateRoyaltyMetadata.collectionId) {
            return (_jsx(Royalty, { chainId: chainId, collectionId: evmUpdateRoyaltyMetadata.collectionId, status: status }));
        }
        else if (evmUpdateCollectionMetadata === null || evmUpdateCollectionMetadata === void 0 ? void 0 : evmUpdateCollectionMetadata.collectionId) {
            if (type === TransactionType.EVM_DUTCH_AUCTION_CLAIM_FUNDS) {
            }
            return (_jsx(UpdateMetadataDescription, { chainId: chainId, collectionId: evmUpdateCollectionMetadata.collectionId, status: status, type: type }));
        }
        else if (evmMintCreatorReservesMetadata === null || evmMintCreatorReservesMetadata === void 0 ? void 0 : evmMintCreatorReservesMetadata.collectionId) {
            return (_jsx(MintDescription, { chainId: chainId, collectionId: evmMintCreatorReservesMetadata.collectionId, status: status }));
        }
        else if (evmTransferMetadata === null || evmTransferMetadata === void 0 ? void 0 : evmTransferMetadata.collectionId) {
            return (_jsx(TransferDescription, { collectionId: evmTransferMetadata.collectionId, status: status, tokenId: evmTransferMetadata.tokenId, fromAddress: evmTransferMetadata.fromAddress, toAddress: evmTransferMetadata.toAddress }));
        }
        else if (evmSaleMetadata === null || evmSaleMetadata === void 0 ? void 0 : evmSaleMetadata.collectionId) {
            return (_jsx(SaleDescription, { collectionId: evmSaleMetadata.collectionId, status: status, fromAddress: transaction.from, toAddress: (_a = transaction.to) !== null && _a !== void 0 ? _a : undefined, tokenId: evmSaleMetadata.tokenId }));
        }
        else if (evmApproveTransferMetadata === null || evmApproveTransferMetadata === void 0 ? void 0 : evmApproveTransferMetadata.nftContractAddress) {
            return (_jsx(ApproveTransferDescription, { status: status, collectionId: evmApproveTransferMetadata.collectionId }));
        }
        else if (evmApproveTransferWrappedMetadata === null || evmApproveTransferWrappedMetadata === void 0 ? void 0 : evmApproveTransferWrappedMetadata.symbol) {
            return (_jsx(ApproveTransferWrappedDescription, { status: status, symbol: evmApproveTransferWrappedMetadata === null || evmApproveTransferWrappedMetadata === void 0 ? void 0 : evmApproveTransferWrappedMetadata.symbol }));
        }
        else if (evmDepositFundsMetadata === null || evmDepositFundsMetadata === void 0 ? void 0 : evmDepositFundsMetadata.amount) {
            return (_jsx(DepositFunds, { amount: evmDepositFundsMetadata === null || evmDepositFundsMetadata === void 0 ? void 0 : evmDepositFundsMetadata.amount, status: status, symbol: evmDepositFundsMetadata.symbol }));
        }
        else if (evmCurrencyAllowanceMetadata) {
            return (_jsx(CurrencyAllowance, { status: status, currency: evmCurrencyAllowanceMetadata.currency }));
        }
        else if (evmSponsoredMintMetadata) {
            return (_jsx(SponsoredMintDescription, { collectionId: (_b = evmSponsoredMintMetadata.collectionId) !== null && _b !== void 0 ? _b : "", chainId: chainId, status: status }));
        }
        else if (evmCrosschainBurnMetadata) {
            return (_jsx(CrosschainBurn, { collectionId: (_c = evmCrosschainBurnMetadata.collectionId) !== null && _c !== void 0 ? _c : "", chainId: chainId, status: status }));
        }
        else if (evmSwapCurrencyMetadata) {
            return (_jsx(SwapCurrencyDescription, { amount: evmSwapCurrencyMetadata.amount, outputAmount: evmSwapCurrencyMetadata.outputAmount, toCurrencySymbol: evmSwapCurrencyMetadata.toCurrencySymbol, fromCurrencySymbol: evmSwapCurrencyMetadata.fromCurrencySymbol, fromChain: evmSwapCurrencyMetadata.fromChain, toChain: evmSwapCurrencyMetadata.toChain, isWrapping: evmSwapCurrencyMetadata.isWrapping, isSwapping: evmSwapCurrencyMetadata.isSwapping, status: status }));
        }
        else {
            console.log(evmDepositFundsMetadata);
            return "Unknown transaction type";
        }
    };
    const txExplorerUrl = ({ chainId, txHash }) => {
        return buildTxUrl(networkLookup(chainId).type, txHash);
    };
    const internalOrExternalAnchor = (url, children) => {
        if (url.startsWith("http")) {
            return (_jsx(Anchor, { href: url, target: "_blank", children: children }));
        }
        else {
            return (_jsx(Anchor, { component: Link, to: url, children: children }));
        }
    };
    const viewTokenLink = ({ evm721MintMetadata, evmDeployCollectionMetadata, }) => {
        if (evmDeployCollectionMetadata === null || evmDeployCollectionMetadata === void 0 ? void 0 : evmDeployCollectionMetadata.collectionId) {
            const url = manageCollectionUrl(evmDeployCollectionMetadata.collectionId);
            const text = (_jsx(Text, { size: "xs", weight: WEIGHT_BOLD, children: "View token" }));
            return internalOrExternalAnchor(url, text);
        }
        else if (evm721MintMetadata === null || evm721MintMetadata === void 0 ? void 0 : evm721MintMetadata.collectionId) {
            const url = mintTokenUrl(evm721MintMetadata.collectionId, evm721MintMetadata.mintedTokenIds);
            const hasMultipleTokenIds = evm721MintMetadata.mintedTokenIds &&
                evm721MintMetadata.mintedTokenIds.length > 1;
            const text = (_jsx(Text, { size: "xs", weight: WEIGHT_BOLD, children: hasMultipleTokenIds ? "View first token" : "View token" }));
            return internalOrExternalAnchor(url, text);
        }
        else {
            return _jsx(_Fragment, {});
        }
    };
    const icon = ({ status }) => {
        if (status === "PENDING") {
            return _jsx(Loader, { color: STATUS_COLOR.WARNING, size: 16 });
        }
        else if (status === "MINED") {
            return _jsx(CheckGreen, { height: 16, width: 16 });
        }
        else if (status === "REJECTED") {
            return (_jsx(IconAlertTriangle, { color: theme.colors.errorStatus[0], size: 16 }));
        }
    };
    return (_jsx("div", { children: _jsxs(Stack, { spacing: "xs", children: [_jsxs(Group, { position: "apart", children: [_jsxs(Group, { spacing: "xs", children: [icon(operation), status(operation)] }), _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, weight: 400, children: timestamp(operation) })] }), _jsx(Text, { size: "sm", lineClamp: 3, children: description(operation) }), _jsx(Text, { weight: 500, size: "sm", children: _jsx(Group, { children: _jsxs(_Fragment, { children: [operation.status !== TransactionStatus.PENDING &&
                                    viewTokenLink(operation), _jsx(Anchor, { href: txExplorerUrl(operation), target: "_blank", rel: "noreferrer", children: _jsx(Text, { size: "xs", weight: WEIGHT_BOLD, children: "View transaction" }) })] }) }) })] }) }, operation.txHash));
};
export default TxHistoryItem;
