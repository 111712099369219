import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type RefreshCollectionMetadataMutationVariables = Types.Exact<{
  address: Types.Scalars["String"];
  chainId: Types.Scalars["Float"];
  editionId: Types.Scalars["String"];
}>;

export type RefreshCollectionMetadataMutation = {
  refreshCollectionMetadata: boolean;
};

export type RefreshTokenMetadataMutationVariables = Types.Exact<{
  address: Types.Scalars["String"];
  chainId: Types.Scalars["Float"];
  editionId: Types.Scalars["String"];
  tokenId: Types.Scalars["String"];
}>;

export type RefreshTokenMetadataMutation = { refreshTokenMetadata: boolean };

export const RefreshCollectionMetadataDocument = gql`
  mutation RefreshCollectionMetadata(
    $address: String!
    $chainId: Float!
    $editionId: String!
  ) {
    refreshCollectionMetadata(
      address: $address
      chainId: $chainId
      editionId: $editionId
    )
  }
`;
export type RefreshCollectionMetadataMutationFn = Apollo.MutationFunction<
  RefreshCollectionMetadataMutation,
  RefreshCollectionMetadataMutationVariables
>;

/**
 * __useRefreshCollectionMetadataMutation__
 *
 * To run a mutation, you first call `useRefreshCollectionMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshCollectionMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshCollectionMetadataMutation, { data, loading, error }] = useRefreshCollectionMetadataMutation({
 *   variables: {
 *      address: // value for 'address'
 *      chainId: // value for 'chainId'
 *      editionId: // value for 'editionId'
 *   },
 * });
 */
export function useRefreshCollectionMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshCollectionMetadataMutation,
    RefreshCollectionMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshCollectionMetadataMutation,
    RefreshCollectionMetadataMutationVariables
  >(RefreshCollectionMetadataDocument, options);
}
export type RefreshCollectionMetadataMutationHookResult = ReturnType<
  typeof useRefreshCollectionMetadataMutation
>;
export type RefreshCollectionMetadataMutationResult =
  Apollo.MutationResult<RefreshCollectionMetadataMutation>;
export type RefreshCollectionMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    RefreshCollectionMetadataMutation,
    RefreshCollectionMetadataMutationVariables
  >;
export const RefreshTokenMetadataDocument = gql`
  mutation RefreshTokenMetadata(
    $address: String!
    $chainId: Float!
    $editionId: String!
    $tokenId: String!
  ) {
    refreshTokenMetadata(
      address: $address
      chainId: $chainId
      editionId: $editionId
      tokenId: $tokenId
    )
  }
`;
export type RefreshTokenMetadataMutationFn = Apollo.MutationFunction<
  RefreshTokenMetadataMutation,
  RefreshTokenMetadataMutationVariables
>;

/**
 * __useRefreshTokenMetadataMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMetadataMutation, { data, loading, error }] = useRefreshTokenMetadataMutation({
 *   variables: {
 *      address: // value for 'address'
 *      chainId: // value for 'chainId'
 *      editionId: // value for 'editionId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useRefreshTokenMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMetadataMutation,
    RefreshTokenMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMetadataMutation,
    RefreshTokenMetadataMutationVariables
  >(RefreshTokenMetadataDocument, options);
}
export type RefreshTokenMetadataMutationHookResult = ReturnType<
  typeof useRefreshTokenMetadataMutation
>;
export type RefreshTokenMetadataMutationResult =
  Apollo.MutationResult<RefreshTokenMetadataMutation>;
export type RefreshTokenMetadataMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMetadataMutation,
  RefreshTokenMetadataMutationVariables
>;
