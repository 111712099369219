import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect } from "react";
import { useAccount, useNetwork, useSwitchNetwork, useWalletClient, } from "wagmi";
import { TransactionType } from "../../apollo/graphql.generated";
import CrossChainTransactionExecution from "./CrossChainTransactionExecution";
import RelayerTransactionExecution from "./RelayerTransactionExecution";
import SwapCurrencyTransactionExecution from "./SwapCurrencyTransactionExecution";
import TransactionConfirmation from "./TransactionConfirmation";
import { useTransactionsDispatch, } from "./TransactionContext";
import TransactionContractWriteExecution from "./TransactionContractWriteExecution";
import TransactionDeployExecution from "./TransactionDeployExecution";
const ReservoirTransactionTypes = [
    TransactionType.EVM_DEPOSIT_FUNDS,
    TransactionType.EVM_TOKEN_SALE,
    TransactionType.EVM_APPROVE_TRANSFER,
    TransactionType.EVM_APPROVE_TRANSFER_WRAPPED,
];
const TransactionLifecycle = ({ id, state }) => {
    var _a, _b, _c;
    const { data: walletClient } = useWalletClient();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { isLoading, pendingChainId, switchNetwork, status: switchStatus, } = useSwitchNetwork();
    const dispatch = useTransactionsDispatch();
    const relayerTaskId = state.relayerTaskId;
    const requiredChainId = (_a = state.fromChain) !== null && _a !== void 0 ? _a : (_b = state.args) === null || _b === void 0 ? void 0 : _b.chainId;
    const currentChainId = relayerTaskId ? requiredChainId : chain === null || chain === void 0 ? void 0 : chain.id;
    const destinationChainId = (_c = state.args) === null || _c === void 0 ? void 0 : _c.chainId;
    const isReservoirTx = ReservoirTransactionTypes.includes(state.transactionType);
    useEffect(() => {
        if (currentChainId &&
            requiredChainId &&
            currentChainId !== requiredChainId &&
            !relayerTaskId // don't prompt network switch if mint has a gasless relayer
        ) {
            switchNetwork === null || switchNetwork === void 0 ? void 0 : switchNetwork(requiredChainId);
        }
    }, [currentChainId, requiredChainId]);
    useEffect(() => {
        if (!dispatch) {
            return;
        }
        if (requiredChainId && switchStatus === "error") {
            dispatch({
                type: "CHAIN_SWITCH_ERROR",
                payload: {
                    id,
                    chainId: requiredChainId,
                },
            });
        }
    }, [id, dispatch, switchStatus]);
    if (!state.args)
        return _jsx(_Fragment, {});
    const transactionConfirmation = state.hash && state.metadata ? (_jsx(TransactionConfirmation, { id: id, hash: state.hash, metadata: state.metadata, transactionType: state.transactionType, chainId: state.args.chainId, crossChainId: state.crossChainId, relayerId: relayerTaskId })) : null;
    if (isReservoirTx) {
        return transactionConfirmation;
    }
    if (!walletClient || !chain || !address)
        return _jsx(_Fragment, {});
    if (!currentChainId ||
        !destinationChainId ||
        !requiredChainId ||
        currentChainId !== requiredChainId)
        return _jsx(_Fragment, {});
    if (relayerTaskId) {
        return (_jsxs(_Fragment, { children: [_jsx(RelayerTransactionExecution, { id: id, type: state.type, transactionType: state.transactionType, collectionType: state.collectionType, args: state.args, relayerTaskId: relayerTaskId, chainId: destinationChainId }), transactionConfirmation] }));
    }
    if (isLoading && pendingChainId === requiredChainId)
        return _jsx(_Fragment, {});
    if (state.fromChain &&
        state.fromChain !== destinationChainId &&
        state.transactionType !== TransactionType.EVM_CROSSCHAIN_BURN &&
        state.transactionType !== TransactionType.EVM_SWAP_CURRENCY)
        return (_jsxs(_Fragment, { children: [_jsx(CrossChainTransactionExecution, { id: id, address: address, type: state.type, transactionType: state.transactionType, collectionType: state.collectionType, walletClient: walletClient, args: state.args, fromChain: state.fromChain }), transactionConfirmation] }));
    if (state.transactionType === TransactionType.EVM_SWAP_CURRENCY) {
        return (_jsxs(_Fragment, { children: [_jsx(SwapCurrencyTransactionExecution, { id: id, address: address, type: state.type, transactionType: state.transactionType, walletClient: walletClient, args: state.args, fromCurrencyAddress: state.fromCurrencyAddress, fromChain: state.fromChain, fromValue: state.fromValue }), transactionConfirmation] }));
    }
    if (state.args.bytecode)
        return (_jsxs(_Fragment, { children: [_jsx(TransactionDeployExecution, { id: id, address: address, type: state.type, collectionType: state.collectionType, walletClient: walletClient, args: state.args }), transactionConfirmation] }));
    return (_jsxs(_Fragment, { children: [_jsx(TransactionContractWriteExecution, { id: id, address: address, type: state.type, transactionType: state.transactionType, collectionType: state.collectionType, contractType: state.contractType, walletClient: walletClient, args: state.args, chainId: destinationChainId }), transactionConfirmation] }));
};
export default TransactionLifecycle;
