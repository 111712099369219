import { createClient, convertViemChainToRelayChain, TESTNET_RELAY_API, MAINNET_RELAY_API, } from "@reservoir0x/relay-sdk";
import { sepolia, baseSepolia, mainnet, base, arbitrum, optimism, zora, scroll, zkSync, } from "wagmi/chains";
import { forma, shape, cyber, zero } from "../eth/providers/privy/customChains";
export const getRelayClient = (chainId) => {
    const testnetIds = [84532, 11155111];
    return testnetIds.includes(chainId)
        ? createClient({
            baseApiUrl: TESTNET_RELAY_API,
            source: "Highlight",
            chains: [
                convertViemChainToRelayChain(sepolia),
                convertViemChainToRelayChain(baseSepolia),
            ],
        })
        : createClient({
            baseApiUrl: MAINNET_RELAY_API,
            source: "Highlight",
            chains: [
                convertViemChainToRelayChain(mainnet),
                convertViemChainToRelayChain(base),
                convertViemChainToRelayChain(arbitrum),
                convertViemChainToRelayChain(optimism),
                convertViemChainToRelayChain(zora),
                convertViemChainToRelayChain(scroll),
                convertViemChainToRelayChain(forma),
                convertViemChainToRelayChain(zkSync),
                convertViemChainToRelayChain(shape),
                convertViemChainToRelayChain(cyber),
                convertViemChainToRelayChain(zero),
            ],
        });
};
