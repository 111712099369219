import { useBalance } from "wagmi";
import { CurrencyType } from "./BlockchainSelect";
export const useCurrencyBalance = ({ walletAddress, currency, chainId, }) => {
    const { data, isFetching, refetch } = useBalance({
        address: walletAddress,
        cacheTime: 60000,
        enabled: !!chainId && !!walletAddress,
        token: (currency === null || currency === void 0 ? void 0 : currency.type) === CurrencyType.NATIVE || !currency
            ? undefined
            : currency === null || currency === void 0 ? void 0 : currency.address,
        chainId,
    });
    const balanceRounded = (balance, digits = 3) => {
        if (!balance)
            return "0";
        const balanceFormatted = parseFloat(balance);
        if (balanceFormatted === 0)
            return "0";
        if (digits <= 3 && balanceFormatted < 0.001)
            return "<0.001";
        return balanceFormatted.toFixed(digits);
    };
    return {
        isFetching,
        balanceFormatted: data === null || data === void 0 ? void 0 : data.formatted,
        balanceNumber: data ? parseFloat(data === null || data === void 0 ? void 0 : data.formatted) : undefined,
        balanceRoundedSmall: balanceRounded(data === null || data === void 0 ? void 0 : data.formatted, 3),
        balanceRoundedLarge: balanceRounded(data === null || data === void 0 ? void 0 : data.formatted, 8),
        refetch,
    };
};
