import { useReactiveVar } from "@apollo/client";
import { identityVar } from "../vars";
export const useFeatureFlags = (flag, defaultValue) => {
    const identity = useReactiveVar(identityVar);
    /**
     * identity can be null, since we may be using this hook in other hooks
     * this means at app start, we have a short window of time where we default to null for feature flags
     * before we finish fetching the anonymous query
     */
    if (!identity) {
        return defaultValue;
    }
    const variations = identity.flagVariations;
    if (!variations) {
        return defaultValue !== undefined ? defaultValue : false;
    }
    return variations[flag];
};
export var FEATURE_FLAGS;
(function (FEATURE_FLAGS) {
    FEATURE_FLAGS["DISABLE_PAGE_CREATE"] = "gatesDisablePageCreate";
    FEATURE_FLAGS["DISABLE_TOKEN_TESTNETS"] = "gatesDisableTokenTestnets";
    FEATURE_FLAGS["EDITOR_DISABLE_HTML_PLUGIN"] = "gatesEditorDisableHTMLPlugin";
    FEATURE_FLAGS["ENABLE_721M_COLLECTIONS"] = "collections721mEnableFeature";
    FEATURE_FLAGS["CREATE_721M_COLLECTIONS"] = "contractsCollections721mCreate";
    FEATURE_FLAGS["GREEK_DEMO_USE_S3"] = "gatesEnableS3Upload";
    FEATURE_FLAGS["EMBEDS_SHOW_MODAL"] = "embedsShowModal";
    FEATURE_FLAGS["SHOW_LOCKED_PREVIEW_TOGGLE_IN_TOOLS"] = "showLockedPreviewToggleInTools";
    FEATURE_FLAGS["SHOW_GATE_BY_TOKEN_ATTRIBUTE"] = "gateByTokenAttribute";
    FEATURE_FLAGS["ENABLE_GATED_MINTS"] = "gatedMints";
    FEATURE_FLAGS["IMPORT_COLLECTIONS"] = "importCollections";
    FEATURE_FLAGS["CREATE_GATE_FROM_IMPORTED_COLLECTION"] = "createGateFromImportedCollection";
    FEATURE_FLAGS["CREATE_GATE_FROM_IMPORTED_TOKEN"] = "createGateFromImportedToken";
    FEATURE_FLAGS["SHOW_AUDIENCE_TAB"] = "showAudienceTab";
    FEATURE_FLAGS["ENABLE_AUCTIONS"] = "enableAuctions";
    FEATURE_FLAGS["SHOW_ACCOUNT_SETTINGS_ON_FRONTEND"] = "showAccountSettingsOnFrontend";
    FEATURE_FLAGS["SHOW_CREATE_EMAIL_GATE"] = "showCreateEmailGate";
    FEATURE_FLAGS["SHOW_MINT_PAGE_COLLECTORS"] = "showMintPageCollectors";
    FEATURE_FLAGS["ENABLE_MULTIPLE_PORTFOLIOS"] = "enableMultiplePortfolios";
    FEATURE_FLAGS["ENABLE_INHOTIM_NEW_COLLECTION_UX"] = "enableInhotimCreateUx";
    FEATURE_FLAGS["ENABLE_INHOTIM_DRAFTS"] = "enableInhotimDrafts";
    FEATURE_FLAGS["ENABLE_INHOTIM_SERIES"] = "enableInhotimSeriesCollections";
    FEATURE_FLAGS["ENABLE_INHOTIM_NEW_DEPLOY"] = "useNewContractDeploy";
    FEATURE_FLAGS["ENABLE_INHOTIM_CUSTOM_CONTRACTS"] = "enableContractCustomization";
    FEATURE_FLAGS["SHOW_DEBUG_PAGES"] = "showDebugPages";
    FEATURE_FLAGS["SKIP_SERIES_ZIP_VALIDATION"] = "skipFrontendValidation";
    FEATURE_FLAGS["SHOW_SERIES_UPLOAD_ZIP_PREVIEW"] = "showUploadZipPreview";
    FEATURE_FLAGS["AUDIENCE_TAB_V2"] = "audienceTabV2";
    FEATURE_FLAGS["SHOW_DARK_MODE_SETTINGS"] = "showDarkModeSettings";
    FEATURE_FLAGS["ENABLE_SERIES_METADATA_UPDATES"] = "seriesMetadataUpdates";
    FEATURE_FLAGS["ENABLE_GEN_SERIES_CURATED_OUTPUT"] = "enableGenSeriesCuratedOutput";
    FEATURE_FLAGS["ENABLE_DUTCH_AUCTIONS_CREATION"] = "enableDutchAuctionsCreation";
    FEATURE_FLAGS["ENABLE_RANKED_AUCTIONS"] = "enableRankedAuctions";
    FEATURE_FLAGS["ENABLE_DIRECT_MINTING"] = "enableDirectMinting";
    FEATURE_FLAGS["ENABLE_CROSSMINT_PAYMENTS"] = "crossmintPayments";
    FEATURE_FLAGS["ENABLE_CROSSMINT_SELF_SERVE"] = "crossmintSelfServe";
    FEATURE_FLAGS["ENABLE_USER_PROFILE"] = "enableUserProfile";
    FEATURE_FLAGS["ENABLE_NEW_TOOLS_DASHBOARD"] = "enableNewToolsDashboard";
    FEATURE_FLAGS["SHOW_GPU_SUPPORT_TOGGLE"] = "showGPUSupportToggle";
    FEATURE_FLAGS["SHOW_CAPTURE_SELECTOR_TYPE"] = "showCaptureSelectorType";
    FEATURE_FLAGS["ENABLE_MINT_REVEAL_PAGE"] = "enableMintRevealPage";
    FEATURE_FLAGS["SHOW_COLLECTIONS_CONTEXT_MENU"] = "showCollectionsContextMenu";
    FEATURE_FLAGS["TRENDING_MODULE_FREE_MINTS_FILTER"] = "trendingModuleFreeMintsFilter";
    FEATURE_FLAGS["TRENDING_MODULE_24_HOURS_FILTER"] = "trendingModule24HoursFilter";
    FEATURE_FLAGS["TRENDING_MODULE_MINTED_COLUMN"] = "trendingModuleMintedColumn";
    FEATURE_FLAGS["ZIP_INCREASE_FILE_SIZE_LIMIT"] = "zipIncreaseFileSizeLimit";
    FEATURE_FLAGS["ENABLE_NAVBAR_SEARCH"] = "enableNavbarSearch";
    FEATURE_FLAGS["ENABLE_EDIT_COLLECTION_DETAILS"] = "enableEditCollectionDetails";
    FEATURE_FLAGS["MARKETPLACE_PAGE_OPTIMIZATION"] = "marketplacePageOptimization";
    FEATURE_FLAGS["GATE_BY_FARCASTER_FOLLOWERS"] = "gateByFarcasterFollowers";
    FEATURE_FLAGS["MINT_PAGE_LOAD_OPTIMIZATION"] = "mintPageLoadOptimization";
    FEATURE_FLAGS["CREATE_COLLECTIONS_WITHOUT_MINT_VECTORS"] = "createCollectionsWithoutMintVectors";
    FEATURE_FLAGS["IMPORT_EVM_COLLECTIONS"] = "importEvmCollections";
    FEATURE_FLAGS["APOLLO_MINT_PAGE_UPDATE"] = "apolloMintPageUpdate";
    FEATURE_FLAGS["ENABLE_CREATOR_REWARDS"] = "enableCreatorRewards";
    FEATURE_FLAGS["ENABLE_ERC20_CURRENCIES"] = "enableDegenCurrency";
    FEATURE_FLAGS["SHOW_TESTNET_CURRENCIES"] = "showTestnetCurrencies";
    FEATURE_FLAGS["ENABLE_REFERRALS"] = "enableReferrals";
    FEATURE_FLAGS["ENABLE_SPONSORED_MINTS"] = "enableSponsoredMints";
    FEATURE_FLAGS["ENABLE_FORMA_COLLECTIONS"] = "enableFormaCollections";
    FEATURE_FLAGS["ENABLE_V3_API"] = "enableV3API";
    FEATURE_FLAGS["ENABLE_TRANSFERS"] = "enableTransfers";
    FEATURE_FLAGS["CALENDAR_WATCH"] = "calendarWatch";
    FEATURE_FLAGS["ENABLE_TAGS_AND_CATEGORIES"] = "enableTagsAndCategories";
    FEATURE_FLAGS["CURATED_SPACES_PREVIEW"] = "curatedSpacesPreview";
    FEATURE_FLAGS["ENABLE_HIGHLIGHTED_NOTES"] = "enableHighlightedNotes";
    FEATURE_FLAGS["ENABLE_TAGS_AND_CATEGORIES_FOR_TESTNET"] = "enableTagsCategoriesForTestnet";
    FEATURE_FLAGS["ENABLE_CURATED_SPACES_AUTHOR"] = "enableCuratedSpacesAuthor";
    FEATURE_FLAGS["ENABLE_1155_SUPPORT"] = "enable1155Support";
    FEATURE_FLAGS["ENABLE_SHAPE_COLLECTION_CREATION"] = "enableShapeCollectionCreation";
    FEATURE_FLAGS["ENABLE_HOMEPAGE_DARK_MODE"] = "enableHomepageDarkMode";
    FEATURE_FLAGS["ENABLE_REFRESH_TOKEN_METADATA"] = "enableRefreshTokenMetadata";
    FEATURE_FLAGS["ENABLE_REFRESH_COLLECTION_METADATA"] = "enableRefreshCollectionMetadata";
    FEATURE_FLAGS["ENABLE_ZERO_COLLECTION_CREATION"] = "enableZeroCollectionCreation";
    FEATURE_FLAGS["ENABLE_SWAP_CURRENCY"] = "enableSwapCurrency";
    FEATURE_FLAGS["CREATE_NFT_MODAL"] = "createNftModal";
})(FEATURE_FLAGS || (FEATURE_FLAGS = {}));
