import { InternalLink } from "./InternalLink";

type InternalLinkProxyProps = React.ComponentProps<typeof InternalLink> & {
  isCollectorsChoice?: boolean;
  minted: boolean;
};

export const InternalLinkProxy = ({
  children,
  isCollectorsChoice,
  minted,
  ...props
}: InternalLinkProxyProps) => {
  if (isCollectorsChoice && !minted) {
    return <>{children}</>;
  }
  return <InternalLink {...props}>{children}</InternalLink>;
};
