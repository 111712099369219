import { isUnlimitedSize } from "@hl/shared-features/lib/utils/unlimitedSize";

import {
  EditionToken,
  MintVectorStatus,
} from "../../../apollo/graphql.generated";
import { MintVector } from "../MintVector/MintCard";

import { UserStatus, SaleStatus } from "./types";

export const tokensAvailable = (edition: EditionToken) =>
  isUnlimitedSize(edition.size) ? null : edition.remainingSupply;

export const getSaleStatus = (
  edition: EditionToken,
  mintVector?: MintVector,
  numTotalMintsLeft?: number,
  isSeriesLike = false,
  { forcePaused }: { forcePaused?: boolean } = {}
) => {
  if (!mintVector) return;
  if (
    MintVectorStatus.Ended === mintVector.mintVectorStats?.status &&
    numTotalMintsLeft !== 0
  )
    return SaleStatus.ENDED;

  if (isSeriesLike && numTotalMintsLeft === 0) {
    return SaleStatus.SOLD_OUT;
  }
  const tokensLeft = tokensAvailable(edition);
  if (tokensLeft !== null && (tokensLeft === 0 || numTotalMintsLeft === 0)) {
    return SaleStatus.SOLD_OUT;
  }
  if (mintVector.paused) return SaleStatus.PAUSED;
  if (MintVectorStatus.NotStarted === mintVector.mintVectorStats?.status)
    return SaleStatus.NOT_STARTED;

  if (forcePaused) return SaleStatus.PAUSED;

  if (!mintVector.end) return SaleStatus.ACTIVE_WITH_NO_END;
  return SaleStatus.ACTIVE_WITH_END;
};

export function isActiveSale(status: SaleStatus) {
  return [SaleStatus.ACTIVE_WITH_END, SaleStatus.ACTIVE_WITH_NO_END].includes(
    status
  );
}

export const getUserStatus = (
  numberOfMintOnCurrentWallet: number,
  numberOfMintPerWalletLimit: number
) => {
  if (numberOfMintPerWalletLimit === 0) return UserStatus.NO_LIMIT;
  if (numberOfMintOnCurrentWallet === 0) return UserStatus.LIMIT_NOT_MINTED;

  if (numberOfMintOnCurrentWallet < numberOfMintPerWalletLimit) {
    return UserStatus.LIMIT_MINTED;
  }

  return UserStatus.LIMIT_REACHED;
};
