import { useMemo } from "react";
import { useGetSupportedChainsQuery, } from "./configuration.graphql.generated";
export const useSupportedChains = (skip = false) => {
    const { data, loading, error } = useGetSupportedChainsQuery({ skip });
    const supportedChains = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.getSupportedChains) !== null && _a !== void 0 ? _a : []; }, [data === null || data === void 0 ? void 0 : data.getSupportedChains]);
    const getChainOptions = (onlyMainnet) => {
        const options = supportedChains
            .filter((chain) => (onlyMainnet ? !chain.testnet : true))
            .map((chain) => {
            var _a;
            return {
                label: (_a = chain.name) === null || _a === void 0 ? void 0 : _a.replace("Mainnet", ""),
                value: chain.chainId.toString(),
            };
        });
        options.unshift({ label: "Select Chain", value: "0" });
        return options;
    };
    const getWrappedCurrency = (chainId) => {
        const walletChain = supportedChains.find((chain) => chain.chainId === chainId);
        return (walletChain === null || walletChain === void 0 ? void 0 : walletChain.wrappedCurrency) || null;
    };
    const getChain = (chainId) => {
        const chain = supportedChains.find((chain) => chain.chainId === chainId);
        return chain || null;
    };
    return {
        loading,
        error,
        supportedChains,
        getChainOptions,
        getWrappedCurrency,
        getChain,
    };
};
export default useSupportedChains;
