import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, memo, useCallback, useEffect, useState, } from "react";
import { Group, Select, Text } from "@mantine/core";
import { zeroAddress } from "viem";
import { NetworkType } from "../../apollo/graphql.generated";
import { getCurrencySymbol, getNativeCurrencyIcon } from "../../utils/currency";
export var CurrencyType;
(function (CurrencyType) {
    CurrencyType[CurrencyType["NATIVE"] = 0] = "NATIVE";
    CurrencyType[CurrencyType["WRAPPED"] = 1] = "WRAPPED";
    CurrencyType[CurrencyType["ERC20"] = 2] = "ERC20";
})(CurrencyType || (CurrencyType = {}));
const SelectItem = forwardRef(({ label, ...others }, ref) => (_jsx("div", { ref: ref, ...others, children: _jsxs(Group, { noWrap: true, children: [getNativeCurrencyIcon(label, 20), _jsx("div", { children: _jsx(Text, { size: "sm", children: label }) })] }) })));
const BlockchainSelect = ({ supportedChains, onChainSelect, value = undefined, ...props }) => {
    const [chainIdSelected, setChainIdSelected] = useState("");
    const [chainLabelSelected, setChainLabelSelected] = useState("");
    const options = supportedChains
        .filter((chain) => !!chain.wrappedCurrency)
        .map((chain) => {
        const label = chain.name;
        return {
            label: chain && chain.network === NetworkType.ZK_SYNC_ERA
                ? "ZKsync Era"
                : label,
            value: chain.chainId.toString(),
            group: !chain.testnet ? "PRODUCTION CHAINS" : "TEST CHAINS",
        };
    })
        .sort((a, b) => {
        return a.label.localeCompare(b.label);
    });
    const handleNetworkChange = useCallback((chainId) => {
        const chain = supportedChains.find((chain) => chain.chainId.toString() === chainId);
        if (!chain || !chain.wrappedCurrency)
            return false;
        onChainSelect({
            chain,
            wrappedCurrency: {
                address: chain.wrappedCurrency.address,
                symbol: chain.wrappedCurrency.symbol,
                type: CurrencyType.WRAPPED,
            },
            nativeCurrency: {
                address: zeroAddress,
                symbol: getCurrencySymbol(chainId),
                type: CurrencyType.NATIVE,
            },
        });
        setChainIdSelected(chainId);
        setChainLabelSelected(chain.name);
    }, [onChainSelect, supportedChains]);
    useEffect(() => {
        var _a, _b;
        if (!supportedChains.length) {
            handleNetworkChange(value || "");
        }
        const initialChain = supportedChains.find((chain) => chain.chainId.toString() === value);
        handleNetworkChange(((_a = initialChain === null || initialChain === void 0 ? void 0 : initialChain.chainId) === null || _a === void 0 ? void 0 : _a.toString()) ||
            ((_b = supportedChains.at(0)) === null || _b === void 0 ? void 0 : _b.chainId.toString()) ||
            "");
    }, [supportedChains, value]);
    const filterOptions = (value, item) => {
        var _a;
        return item !== undefined &&
            ((_a = item === null || item === void 0 ? void 0 : item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value.toLowerCase().trim()));
    };
    return (_jsx(Select, { placeholder: "Select Blockchain", itemComponent: SelectItem, data: options, searchable: true, maxDropdownHeight: 400, nothingFound: "Chain not found", filter: filterOptions, icon: getNativeCurrencyIcon(chainLabelSelected, 20), value: chainIdSelected, onChange: handleNetworkChange, ...props }));
};
export default memo(BlockchainSelect);
