import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Text } from "@mantine/core";
const SwapCurrencyDescription = ({ amount, outputAmount, toCurrencySymbol, fromCurrencySymbol, status, fromChain, toChain, isWrapping, isSwapping, }) => {
    const formatted = (text) => (_jsx(Text, { size: "sm", display: "inline", weight: WEIGHT_BOLD, children: text }));
    const toLabel = fromChain === toChain ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [" ", "to", " ", formatted(outputAmount
                ? `${outputAmount} ${toCurrencySymbol}`
                : toCurrencySymbol), " ", "on ", formatted(toChain), "`"] }));
    const fromLabel = (_jsxs(_Fragment, { children: [formatted(amount ? `${amount} ${fromCurrencySymbol}` : fromCurrencySymbol), " ", "on ", formatted(fromChain), "`"] }));
    if (status === "PENDING") {
        return (_jsxs(_Fragment, { children: [isSwapping ? "Swapping" : isWrapping ? "Wrapping" : "Unwrapping", " ", fromLabel, toLabel] }));
    }
    if (status === "MINED") {
        return (_jsxs(_Fragment, { children: [isSwapping ? "Swapped" : isWrapping ? "Wrapped" : "Unwrapped", " ", fromLabel, toLabel] }));
    }
    if (status === "REJECTED") {
        return (_jsxs(_Fragment, { children: [isSwapping
                    ? "Fail to swap"
                    : isWrapping
                        ? "Fail to wrap"
                        : "Fail to unwrap", " ", fromLabel, toLabel] }));
    }
    return _jsx(_Fragment, { children: "Unknown transaction status." });
};
export default SwapCurrencyDescription;
