import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { BACKGROUND_COLOR } from "@hl/base-components/lib/theme/colors";
import { Box, Button, createStyles, Flex, Paper, ScrollArea, Stack, Text, useMantineTheme, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
const useStyles = createStyles((theme, { isCollectorsChoice, stickyHeaderAdjustment = 0, }) => ({
    aside: {
        minWidth: 280,
        maxWidth: 280,
        [theme.fn.largerThan("xs")]: {
            position: "sticky",
            top: `calc(${113 + stickyHeaderAdjustment}px + var(--mantine-header-height, 0px))`,
            width: "25%",
            height: `calc(100vh - ${
            // account for modal not being full height with 100px, 198 accounts for the components above traits
            198 + stickyHeaderAdjustment + (isCollectorsChoice ? 100 : 0)}px - var(--mantine-header-height, 0px));`,
            transition: "0.1s ease-in-out top",
        },
    },
    filters: {
        padding: 0,
        maxHeight: "100%",
        [theme.fn.smallerThan("xs")]: {
            position: isCollectorsChoice ? "absolute" : "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.colors.baseBackground[0],
            zIndex: 101,
        },
    },
    scrollArea: {
        height: "100%",
        padding: "0 16px",
        "> div": {
            maxWidth: "100%",
        },
        [theme.fn.largerThan("xs")]: {
            padding: 0,
        },
    },
    filterTop: {
        position: "relative",
        top: 0,
        padding: 10,
        borderBottom: `1px solid ${theme.colors.divider[0]}`,
    },
    filterBottom: {
        padding: 16,
        borderTop: `1px solid ${theme.colors.divider[0]}`,
        backgroundColor: theme.colors.baseBackground[0],
    },
}));
const FilterSidebar = ({ ref, title = "Filter", children, isFiltering, showFilters, closeFilters, handleClearFilters, style, scrollContainerConfig = {
    mobileHeight: "calc(100vh - 152px)",
    height: "calc(100vh - 96px - var(--mantine-header-height, 0px))",
}, mintedOnly, stickyHeaderAdjustment = 0, }) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
    const { classes, cx } = useStyles({
        isCollectorsChoice: !mintedOnly,
        stickyHeaderAdjustment,
    });
    return (_jsx(Stack, { className: classes.aside, style: style, ref: ref, children: (!isMobile || showFilters) && (_jsxs(Paper, { radius: isMobile ? 0 : 10, shadow: "none", className: classes.filters, children: [_jsx(Box, { sx: {
                        display: "visible",
                        [theme.fn.largerThan("xs")]: {
                            display: "none",
                        },
                    }, children: _jsxs(Flex, { className: classes.filterTop, align: "center", justify: "center", mb: 16, children: [_jsx(Button, { color: BACKGROUND_COLOR.SECONDARY, onClick: closeFilters, children: "Cancel" }), _jsx(Text, { size: 16, weight: 500, align: "center", w: "100%", pr: 80, children: title })] }) }), _jsx(ScrollArea.Autosize, { offsetScrollbars: true, maxHeight: isMobile
                        ? scrollContainerConfig === null || scrollContainerConfig === void 0 ? void 0 : scrollContainerConfig.mobileHeight
                        : scrollContainerConfig === null || scrollContainerConfig === void 0 ? void 0 : scrollContainerConfig.height, className: cx(classes.scrollArea, "no-child-table"), children: _jsx(Stack, { spacing: 0, pt: { base: 0, xs: 16 }, children: children }) }), isMobile && (_jsxs(Flex, { className: classes.filterBottom, children: [isFiltering && (_jsx(Button, { fullWidth: true, mr: "sm", color: BACKGROUND_COLOR.SECONDARY, onClick: handleClearFilters, children: "Clear" })), _jsx(Button, { fullWidth: true, onClick: closeFilters, children: "Apply" })] }))] })) }));
};
export default FilterSidebar;
