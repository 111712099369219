import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { TransactionStateType, useTransactionsDispatch, useTransactionState, } from "../features/evm-tx/TransactionContext";
import { logError } from "../services/logger";
export function useOnChainTransaction({ onFinish, collectionType, entityId, refetchQueries, transactionType, actionLabel = "Signing", refetchDelay = 1000, finishDelay = 1000, }) {
    const [error, setError] = useState("");
    const getNewTxnId = () => `txn-${Date.now()}`;
    const [txnId, setTxnId] = useState(getNewTxnId());
    const transactionDispatch = useTransactionsDispatch();
    const transactionState = useTransactionState(txnId);
    const [buttonLabel, setButtonLabel] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const startTransaction = async ({ args, metadata, fromCurrencyAddress, fromChain, fromValue, }) => {
        setError("");
        setButtonLoading(true);
        try {
            if (!args)
                throw new Error("Error getting args");
            const txnId = getNewTxnId();
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "START_TRANSACTION",
                payload: {
                    id: txnId,
                    entityId,
                    transactionType,
                    collectionType,
                    args,
                    metadata,
                    fromCurrencyAddress,
                    fromChain,
                    fromValue,
                },
            });
            setTxnId(txnId);
        }
        catch (e) {
            setButtonLoading(false);
            setError("Failed to execute transaction");
            logError({
                e,
                message: "Failed to start transaction",
            });
        }
    };
    const apolloClient = useApolloClient();
    useEffect(() => {
        if (transactionState &&
            !transactionState.error &&
            !!transactionState.args) {
            let buttonLabel = "";
            if (transactionState.type === TransactionStateType.WaitingSignedTx) {
                buttonLabel = actionLabel;
            }
            else if (transactionState.type !== TransactionStateType.Done) {
                buttonLabel = "Processing";
            }
            setButtonLabel(buttonLabel);
        }
        if ((transactionState === null || transactionState === void 0 ? void 0 : transactionState.type) === TransactionStateType.SignTxRejected) {
            setButtonLabel("");
            setButtonLoading(false);
            setError("Transaction rejected");
        }
        if ((transactionState === null || transactionState === void 0 ? void 0 : transactionState.type) === TransactionStateType.Done &&
            // having receipt ensures that `updateTx` is called already, that would trigger sync
            transactionState.receipt) {
            const timeout = setTimeout(() => {
                if (refetchQueries === null || refetchQueries === void 0 ? void 0 : refetchQueries.length) {
                    apolloClient.refetchQueries({
                        include: refetchQueries,
                    });
                }
            }, refetchDelay);
            const finishTimeout = setTimeout(() => {
                onFinish === null || onFinish === void 0 ? void 0 : onFinish(transactionState);
                setButtonLoading(false);
                setButtonLabel("");
            }, finishDelay);
            return () => {
                clearTimeout(timeout);
                clearTimeout(finishTimeout);
            };
        }
        if ((transactionState === null || transactionState === void 0 ? void 0 : transactionState.type) === TransactionStateType.Web2TxCreateError) {
            setButtonLabel("");
            setButtonLoading(false);
            setError("Error creating transaction");
        }
    }, [
        transactionState === null || transactionState === void 0 ? void 0 : transactionState.type,
        transactionState === null || transactionState === void 0 ? void 0 : transactionState.receipt,
        refetchDelay,
        finishDelay,
    ]);
    return {
        error,
        txnId,
        startTransaction,
        buttonLabel,
        buttonLoading,
        isDone: (transactionState === null || transactionState === void 0 ? void 0 : transactionState.type) === TransactionStateType.Done,
        txInProgress: !!(transactionState === null || transactionState === void 0 ? void 0 : transactionState.type) &&
            [TransactionStateType.WaitingConfirmedTx].includes(transactionState.type),
        txHash: transactionState === null || transactionState === void 0 ? void 0 : transactionState.hash,
        clearError: () => setError(""),
    };
}
