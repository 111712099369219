import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { ethers } from "ethers";
const ChainName = ({ chainId }) => {
    switch (chainId.toString()) {
        case "1":
            return _jsx(_Fragment, { children: "Ethereum" });
        case "5":
            return _jsx(_Fragment, { children: "Goerli" });
        case "11155111":
            return _jsx(_Fragment, { children: "Sepolia" });
        case "137":
            return _jsx(_Fragment, { children: "Polygon" });
        case "80001":
            return _jsx(_Fragment, { children: "Mumbai" });
        case "42161":
            return _jsx(_Fragment, { children: "Arbitrum" });
        case "421613":
            return _jsx(_Fragment, { children: "Arbitrum Goerli" });
        case "10":
            return _jsx(_Fragment, { children: "Optimism" });
        case "420":
            return _jsx(_Fragment, { children: "Optimism Goerli" });
        case "8453":
            return _jsx(_Fragment, { children: "Base" });
        case "84531":
            return _jsx(_Fragment, { children: "Base Goerli" });
        case "84532":
            return _jsx(_Fragment, { children: "Base Sepolia" });
        case "7777777":
            return _jsx(_Fragment, { children: "Zora" });
        case "999":
            return _jsx(_Fragment, { children: "Zora Goerli" });
        case "984122":
            return _jsx(_Fragment, { children: "Forma" });
        case "5000":
            return _jsx(_Fragment, { children: "Mantle" });
        case "534352":
            return _jsx(_Fragment, { children: "Scroll" });
        case "324":
            return _jsx(_Fragment, { children: "ZKsync Era" });
        case "360":
            return _jsx(_Fragment, { children: "Shape" });
        case "7560":
            return _jsx(_Fragment, { children: "Cyber" });
        case "543210":
            return _jsx(_Fragment, { children: "ZERO" });
        default:
            return _jsx(_Fragment, { children: ethers.providers.getNetwork(parseInt(chainId.toString())) });
    }
};
export default memo(ChainName);
