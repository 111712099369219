import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect } from "react";
import { useSwapCurrencyTransaction } from "./SwapCurrencyTransactionContext";
import { TransactionStateType, useTransactionsDispatch, } from "./TransactionContext";
const SwapCurrencyTransactionExecution = ({ id, type, args, walletClient, fromCurrencyAddress, fromChain, fromValue, }) => {
    const dispatch = useTransactionsDispatch();
    const { startSwapCurrencyTransaction, txStatus, destinationTx, txError, crossChainId, } = useSwapCurrencyTransaction();
    useEffect(() => {
        if (type === TransactionStateType.WaitingPrepareTx) {
            startSwapCurrencyTransaction({
                args,
                walletClient,
                fromCurrencyAddress,
                fromChain,
                fromValue,
            }).then(() => dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATE",
                payload: {
                    id,
                    type: TransactionStateType.WaitingSignedTx,
                },
            }));
        }
    }, []);
    useEffect(() => {
        if (txError) {
            const type = txError.includes("User rejected the request")
                ? TransactionStateType.SignTxRejected
                : TransactionStateType.Web2TxCreateError;
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATE",
                payload: {
                    id,
                    type,
                },
            });
        }
    }, [txError]);
    useEffect(() => {
        console.log("Destination TX Status - Swap", { txStatus });
        if (txStatus === "complete" && destinationTx) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id,
                    hash: destinationTx,
                    crossChainId,
                },
            });
        }
    }, [txStatus, destinationTx, crossChainId]);
    return _jsx(_Fragment, {});
};
export default memo(SwapCurrencyTransactionExecution);
