import { forwardRef, memo, Ref } from "react";

import { CLEAR_COLOR } from "@hl/base-components/lib/theme/button";
import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { CheckGreen } from "@hl/shared-features/lib/assets/icons.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { useEmbedMode } from "@hl/shared-features/lib/features/auth/hooks";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { formatDate } from "@hl/shared-features/lib/utils/format";
import {
  Button,
  ButtonProps,
  createStyles,
  Group,
  Paper,
  PaperProps,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { ReactComponent as EthereumIcon } from "~assets/icons/ethereum.svg";
import BurnAndMint from "~features/MintPage/MintVector/BurnAndMint";
import MainCardSkeleton from "~features/MintPage/MintVector/MintCardSkeleton";
import MintFee from "~features/MintPage/MintVector/MintFee";
import ReferralButton from "~features/share/ReferralButton";

import {
  CollectionStatus,
  ConsumerMintVectorType,
  PriceType,
} from "../../../apollo/graphql.generated";
import useMintState, { MintStateRequired } from "../../../hooks/useMintState";
import {
  ClaimMinter,
  ClaimMinterWithNavigate,
} from "../../claim-mints/ClaimMinter";
import { CreditCardPayButton } from "../../fiat-payments/pay-button";
import { RebateCollectorButton } from "../dutch-auction/RebateCollectorModal";
import {
  GetSalePageCollectionQuery,
  GetSalePageMintVectorsQuery,
} from "../queries.graphql.generated";
import { RankedAuctionCards } from "../ranked-auction/RaMintCard";
import { isActiveSale } from "../utils/statuses";
import { SaleStatus, UserStatus } from "../utils/types";

import CollectorsStats from "./CollectorsStats";
import MintCardButton, { MintButtonPosition } from "./MintCardButton";
import MintCardDate from "./MintCardDate";
import CardStats, { MintCardStatsProps } from "./MintCardStats";
import NumMintsInput from "./NumMintsInput";
import { RebatesTooltip } from "./rebates";

export type MintVector =
  GetSalePageMintVectorsQuery["getMintVectorsByCollection"][0];
export type Collection = GetSalePageCollectionQuery["getPublicCollection"];

export type MintCardProps = {
  onMintClick: (
    fromChain: number,
    tokenIdNum?: string | null,
    imageUrl?: string
  ) => void;
  onTimesUp?: () => void;
  hideMintButton?: boolean;
  statsStyles?: MintCardStatsProps["styles"];
  hideCollectorStats?: boolean;
  hideCardStats?: boolean;
  seriesTokeMinted?: boolean | null;
  buttonProps?: ButtonProps;
  showErrorIcon?: boolean;
  showMintFee?: boolean;
  showGateRequirementsModal?: boolean;
  showWarningModals?: boolean;
  hideRebateMessage?: boolean;
  isApollo?: boolean;
  showCreditCardButton?: boolean;
  showReferButton?: boolean;
  isButtonOnly?: boolean;
};

export type MintCardInnerProps = MintCardProps;

type StyleProps = {
  hideCardStats?: boolean;
};
const useStyles = createStyles((theme, props: StyleProps) => ({
  mintBtnWrapper: props.hideCardStats
    ? {
        display: "flex",
        flexDirection: "row",
        gap: 8,
      }
    : {},
}));

export const MintCardInner = forwardRef(
  (props: MintCardInnerProps, ref: Ref<HTMLButtonElement>) => {
    const {
      onMintClick,
      hideMintButton,
      statsStyles,
      hideCollectorStats,
      hideCardStats,
      seriesTokeMinted = false,
      buttonProps = {},
      showErrorIcon,
      showMintFee,
      showGateRequirementsModal,
      showWarningModals,
      onTimesUp,
      isButtonOnly,
    } = props;
    const {
      collection,
      mintVector,
      numTokensToMint,
      numberOfMintOnCurrentWallet,
      userStatus,
      saleStatus,
      disabledCardText,
      loadingClaimMint,
      isCollectorChoiceMint,
      maxPerTransaction,
      mintDataLoading,
      handleCreditCardClaim,
      ccClaim,
      isNativeCurrencyMint,
      loadingUserCurrencyAllowance,
      enableBurnAndRedeem,
      collectionStatus,
      isSponsoredMint,
      isFreeMint,
      mintFeeWaived,
      chain,
    } = useMintState() as MintStateRequired;
    const { eligibleRebate } = useMintState();
    const { isEmbedMode } = useEmbedMode();
    const { classes } = useStyles({ hideCardStats });
    const { authenticated } = useAuth();

    const mintLabel = isNativeCurrencyMint
      ? "Mint"
      : `Mint with ${getCurrencySymbol(
          collection.chainId,
          mintVector.paymentCurrency?.symbol,
          true
        )}`;

    const isNotStarted =
      SaleStatus.NOT_STARTED === saleStatus ||
      collectionStatus !== CollectionStatus.LIVE;

    if (collection.flagVariations.externalMintUrl && !isEmbedMode) {
      return (
        <Stack>
          <Text size="sm">This mint is hosted on a dedicated website.</Text>
          <Button
            component="a"
            href={collection.flagVariations.externalMintUrl ?? ""}
            target="_blank"
          >
            Go to mint site
          </Button>
        </Stack>
      );
    }

    if (isButtonOnly) {
      return (
        <>
          {isEmbedMode ? <ClaimMinter /> : <ClaimMinterWithNavigate />}
          <MintCardButton
            ref={ref}
            fullWidth
            onMintClick={onMintClick}
            loading={
              loadingClaimMint ||
              mintDataLoading ||
              loadingUserCurrencyAllowance
            }
            showErrorIcon={showErrorIcon}
            disabledMint={seriesTokeMinted}
            showMintFee={showMintFee}
            size="xl"
            mintLabel={mintLabel}
            enableCrossChainMint={
              collection.flagVariations.enableCrossChainMinting
            }
            showGateRequirementsModal={showGateRequirementsModal}
            showWarningModals={showWarningModals}
            leftIcon={
              !!collection.creditCardEnabled ? <EthereumIcon /> : undefined
            }
            buttonPosition={MintButtonPosition.MintCard}
            {...buttonProps}
          />
        </>
      );
    }
    const showOnlyMintFee = isFreeMint && chain?.mintFee && !mintFeeWaived;

    return (
      <>
        {isEmbedMode ? <ClaimMinter /> : <ClaimMinterWithNavigate />}
        {(!isFreeMint || mintVector.sponsored) &&
          !hideCardStats &&
          !enableBurnAndRedeem && (
            <CardStats
              styles={statsStyles}
              onTimesUp={onTimesUp}
              showUsdPrice={
                !!collection.creditCardEnabled &&
                !hideMintButton &&
                mintVector.consumerData?.type !=
                  ConsumerMintVectorType.HL_GASLESS &&
                !mintVector.sponsored
              }
              hideNumInput={hideMintButton || isNotStarted}
            />
          )}
        {enableBurnAndRedeem && <BurnAndMint />}
        {disabledCardText && (
          <Stack spacing="xs" mb="sm">
            <Title size="h5">{disabledCardText.title}</Title>
            <Text style={{ opacity: 0.6 }} size="sm">
              {disabledCardText.description}
            </Text>
          </Stack>
        )}
        <div className={classes.mintBtnWrapper}>
          {hideCardStats &&
            authenticated &&
            !isCollectorChoiceMint &&
            isActiveSale(saleStatus) &&
            !hideMintButton &&
            (mintVector.sponsored ? (
              <Text size="xs">Gas and mint fee sponsored</Text>
            ) : (
              !isFreeMint && <NumMintsInput size="sm" />
            ))}
          {!hideMintButton && (
            <>
              <MintCardButton
                showNumberMintsInput={isFreeMint && !mintVector.sponsored}
                ref={ref}
                fullWidth
                onMintClick={onMintClick}
                loading={
                  loadingClaimMint ||
                  mintDataLoading ||
                  loadingUserCurrencyAllowance
                }
                showErrorIcon={showErrorIcon}
                disabledMint={seriesTokeMinted}
                showMintFee={showMintFee}
                size="xl"
                mintLabel={mintLabel}
                enableCrossChainMint={
                  collection.flagVariations.enableCrossChainMinting
                }
                showGateRequirementsModal={showGateRequirementsModal}
                showWarningModals={showWarningModals}
                leftIcon={
                  !!collection.creditCardEnabled ? <EthereumIcon /> : undefined
                }
                buttonPosition={MintButtonPosition.MintCard}
                {...buttonProps}
              />
            </>
          )}
        </div>
        {!hideMintButton &&
          collection.creditCardEnabled &&
          collection.crossmint &&
          mintVector.isDirectMint &&
          isActiveSale(saleStatus) && (
            <>
              <Space h={8} />
              <CreditCardPayButton
                crossmint={{
                  ...collection.crossmint,
                  projectId: collection.crossmint.projectId ?? "",
                }}
                handleCreditCardClaim={handleCreditCardClaim}
                claim={ccClaim}
                quantity={numTokensToMint}
                vectorId={mintVector.id!}
                onchainVectorId={
                  mintVector.onchainMintVectorId?.split(":")[2] ?? ""
                }
                price={mintVector.price}
                mintFee={mintVector.chain?.mintFee}
                collectionId={collection.id}
                onchainId={collection.onchainId}
                editionId={0}
                collectionName={collection.name}
                artistName={
                  collection.creatorAccountSettings?.displayName ??
                  (collection.creatorAccountSettings?.walletAddresses ??
                    [])[0] ??
                  ""
                }
                chainId={collection.chainId}
              />
            </>
          )}
        <Group position="apart" w="100%" grow={!showOnlyMintFee} mt={12}>
          {showOnlyMintFee && <MintFee numTokensToMint={numTokensToMint} />}
          <ReferralButton size="xs" color={CLEAR_COLOR} />
        </Group>
        {!showWarningModals &&
          (userStatus !== UserStatus.NO_LIMIT || maxPerTransaction !== 0) &&
          [
            SaleStatus.ACTIVE_WITH_END,
            SaleStatus.ACTIVE_WITH_NO_END,
            SaleStatus.NOT_STARTED,
          ].includes(saleStatus) &&
          !(isSponsoredMint && SaleStatus.NOT_STARTED === saleStatus) && (
            <>
              <Space h="sm" />
              {userStatus === UserStatus.LIMIT_MINTED && (
                <Text style={{ opacity: 0.6 }} size="sm">
                  {mintVector.maxPerUser - numberOfMintOnCurrentWallet} of{" "}
                  {mintVector.maxPerUser} {isSponsoredMint ? "claim" : "mint"}
                  {mintVector.maxPerUser === 1 ? "" : "s"} remaining
                </Text>
              )}
              {userStatus === UserStatus.LIMIT_NOT_MINTED && (
                <Text style={{ opacity: 0.6 }} size="sm">
                  {mintVector.maxPerUser} {isSponsoredMint ? "claim" : "mint"}
                  {mintVector.maxPerUser === 1 ? "" : "s"} allowed per wallet
                </Text>
              )}
              {maxPerTransaction !== 0 && (
                <Text style={{ opacity: 0.6 }} size="sm">
                  {maxPerTransaction} {isSponsoredMint ? "claim" : "mint"}
                  {maxPerTransaction === 1 ? "" : "s"} allowed per transaction
                </Text>
              )}
              {userStatus === UserStatus.LIMIT_REACHED && (
                <Text
                  size="xs"
                  color={STATUS_COLOR.AFFIRMATIVE}
                  sx={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <CheckGreen height={14} width={14} />
                  You&apos;ve {isSponsoredMint ? "claimed" : "minted"} the max
                  number of tokens allowed
                </Text>
              )}
            </>
          )}
        {eligibleRebate && (
          <RebateCollectorButton eligibleRebate={eligibleRebate} />
        )}
        {!hideCollectorStats && <CollectorsStats />}
      </>
    );
  }
);

export const MintCard = memo(
  forwardRef((props: MintCardProps, ref: Ref<HTMLButtonElement>) => {
    const {
      isPossibleRebate,
      isRebatesDisabled,
      mintVectorsLoading,
      mintVector,
      isOptimizedLoading,
    } = useMintState();
    if (isOptimizedLoading && mintVectorsLoading && !mintVector) {
      return <MainCardSkeleton isApollo={props.isApollo} />;
    }
    if (mintVector?.priceType === PriceType.RankedAuction) {
      return <RankedAuctionCards {...props} />;
    }

    const cardPaperProps: PaperProps = {
      shadow: props.isApollo ? "0" : "md",
      radius: props.isApollo ? 12 : "md",
      withBorder: props.isApollo,
      p: "md",
    };

    return (
      <>
        <Paper {...cardPaperProps}>
          <MintCardDate showDivider />
          <MintCardInner {...props} ref={ref} />
        </Paper>
        {isPossibleRebate && !props.hideRebateMessage && (
          <Group pt={8} noWrap spacing={4}>
            <RebatesTooltip />
            <Text size="xs" color={TEXT_COLOR.SECONDARY}>
              You can claim your rebate once the resting price is reached
            </Text>
          </Group>
        )}
        {isRebatesDisabled && (
          <Group pt={8} noWrap spacing={4}>
            <RebatesTooltip notAvailable />
            <Text size="xs" color={TEXT_COLOR.SECONDARY}>
              Rebates are not available for this Dutch Auction
            </Text>
          </Group>
        )}
      </>
    );
  })
);

export type DisabledCardText = {
  title: string;
  description: string;
  hasOpenSeaLink: boolean;
};
export const getDisabledCardText = (
  saleStatus?: SaleStatus,
  saleDateTo?: Date | null
): DisabledCardText | undefined => {
  switch (saleStatus) {
    case SaleStatus.ENDED:
      return {
        title: "Mint ended",
        description: `This mint ended on ${formatDate(
          saleDateTo!
        )}, but check the marketplace to see if there are any available.`,
        hasOpenSeaLink: false,
      };
    case SaleStatus.SOLD_OUT:
      return {
        title: "Sold out",
        description:
          "This mint sold out, but check the marketplace to see if there are any available.",
        hasOpenSeaLink: false,
      };
    case SaleStatus.PAUSED:
      return {
        title: "Paused",
        description: "This mint is paused at the moment.",
        hasOpenSeaLink: false,
      };
  }
};
