import { useCallback, useState } from "react";

import { TransactionType } from "@hl/shared-features/lib/apollo/graphql.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { useOnChainTransaction } from "@hl/shared-features/lib/hooks/useOnChainTransaction";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";

import { MintVector } from "..";
import { _CollectionType } from "../../../apollo/graphql.generated";

import {
  useRankedAuctionBidTxArgsLazyQuery,
  RankedAuctionDocument,
  useReclaimBidFundsTxArgsLazyQuery,
} from "./ra-queries.graphql.generated";

export const useRankedAuctionTx = ({
  collectionId,
  collectionType,
  mintVector,
  bidAmount,
  bidId,
  onFinish,
}: {
  collectionId: string;
  collectionType: _CollectionType;
  mintVector: MintVector;
  bidAmount: string;
  bidId?: string;
  onFinish: () => void;
}) => {
  const currencySymbol = getCurrencySymbol(
    mintVector?.chainId || 0,
    mintVector?.paymentCurrency?.symbol,
    true
  );

  const { walletAddress } = useAuth();
  const [generateTxArgs, { loading: loadingArgs, error: errorArgs }] =
    useRankedAuctionBidTxArgsLazyQuery();

  const { error, txnId, startTransaction, buttonLabel, buttonLoading, isDone } =
    useOnChainTransaction({
      collectionType,
      entityId: collectionId,
      onFinish,
      finishDelay: 1001,
      refetchQueries: [RankedAuctionDocument],
      refetchDelay: 1000,
      transactionType: TransactionType.EVM_RANKED_AUCTION_BID,
    });

  const approve = useCallback(async () => {
    if (!mintVector?.chainId) throw new Error("mintVector is required");

    const metadata = {
      bidId,
      auctionId: mintVector.id!,
      collectionId,
      nftContractAddress: walletAddress!,
      onchainId: mintVector.onchainMintVectorId ?? undefined,
    };
    const result = await generateTxArgs({
      variables: {
        mintVectorId: mintVector.id!,
        input: {
          bidAmount,
          bidId,
          quantity: 1,
        },
      },
    });
    const args = result?.data?.rankedAuctionBidTxArgs;
    return startTransaction({ args, metadata });
  }, [
    generateTxArgs,
    collectionId,
    mintVector,
    walletAddress,
    bidAmount,
    bidId,
    currencySymbol,
  ]);

  return {
    txnId,
    error: error || (errorArgs ? "Error preparing transaction" : ""),
    approve,
    buttonLabel: !isDone ? buttonLabel : undefined,
    buttonLoading: !isDone && (buttonLoading || loadingArgs),
  };
};

export const useClaimBidFundsTx = ({
  collectionId,
  collectionType,
  mintVector,
  bidId,
  onFinish,
}: {
  collectionId: string;
  collectionType: _CollectionType;
  mintVector: MintVector;
  bidId: string;
  onFinish?: () => void;
}) => {
  const { walletAddress } = useAuth();
  const [generateTxArgs, { loading: loadingArgs, error: errorArgs }] =
    useReclaimBidFundsTxArgsLazyQuery();

  const [disabled, setDisabled] = useState(false);

  const { error, txnId, startTransaction, buttonLabel, buttonLoading, isDone } =
    useOnChainTransaction({
      collectionType,
      entityId: collectionId,
      onFinish: () => {
        setDisabled(true);
        onFinish?.();
      },
      refetchQueries: [RankedAuctionDocument],
      transactionType: TransactionType.EVM_721_RANKED_AUCTION_RECLAIM_BID_FUNDS,
    });

  const approve = useCallback(async () => {
    if (!mintVector?.chainId) throw new Error("mintVector is required");

    const metadata = {
      bidId,
      auctionId: mintVector.id!,
      collectionId,
      nftContractAddress: walletAddress!,
    };
    const result = await generateTxArgs({
      variables: {
        mintVectorId: mintVector.id!,
        bidId,
      },
    });
    const args = result?.data?.rankedAuctionReclaimBidTxArgs;
    return startTransaction({ args, metadata });
  }, [generateTxArgs, collectionId, mintVector, walletAddress, bidId]);

  return {
    txnId,
    error: error || (errorArgs ? "Error preparing transaction" : ""),
    approve,
    buttonLabel: !isDone ? buttonLabel : undefined,
    buttonLoading: (!isDone && (buttonLoading || loadingArgs)) || disabled,
  };
};
