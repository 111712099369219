import { useEmbedMode } from "@hl/shared-features/lib/features/auth/hooks";
import { Box, Center, Loader, Text } from "@mantine/core";

import { MintState, useMintPage } from "~hooks/useMintPage";
import { MintTokensState } from "~hooks/useMintPageTokens";
import { MintContext } from "~hooks/useMintState";
import { MintTokensContext } from "~hooks/useMintTokensState";

import {
  ClaimMinter,
  ClaimMinterWithNavigate,
} from "../claim-mints/ClaimMinter";

import { MarketplaceTokens } from "./MarketplaceTokens";
import { useMarketplaceBrowseCollectionQuery } from "./queries/browse.graphql.generated";

const MODAL_HEIGHT = "calc(100vh - 185px)";

type Props = {
  mintState: MintState;
  mintPageTokens: MintTokensState;
};

const CollectorsChoiceMarketplaceModal = ({
  mintState,
  mintPageTokens,
}: Props) => {
  const { collection, referrer } = mintState;
  const collectionId = collection?.id ?? "";
  const { data, loading } = useMarketplaceBrowseCollectionQuery({
    variables: {
      collectionId,
      minted: null,
    },
    fetchPolicy: "cache-first",
    skip: !collectionId,
  });

  const mintPage = useMintPage(collectionId ?? "", {
    skipAuction: true,
    referrer,
  });
  const { mintVectorsLoading, collectionLoading } = mintPage;
  const browseCollection = data?.getPublicCollection;

  const { isEmbedMode } = useEmbedMode();

  if (
    (!browseCollection && loading) ||
    collectionLoading ||
    mintVectorsLoading
  ) {
    return (
      <Center h={MODAL_HEIGHT}>
        <Loader />
      </Center>
    );
  }

  if (!browseCollection) {
    return (
      <Center h={MODAL_HEIGHT}>
        <Text>Error trying to display tokens.</Text>
      </Center>
    );
  }

  return (
    <MintContext.Provider value={mintPage}>
      <MintTokensContext.Provider value={mintPageTokens}>
        {isEmbedMode ? <ClaimMinter /> : <ClaimMinterWithNavigate />}
        <Box p="0 24px" pos="relative" h={MODAL_HEIGHT} id="scrollableDiv">
          <MarketplaceTokens
            collectionId={collectionId}
            browseCollection={browseCollection}
            scrollBodyOnFilterChange={true}
            enableMint
            containerConfig={{
              top: 0,
              zIndex: 101,
            }}
            filterScrollContainerConfig={{
              mobileHeight: "calc(100vh - 327px)",
              height: "calc(100vh - 96px)",
            }}
          />
        </Box>
      </MintTokensContext.Provider>
    </MintContext.Provider>
  );
};

export default CollectorsChoiceMarketplaceModal;
