import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { formatDate } from "@hl/shared-features/lib/utils/format";

import { AuctionStatus } from "../../../apollo/graphql.generated";

import { SalePageAuctionQueryResult, SaleStatusAuction } from "./types";

export const getDisabledCardText = (
  saleStatus: SaleStatusAuction,
  saleDateTo?: Date | null,
  chainId?: number | null,
  stats?: SalePageAuctionQueryResult["stats"]
): { title: string; description: string; footer?: string } | undefined => {
  const owner = stats?.highestBidder;
  const highestBid = stats?.currentBid;
  switch (saleStatus) {
    case SaleStatusAuction.ENDED_WITH_WIN:
      return {
        title: `Congrats! You won the auction at ${
          highestBid ? highestBid : 0.0
        } ${getCurrencySymbol(chainId ?? "")}`,
        description: `This auction ended on ${formatDate(
          saleDateTo!
        )}. You were the highest bidder`,
      };
    case SaleStatusAuction.ENDED_WITH_LOSS:
      return {
        title: `Sold at ${highestBid ? highestBid : 0.0} ${getCurrencySymbol(
          chainId ?? ""
        )}`,
        description: `This auction ended on ${formatDate(
          saleDateTo!
        )}. You were not the top bidder.`,
        footer: owner ? `Owned by ${maskAddress(owner, 4, 4)}` : undefined,
      };
    case SaleStatusAuction.ENDED:
      return {
        title: `Auction Ended`,
        description: `This auction ended on ${formatDate(
          saleDateTo!
        )} with no winning bids`,
      };
  }
};

export const parseBidError = ({ message }: { message: string }) => {
  if (message.includes("MIN_BID_PRICE")) {
    return "Please bid higher to get the bid accepted";
  }

  return "Unexpected error, please try later";
};

export const getAuctionStatus = (
  walletAddress?: string,
  auction?: SalePageAuctionQueryResult
) => {
  if (!auction?.stats) {
    return SaleStatusAuction.NOT_STARTED;
  }
  if (AuctionStatus.NotStarted === auction.stats.status)
    return SaleStatusAuction.NOT_STARTED;
  if (
    AuctionStatus.Ended === auction.stats.status ||
    AuctionStatus.Fulfilled === auction.stats.status
  ) {
    if (
      walletAddress?.toLowerCase() === auction.stats.highestBidder.toLowerCase()
    )
      return SaleStatusAuction.ENDED_WITH_WIN;
    if (auction.stats.totalBids) return SaleStatusAuction.ENDED_WITH_LOSS;
    return SaleStatusAuction.ENDED;
  }
  if (auction.stats.totalBids) {
    return SaleStatusAuction.ACTIVE_WITH_BID;
  }
  return SaleStatusAuction.ACTIVE;
};
