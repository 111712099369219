import { useCallback } from "react";
import { useEmbedMode } from "../auth/hooks";
export const EMBED_EVENT_PREFIX = "highlight";
export var EmbedEvent;
(function (EmbedEvent) {
    EmbedEvent["TokenRevealed"] = "token-revealed";
    EmbedEvent["MintFinished"] = "minted";
    EmbedEvent["SignIn"] = "sign-in";
    EmbedEvent["SignOut"] = "sign-out";
    EmbedEvent["MintInitiated"] = "mint-initiated";
    EmbedEvent["SeedRefreshInitiated"] = "seed-refresh-initiated";
    EmbedEvent["SeedRefreshFinished"] = "seed-refresh-finished";
})(EmbedEvent || (EmbedEvent = {}));
export const useGlobalEmbedEvents = () => {
    const { isEmbedMode } = useEmbedMode();
    const emitSignInEvent = useCallback((data) => {
        if (!isEmbedMode)
            return;
        const event = new CustomEvent(`${EMBED_EVENT_PREFIX}:${EmbedEvent.SignIn}`, {
            detail: data,
        });
        window.dispatchEvent(event);
    }, [isEmbedMode]);
    const emitSignOutEvent = useCallback(() => {
        if (!isEmbedMode)
            return;
        const event = new CustomEvent(`${EMBED_EVENT_PREFIX}:${EmbedEvent.SignOut}`);
        window.dispatchEvent(event);
    }, [isEmbedMode]);
    return {
        emitSignInEvent,
        emitSignOutEvent,
    };
};
