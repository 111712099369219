import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { CheckGreen } from "@hl/base-components/lib/assets/icons.generated";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Box, Card, Group, Text } from "@mantine/core";
import { buildTxUrl, networkLookup } from "../../utils/blockExplorer";
const SwapCompleteBox = ({ txHash = "", chainId, }) => {
    const txUrl = buildTxUrl(networkLookup(chainId).type, txHash);
    return (_jsx(Card, { bg: STATUS_COLOR.SUCCESS_WASH, sx: (theme) => ({
            borderColor: theme.fn.themeColor(STATUS_COLOR.SUCCESS),
        }), children: _jsxs(Group, { align: "center", noWrap: true, children: [_jsx(CheckGreen, {}), _jsxs(Box, { children: [_jsxs(Text, { size: "sm", fw: WEIGHT_BOLD, span: true, children: ["Transaction complete.", " "] }), _jsxs(Text, { size: "sm", span: true, children: ["View transaction", " ", _jsx(Anchor, { size: "sm", fw: WEIGHT_BOLD, underline: false, href: txUrl, target: "_blank", rel: "noreferrer", children: "here" })] })] })] }) }));
};
export default SwapCompleteBox;
