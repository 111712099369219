import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { ArrowUp, ArrowDown, InfoCircleFill, } from "@hl/base-components/lib/assets/icons.generated";
import ModalSection from "@hl/base-components/lib/modal/ModalSection";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, Space, Text, ActionIcon, Grid, Group, Anchor, } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { capitalize } from "lodash";
import { zeroAddress } from "viem";
import { EXTERNAL_URL } from "../../config";
import { getCurrencySymbol } from "../../utils/currency";
import ErrorText from "../error/ErrorText";
import CryptoCurrencyInput from "../input/CryptoCurrencyInput";
import { useModalStack } from "../modal";
import BlockchainSelect, { CurrencyType } from "./BlockchainSelect";
import SwapCompleteBox from "./SwapCompleteBox";
import SwapInProgressBox from "./SwapInProgressBox";
import { useCurrencyBalance } from "./useCurrencyBalance";
import { useCurrencyRate } from "./useCurrencyRate";
import { useSwapCurrency } from "./useSwapCurrency";
export const SwapModal = ({ small = false, refetchBalance, walletChainId, walletAddress, walletWrappedCurrency, supportedChains, getChain, isFetchingBalance, }) => {
    const [value, setValue] = useState(0);
    const [debouncedValue] = useDebouncedValue(value, 500);
    const [tradeType, setTradeType] = useState(null);
    const [fromCurrency, setFromCurrency] = useState(null);
    const [fromCurrencies, setFromCurrencies] = useState([]);
    const [toCurrencies, setToCurrencies] = useState([]);
    const [toCurrency, setToCurrency] = useState(null);
    const [fromChain, setFromChain] = useState(null);
    const [toChain, setToChain] = useState(null);
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const { popModal } = useModalStack();
    // Initialize chains
    useEffect(() => {
        if (!walletChainId || !supportedChains.length) {
            setFromChain(null);
            setToChain(null);
            return;
        }
        let defaultChain = getChain(walletChainId);
        if (!(defaultChain === null || defaultChain === void 0 ? void 0 : defaultChain.wrappedCurrency)) {
            defaultChain = supportedChains[0];
        }
        if (!fromChain)
            setFromChain(defaultChain);
        if (!toChain)
            setToChain(defaultChain);
    }, [walletChainId, supportedChains]);
    // Initialize fromCurrency and toCurrency addresses
    useEffect(() => {
        if (!fromCurrency && walletChainId) {
            setFromCurrency({
                address: zeroAddress,
                symbol: getCurrencySymbol(walletChainId),
                type: CurrencyType.NATIVE,
            });
        }
        if (!toCurrency && walletWrappedCurrency) {
            setToCurrency(walletWrappedCurrency);
        }
    }, [walletWrappedCurrency, walletChainId]);
    const { handleSwap, error, buttonLoading, buttonLabel, clearError, userAction, txHash, txInProgress, isDone, getCurrencies, } = useSwapCurrency({
        toCurrency,
        toChain,
        fromChain,
        value: debouncedValue,
        walletAddress,
        fromCurrency,
        tradeType,
        onFinish: async () => {
            await Promise.all([
                refetchBalance(),
                refetchFromBalance(),
                refetchToBalance(),
            ]);
        },
    });
    // Set currencies
    useEffect(() => {
        if (!supportedChains.length || !toChain || !fromChain)
            return;
        setToCurrencies(getCurrencies(getChain(toChain.chainId)));
        setFromCurrencies(getCurrencies(getChain(fromChain.chainId)));
    }, [toChain, fromChain, supportedChains]);
    const { priceValue: fetchedValue, loading: loadingRate, error: errorRate, } = useCurrencyRate({
        fromChain,
        fromCurrency,
        toChain,
        toCurrency,
        fromValue: debouncedValue,
        walletAddress,
        tradeType,
        insufficientBalance,
    });
    const onClose = useCallback(() => {
        setValue(0);
        clearError();
        popModal();
    }, [popModal, clearError]);
    const handleReverseSwapOnClick = useCallback(() => {
        if (!fromChain || !toChain)
            return false;
        if (fromChain.chainId !== toChain.chainId) {
            const fromChainTemp = fromChain;
            setFromChain(toChain);
            setToChain(fromChainTemp);
        }
        const fromCurrencyTemp = fromCurrency;
        setFromCurrency(toCurrency);
        setToCurrency(fromCurrencyTemp);
        setTradeType((prev) => prev === null
            ? null
            : prev === "EXACT_INPUT"
                ? "EXACT_OUTPUT"
                : "EXACT_INPUT");
    }, [fromCurrency, toCurrency, fromChain, toChain]);
    const handleFromBlockchainSelect = useCallback(({ chain, nativeCurrency, wrappedCurrency, }) => {
        if (!toChain || !toCurrency) {
            return false;
        }
        setFromChain(chain);
        if (chain.chainId !== toChain.chainId) {
            return setFromCurrency(nativeCurrency);
        }
        if (toCurrency.type === CurrencyType.NATIVE) {
            setFromCurrency(wrappedCurrency);
        }
        else {
            setFromCurrency(nativeCurrency);
        }
    }, [toCurrency, toChain]);
    const handleToBlockchainSelect = useCallback(({ chain, nativeCurrency, wrappedCurrency, }) => {
        if (!fromChain || !fromCurrency) {
            return false;
        }
        setToChain(chain);
        if (chain.chainId !== fromChain.chainId) {
            return setToCurrency(nativeCurrency);
        }
        if (fromCurrency.type === CurrencyType.NATIVE) {
            setToCurrency(wrappedCurrency);
        }
        else {
            setToCurrency(nativeCurrency);
        }
    }, [fromCurrency, fromChain]);
    const { balanceRoundedLarge: fromBalanceRounded, balanceNumber: fromBalanceNumber, isFetching: isFetchingFromBalance, refetch: refetchFromBalance, } = useCurrencyBalance({
        walletAddress,
        chainId: fromChain === null || fromChain === void 0 ? void 0 : fromChain.chainId,
        currency: fromCurrency,
    });
    const { balanceRoundedLarge: toBalanceRounded, balanceNumber: toBalanceNumber, isFetching: isFetchingToBalance, refetch: refetchToBalance, } = useCurrencyBalance({
        walletAddress,
        chainId: toChain === null || toChain === void 0 ? void 0 : toChain.chainId,
        currency: toCurrency,
    });
    // Check insufficient balance
    useEffect(() => {
        if (!fromBalanceNumber)
            return setInsufficientBalance(false);
        const fromValue = tradeType === "EXACT_INPUT" ? value : fetchedValue;
        setInsufficientBalance(fromValue > fromBalanceNumber);
    }, [fromBalanceNumber, value, fetchedValue, tradeType]);
    if (!fromChain || !toChain) {
        return _jsx(_Fragment, {});
    }
    const currencySymbol = getCurrencySymbol(fromChain.chainId);
    return (_jsxs("form", { children: [_jsxs(Grid, { align: "center", justify: "center", children: [_jsx(Grid.Col, { span: small ? 12 : 1, pr: 0, children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "From" }) }), _jsx(Grid.Col, { span: small ? 12 : 6, children: _jsx(CryptoCurrencyInput, { max: fromBalanceNumber, missingChainIdPlaceholder: "", chainId: fromChain.chainId, onChange: (value) => {
                                setValue(value);
                                setTradeType("EXACT_INPUT");
                            }, value: tradeType === "EXACT_OUTPUT" ? fetchedValue : value, disabled: (tradeType === "EXACT_OUTPUT" && loadingRate) || buttonLoading, customCurrencySymbol: fromCurrency === null || fromCurrency === void 0 ? void 0 : fromCurrency.symbol, currencies: fromCurrencies, onChangeCurrency: (currency) => setFromCurrency(currency) }) }), _jsx(Grid.Col, { span: small ? 12 : 5, children: _jsx(BlockchainSelect, { disabled: buttonLoading, searchable: !small, supportedChains: supportedChains, value: fromChain.chainId.toString(), onChainSelect: handleFromBlockchainSelect }) }), _jsx(Grid.Col, { span: small ? 12 : 11, py: 0, offset: small ? 0 : 1, children: _jsxs(Text, { size: "xs", color: TEXT_COLOR.PLACEHOLDER, children: ["Balance: ", fromBalanceRounded] }) }), _jsx(Grid.Col, { span: 12, children: _jsx(Group, { align: "center", w: "100%", position: "center", children: _jsxs(ActionIcon, { disabled: buttonLoading, size: "xl", onClick: handleReverseSwapOnClick, color: SECONDARY_COLOR, children: [_jsx(ArrowDown, { width: 16, height: 16 }), _jsx(ArrowUp, { width: 16, height: 16 })] }) }) }), _jsx(Grid.Col, { span: small ? 12 : 1, pr: 0, children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "To" }) }), _jsx(Grid.Col, { span: small ? 12 : 6, children: _jsx(CryptoCurrencyInput, { max: toBalanceNumber, missingChainIdPlaceholder: "", chainId: toChain.chainId, onChange: (value) => {
                                setValue(value);
                                setTradeType("EXACT_OUTPUT");
                            }, value: tradeType === "EXACT_INPUT" ? fetchedValue : value, disabled: (tradeType === "EXACT_INPUT" && loadingRate) || buttonLoading, customCurrencySymbol: toCurrency === null || toCurrency === void 0 ? void 0 : toCurrency.symbol, currencies: toCurrencies, onChangeCurrency: (currency) => setToCurrency(currency) }) }), _jsx(Grid.Col, { span: small ? 12 : 5, children: _jsx(BlockchainSelect, { searchable: !small, supportedChains: supportedChains, value: toChain.chainId.toString(), onChainSelect: handleToBlockchainSelect, disabled: buttonLoading }) }), _jsx(Grid.Col, { span: small ? 12 : 11, py: 0, offset: small ? 0 : 1, children: _jsxs(Text, { size: "xs", color: TEXT_COLOR.PLACEHOLDER, children: ["Balance: ", toBalanceRounded] }) })] }), txInProgress && (_jsxs(_Fragment, { children: [_jsx(Space, { h: 40 }), _jsx(SwapInProgressBox, { txHash: txHash, chainId: toChain.chainId })] })), isDone && (_jsxs(_Fragment, { children: [_jsx(Space, { h: 40 }), _jsx(SwapCompleteBox, { txHash: txHash, chainId: toChain.chainId })] })), _jsx(Space, { h: 8 }), _jsxs(ModalSection, { children: [_jsx(Button, { fullWidth: true, onClick: handleSwap, size: "xl", loading: buttonLoading ||
                            isFetchingFromBalance ||
                            isFetchingToBalance ||
                            isFetchingBalance ||
                            loadingRate, disabled: debouncedValue === 0 ||
                            fetchedValue === 0 ||
                            toChain.testnet !== fromChain.testnet ||
                            insufficientBalance, children: insufficientBalance
                            ? "Insufficient balance"
                            : buttonLabel || `${capitalize(userAction)} ${currencySymbol}` }), _jsx(ErrorText, { error: errorRate || error, mt: 6, align: "center" }), small && (_jsxs(_Fragment, { children: [_jsx(Space, { h: "sm" }), _jsx(Button, { fullWidth: true, onClick: onClose, size: "xl", color: SECONDARY_COLOR, children: "Cancel" })] })), _jsx(Space, { h: "sm" }), _jsx(Anchor, { href: EXTERNAL_URL.SWAP_CURRENCIES_DOC, underline: false, target: "_blank", children: _jsxs(Group, { align: "center", spacing: 6, position: "center", children: [_jsx(InfoCircleFill, { width: 17, height: 17, cursor: "pointer" }), _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Learn more about exchanging currencies" })] }) })] })] }));
};
