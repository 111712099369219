import {
  PageSupportedMediaMime as MediaMime,
  PageSupportedMediaType as MediaType,
} from "../apollo/graphql.generated";

export const MimeTypeMapping: Record<MediaMime, MediaType> = {
  [MediaMime.GIF]: MediaType.IMAGE,
  [MediaMime.JPEG]: MediaType.IMAGE,
  [MediaMime.PNG]: MediaType.IMAGE,
  [MediaMime.WEBP]: MediaType.IMAGE,

  [MediaMime.MP3]: MediaType.AUDIO,
  [MediaMime.WAV]: MediaType.AUDIO,
  [MediaMime.MPEG]: MediaType.AUDIO,

  [MediaMime.WEBM]: MediaType.VIDEO,
  [MediaMime.MP4]: MediaType.VIDEO,
  [MediaMime.GLTF]: MediaType.GLTF,
};

export const getMediaTypeFromContentType = (
  contentType: string | null
): MediaType | null => {
  if (!contentType) return null;
  if (contentType.includes("image")) {
    return MediaType.IMAGE;
  } else if (contentType.includes("audio")) {
    return MediaType.AUDIO;
  } else if (contentType.includes("video")) {
    return MediaType.VIDEO;
  } else if (contentType.includes("gltf")) {
    return MediaType.GLTF;
  } else {
    return null;
  }
};

export type ContentTypeInfo = {
  type: MediaType | null;
  mime: MediaMime | null;
  isHtml: boolean;
  contentType: string | null;
};

const getFileExtension = (url: string) => {
  const file = url.split("/").pop();
  if (!file?.includes(".")) {
    return null;
  }
  return file.split(".").pop();
};

const matchMime = (contentType: string | null) => {
  let mime: MediaMime | null = null;
  for (const mimeKey in Object.keys(MediaMime)) {
    const key = Object.keys(MediaMime)[mimeKey];
    if (contentType?.toLowerCase().includes(key.toLowerCase())) {
      mime = MediaMime[key as keyof typeof MediaMime];
    }
  }
  return mime;
};

export const getContentTypeInfo = async (
  contentUrl: string
): Promise<ContentTypeInfo> => {
  let mime: MediaMime | null = null;
  let isHtml = false;
  let contentType = null;

  const ext = getFileExtension(contentUrl);
  if (ext) {
    mime = matchMime(`/${ext}`);
  }
  if (!mime) {
    const response = await fetch(contentUrl);
    contentType = response.headers.get("content-type") as string;
    if (contentType?.startsWith("text/html")) {
      isHtml = true;
    } else {
      mime = matchMime(contentType);
    }
  }
  const type = mime
    ? MimeTypeMapping[mime]
    : getMediaTypeFromContentType(contentType) || null;
  return { type, mime, isHtml, contentType };
};
