import {
  CSSProperties,
  Dispatch,
  Ref,
  SetStateAction,
  useCallback,
} from "react";

import FilterSidebar from "@hl/shared-features/lib/features/marketplace/components/FilterSidebar";
import { Box, createStyles, Radio, Text } from "@mantine/core";
import { Property } from "csstype";

import { FilterGroup } from "~components/filter/FilterGroup";
import {
  BrowseCollection,
  MintStatus,
} from "~features/marketplace/MarketplacePage";

import RadioStyled from "../../../features/browse/RadioStyled";

const useStyles = createStyles({
  radioGroup: {
    "> div": {
      gap: 0,
    },
  },
});

export type FilterSidebarProps = {
  ref?: Ref<HTMLDivElement>;
  browseCollection: BrowseCollection;
  handleClearFilters: () => void;
  mintedOnly?: boolean | null;
  showFilters: boolean;
  closeFilters: () => void;
  defaultMintStatus: MintStatus;
  mintStatus: string;
  setMintStatus: Dispatch<SetStateAction<string>>;
  attributeFilters: Record<string, string[]>;
  setAttributeFilters: Dispatch<SetStateAction<Record<string, string[]>>>;
  style?: CSSProperties;
  scrollContainerConfig?: {
    height: Property.Height;
    mobileHeight: Property.Height;
  };
  stickyHeaderAdjustment?: number;
};

const FilterSidebarSeries = ({
  ref,
  showFilters,
  closeFilters,
  handleClearFilters,
  mintedOnly,
  browseCollection,
  defaultMintStatus,
  mintStatus,
  setMintStatus,
  attributeFilters,
  setAttributeFilters,
  style,
  scrollContainerConfig = {
    mobileHeight: "calc(100vh - 152px)",
    height: "calc(100vh - 96px - var(--mantine-header-height, 0px))",
  },
  stickyHeaderAdjustment,
}: FilterSidebarProps) => {
  const handleSelectAttr = useCallback(
    (traitType: string, value: string) => {
      setAttributeFilters((filters) => ({
        ...filters,
        [traitType]: (filters[traitType] ?? []).concat([value]),
      }));
    },
    [setAttributeFilters]
  );

  const handleUnselect = useCallback(
    (traitType: string, value: string) => {
      setAttributeFilters((filters) => {
        const values = filters[traitType].filter((x) => x !== value);
        if (!values.length) {
          const copy = { ...filters };
          delete copy[traitType];
          return copy;
        } else {
          return {
            ...filters,
            [traitType]: values,
          };
        }
      });
    },
    [setAttributeFilters]
  );

  const { classes } = useStyles();
  const attributes = browseCollection.seriesAttributes;
  const isFiltering =
    mintStatus !== defaultMintStatus ||
    Object.keys(attributeFilters).length !== 0;

  return (
    <FilterSidebar
      scrollContainerConfig={scrollContainerConfig}
      isFiltering={isFiltering}
      showFilters={showFilters}
      mintedOnly={mintedOnly}
      handleClearFilters={handleClearFilters}
      closeFilters={closeFilters}
      title="Filter Series"
      ref={ref}
      style={style}
      stickyHeaderAdjustment={stickyHeaderAdjustment}
    >
      {!mintedOnly && (
        <Box mb={24}>
          <Text weight={500} size={18}>
            Availability
          </Text>

          <Radio.Group
            name="mintStatus"
            value={mintedOnly ? MintStatus.Minted : mintStatus}
            onChange={setMintStatus}
            className={classes.radioGroup}
          >
            <>
              <RadioStyled value={MintStatus.All} label="All tokens" />
              <RadioStyled
                value={MintStatus.Available}
                label="Available tokens"
              />
            </>

            <RadioStyled value={MintStatus.Minted} label="Minted tokens" />
          </Radio.Group>
        </Box>
      )}

      <Text weight={500} size={18} mb={4}>
        Traits
      </Text>
      {attributes.map((attr, index) => (
        <FilterGroup
          key={index}
          title={attr.name}
          options={attr.variants}
          selectedOptions={attributeFilters[attr.name]}
          onSelect={handleSelectAttr}
          onUnselect={handleUnselect}
          isLast={index === attributes.length - 1}
        />
      ))}
    </FilterSidebar>
  );
};

export default FilterSidebarSeries;
