import { useState } from "react";

import {
  Refresh2 as RefreshIcon,
  InfoCircleFill,
} from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import { ActionIcon, Group, Stack, Text, Tooltip } from "@mantine/core";

import { useRefreshTokenMetadataMutation } from "~features/MintPage/refresh-metadata/queries.graphql.generated";
import useMintState from "~hooks/useMintState";

const RefreshTokenMetadataButton = ({ tokenId }: { tokenId: string }) => {
  const { collection, chainId, address } = useMintState();
  const enableRefreshTokenMetadata = useFeatureFlags(
    FEATURE_FLAGS.ENABLE_REFRESH_TOKEN_METADATA
  );

  const [requested, setRequested] = useState(false);

  const [refreshTokenMetadata, { loading, error }] =
    useRefreshTokenMetadataMutation({
      onCompleted: () => setRequested(true),
      variables: {
        address,
        chainId,
        editionId: collection?.editionId || "0",
        tokenId,
      },
    });

  if (!enableRefreshTokenMetadata || !tokenId || !collection) {
    return <></>;
  }

  return (
    <Tooltip
      events={{ hover: true, focus: false, touch: true }}
      label={
        requested ? (
          <Stack spacing={6} p={6}>
            <Group align="center" spacing={6}>
              <InfoCircleFill height={16} width={16} />
              <Text
                size="xs"
                fw={WEIGHT_BOLD}
                color={TEXT_COLOR.INVERTED_PRIMARY}
              >
                Refresh requested
              </Text>
            </Group>
            <Text size="xs" color={TEXT_COLOR.INVERTED_SECONDARY}>
              Check back in a few minutes to see the updated metadata.
            </Text>
          </Stack>
        ) : error ? (
          "Error refreshing metadata. Try again."
        ) : loading ? (
          "Loading..."
        ) : (
          "Refresh metadata"
        )
      }
    >
      <ActionIcon
        onClick={() => {
          if (!requested && !loading) {
            refreshTokenMetadata();
          }
        }}
      >
        <RefreshIcon height={16} width={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export default RefreshTokenMetadataButton;
