import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { Text } from "@mantine/core";
const ErrorText = ({ error, hide, ...props }) => {
    if (hide || !error)
        return null;
    return (_jsx(Text, { color: STATUS_COLOR.ERROR, size: "xs", ...props, children: error }));
};
export default memo(ErrorText);
