import { useState } from "react";
import { getRelayClient } from "../cross-chain-tx/client";
export const useSwapCurrencyTransaction = () => {
    const [txError, setTxError] = useState();
    const [txStatus, setTxStatus] = useState();
    const [destinationTx, setDestinationTx] = useState();
    const [crossChainId, setCrossChainId] = useState();
    const startSwapCurrencyTransaction = async ({ args, walletClient, fromCurrencyAddress, fromChain, fromValue, }) => {
        try {
            const toValue = args.value && args.value !== "0" ? args.value : undefined;
            if (!fromCurrencyAddress ||
                !fromChain ||
                !(args === null || args === void 0 ? void 0 : args.address) ||
                !(args === null || args === void 0 ? void 0 : args.chainId) ||
                (!toValue && !fromValue) ||
                ((args === null || args === void 0 ? void 0 : args.chainId) === fromChain && fromCurrencyAddress === args.address)) {
                throw new Error(`Unable to create swap tx`);
            }
            const relayClient = getRelayClient(fromChain);
            const quote = await relayClient.actions.getQuote({
                chainId: fromChain,
                toChainId: args.chainId,
                amount: fromValue || toValue,
                // @ts-ignore
                wallet: walletClient,
                currency: fromCurrencyAddress,
                toCurrency: args.address,
                tradeType: fromValue ? "EXACT_INPUT" : "EXACT_OUTPUT",
            });
            await relayClient.actions.execute({
                quote,
                wallet: walletClient,
                onProgress: ({ steps }) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    console.log("Swap Steps are", { steps: quote.steps });
                    const status = (_c = (_b = (_a = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.status;
                    const endpoint = (_g = (_f = (_e = (_d = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.check) === null || _g === void 0 ? void 0 : _g.endpoint;
                    const isCrossChain = fromChain !== args.chainId;
                    if (endpoint && isCrossChain) {
                        setCrossChainId(endpoint.split("=")[1]);
                    }
                    if (status) {
                        setTxStatus(status);
                    }
                    if (status === "complete" && ((_k = (_j = (_h = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _h === void 0 ? void 0 : _h.items) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.txHashes)) {
                        setDestinationTx(steps[0].items[0].txHashes[0].txHash);
                    }
                    setTxError(((_l = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _l === void 0 ? void 0 : _l.error) ? (_m = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _m === void 0 ? void 0 : _m.error : undefined);
                },
            });
            console.log("QUOTE swap", { quote });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (e) {
            setTxError("Error creating transaction.");
            console.log("Error swapping", e);
        }
    };
    return {
        crossChainId,
        txStatus,
        destinationTx,
        txError,
        startSwapCurrencyTransaction,
    };
};
