import React from "react";

import { useApolloClient } from "@apollo/client";
import {
  DESTRUCTIVE_COLOR,
  OUTLINE_COLOR,
  SECONDARY_COLOR,
} from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import BidBuyButtons, {
  Props1155,
} from "@hl/shared-features/lib/features/marketplace/components/BidBuyButtons";
import CancelListingModalHighlight, {
  CancelListingModalTitle,
} from "@hl/shared-features/lib/features/marketplace/modals/CancelListingModal";
import ListModalHighlight from "@hl/shared-features/lib/features/marketplace/modals/ListModal";
import ManageOffersModal, {
  ManageOffersModalTitle,
} from "@hl/shared-features/lib/features/marketplace/modals/ManageOffersModal";
import {
  isTokenV1,
  MarketplaceToken,
  TokenV1,
  TokenV2,
} from "@hl/shared-features/lib/features/marketplace/utils";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { setMarketplaceTokenMinted } from "@hl/shared-features/lib/utils/cache";
import { Box, Button, ButtonProps, Text } from "@mantine/core";
import { useBids, useOwnerListings } from "@reservoir0x/reservoir-kit-ui";

import { SaleStatus } from "~features/MintPage/utils/types";
import useMintState from "~hooks/useMintState";

import { NftContractStandard } from "../../apollo/graphql.generated";
import MintCardButton, {
  MintButtonPosition,
} from "../MintPage/MintVector/MintCardButton";

type Props = {
  token: MarketplaceToken & Props1155;
  onCompleted?: () => void;
};

const buttonProps: ButtonProps = {
  size: "sm",
  px: 12,
};

const MarketplaceTokenButton = (props: Props) => {
  const { cache } = useApolloClient();
  const { token, onCompleted = () => null } = props;

  const {
    mintVector,
    handleMint,
    collection,
    mintingTokenId,
    loadingClaimMint,
    isActiveMint,
    saleStatus,
    isMarketplaceEnabledForCollectionChain,
    isImported,
    isERC1155,
    isEnglishAuction,
  } = useMintState();
  const tokenId = token.tokenId;

  if (!mintVector && !isImported && !isEnglishAuction) {
    return null;
  }

  if (isMarketplaceEnabledForCollectionChain) {
    if (token.minted && collection) {
      return collection.standard === NftContractStandard.ERC1155 ? (
        <ERC1155MarketplaceButton {...props} />
      ) : (
        <ERC721MarketplaceButton {...props} />
      );
    }
    if (
      [SaleStatus.ENDED, SaleStatus.SOLD_OUT, SaleStatus.PAUSED].includes(
        saleStatus ?? SaleStatus.SOLD_OUT
      )
    ) {
      return null;
    }
  }

  if (!mintVector) {
    return null;
  }

  if (isERC1155) {
    return null;
  }

  return (
    <Box>
      <MintCardButton
        showWarningModals
        tokenId={tokenId}
        mintLabel="Mint now"
        onMintClick={(fromChain: number) =>
          handleMint(fromChain, tokenId, token.imageUrl || "")
        }
        loading={mintingTokenId === tokenId && loadingClaimMint}
        showErrorIcon={false}
        isTokenSold={token.minted}
        enableCrossChainMint={
          collection?.flagVariations.enableCrossChainMinting
        }
        showGateRequirementsModal
        disabledMint={isActiveMint && mintingTokenId !== tokenId}
        showConnectButton={false}
        color={SECONDARY_COLOR}
        onMinted={() => {
          setMarketplaceTokenMinted(cache, String(token.id));
          onCompleted?.();
        }}
        buttonPosition={MintButtonPosition.Marketplace}
        {...buttonProps}
      />
    </Box>
  );
};

export default MarketplaceTokenButton;

const ERC721MarketplaceButton = ({
  token,
  onCompleted = () => null,
}: Props) => {
  const { collection, walletAddress } = useMintState();
  const { pushModal } = useModalStack();
  const { ownerAddress } = token;
  const isOwner =
    walletAddress?.toLowerCase() &&
    ownerAddress?.toLowerCase() === walletAddress?.toLowerCase();
  const tokenId = token.tokenId;

  if (!collection) {
    return null;
  }

  if (isOwner) {
    if (token.topOffer?.status === "active") {
      return (
        <Button
          {...buttonProps}
          color={OUTLINE_COLOR}
          onClick={() =>
            pushModal(
              <ManageOffersModal
                collectionData={collection}
                tokenId={tokenId}
                onCompleted={onCompleted}
              />,
              {
                size: "100%",
                title: (
                  <ManageOffersModalTitle
                    tokenName={token?.name}
                    collectionName={collection?.name}
                  />
                ),
              }
            )
          }
        >
          View offers
        </Button>
      );
    }
    return token?.tokenListing?.status === "active" ? (
      <Button
        {...buttonProps}
        color={DESTRUCTIVE_COLOR}
        onClick={() =>
          pushModal(
            <CancelListingModalHighlight
              collectionId={collection.id}
              id={token.tokenListing?.reservoirId ?? ""}
              collectionName={collection.name}
              chainId={collection.chainId}
              imageUrl={token.imageUrl}
              tokenId={tokenId}
              onChainId={`${token.chainId}:${collection?.address}:${tokenId}`}
              floorPrice={
                isTokenV1(token)
                  ? (token as TokenV1).floorListing?.price.amount.native
                  : undefined
              }
              onCancel={onCompleted}
            />,
            {
              size: "lg",
              title: CancelListingModalTitle(),
            }
          )
        }
      >
        Cancel listing
      </Button>
    ) : (
      <Button
        {...buttonProps}
        color={SECONDARY_COLOR}
        onClick={() =>
          pushModal(
            <ListModalHighlight
              src={token.imageUrl || ""}
              collectionData={collection}
              tokenId={tokenId ?? null}
              onCompleted={onCompleted}
            />,
            {
              size: "lg",
              title: <Text fw={WEIGHT_BOLD}>Create a listing</Text>,
            }
          )
        }
      >
        List for sale
      </Button>
    );
  }

  return (
    <BidBuyButtons
      token={token}
      collection={collection}
      onCompleted={() => onCompleted?.()}
    />
  );
};

const ERC1155MarketplaceButton = ({
  token,
  onCompleted = () => null,
}: Props) => {
  const { collection, walletAddress } = useMintState();
  const { pushModal } = useModalStack();
  const { userBalance, tokenId } = token;

  const { data: ownerListings, mutate: mutateOwnerListings } = useOwnerListings(
    {
      token: `${collection?.address}:${tokenId}`,
    }
  );
  const { data: bids, mutate: mutateBids } = useBids({
    token: `${collection?.address}:${tokenId}`,
  });
  const offerToAccept = bids.find(
    (b) =>
      b.status === "active" &&
      b.maker.toLowerCase() !== walletAddress?.toLowerCase()
  );
  const listing = (token as TokenV2).tokenListing;
  const canBuy =
    !!listing &&
    listing?.makerAddress.toLowerCase() !== walletAddress?.toLowerCase();
  const canCancel = ownerListings.length > 0;
  const canList = !!userBalance;
  const canSell = !!userBalance && !!offerToAccept;
  if (!collection) {
    return null;
  }

  if (canSell) {
    return (
      <Button
        {...buttonProps}
        color={OUTLINE_COLOR}
        onClick={() =>
          pushModal(
            <ManageOffersModal
              collectionData={collection}
              tokenId={tokenId}
              onCompleted={onCompleted}
            />,
            {
              size: "100%",
              title: (
                <ManageOffersModalTitle
                  tokenName={token?.name}
                  collectionName={collection?.name}
                />
              ),
            }
          )
        }
      >
        View offers
      </Button>
    );
  }
  if (canList && !canBuy) {
    return canCancel ? (
      <Button
        {...buttonProps}
        color={DESTRUCTIVE_COLOR}
        onClick={() =>
          pushModal(
            <CancelListingModalHighlight
              collectionId={collection.id}
              id={token.tokenListing?.reservoirId ?? ""}
              collectionName={collection.name}
              chainId={collection.chainId}
              imageUrl={token.imageUrl}
              tokenId={tokenId}
              onChainId={`${token.chainId}:${collection?.address}:${tokenId}`}
              floorPrice={
                isTokenV1(token)
                  ? (token as TokenV1).floorListing?.price.amount.native
                  : undefined
              }
              onCancel={() => {
                mutateOwnerListings();
                onCompleted?.();
              }}
            />,
            {
              size: "lg",
              title: CancelListingModalTitle(),
            }
          )
        }
      >
        Cancel listing
      </Button>
    ) : (
      <Button
        {...buttonProps}
        color={SECONDARY_COLOR}
        onClick={() =>
          pushModal(
            <ListModalHighlight
              src={token.imageUrl || ""}
              collectionData={collection}
              tokenId={tokenId ?? null}
              onCompleted={() => {
                mutateOwnerListings();
                onCompleted?.();
              }}
            />,
            {
              size: "lg",
              title: <Text fw={WEIGHT_BOLD}>Create a listing</Text>,
            }
          )
        }
      >
        List for sale
      </Button>
    );
  }
  return (
    <BidBuyButtons
      token={token}
      collection={collection}
      onCompleted={() => {
        mutateBids();
        onCompleted?.();
      }}
    />
  );
};
