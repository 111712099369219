import { useCallback, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import MultilineText from "@hl/base-components/lib/MultilineText";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import {
  Center,
  Flex,
  Loader,
  UnstyledButton,
  Text,
  useMantineTheme,
  Stack,
} from "@mantine/core";
import { useHotkeys, useMediaQuery } from "@mantine/hooks";

import { ReactComponent as ArrowRightIcon } from "~assets/icons/arrow-right.svg";
import { InfiniteCarousel, StaticCarousel } from "~components/carousel";
import { getDetailPageUrl } from "~config";
import { useLinkStyles } from "~features/MintPage/components/InternalLink";
import { InternalLinkProxy } from "~features/MintPage/components/InternalLinkProxy";
import useMintState from "~hooks/useMintState";

import { setCacheTokenMinted } from "../cache";
import { useEmbedFlags } from "../embed/customize";
import { useSuccessModalData } from "../hooks";
import { useSeriesTokensQuery } from "../queries.graphql.generated";

const ArrowLeftIcon = () => (
  <ArrowRightIcon style={{ transform: "rotate(180deg)" }} />
);

const MintSeriesHeader = ({
  collectionId,
  isApollo,
  containerWidth,
  containerHeight,
}: {
  collectionId: string;
  isApollo?: boolean;
  containerWidth?: number;
  containerHeight?: number;
}) => {
  const {
    collection,
    isCollectorChoiceMint,
    isImported,
    seriesTokenSelected: selectedToken,
    setSeriesTokenSelected,
  } = useMintState();

  const theme = useMantineTheme();
  const { classes } = useLinkStyles();
  const { cache } = useApolloClient();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const [images, setImages] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [emblaApi, setEmbla] = useState<Embla | null>(null);
  const featuredTokenId = collection?.seriesDetails?.featuredTokenId;
  useAnimationOffsetEffect(emblaApi, 200);
  const {
    data: tokensData,
    fetchMore,
    loading,
  } = useSeriesTokensQuery({
    variables: {
      collectionId: collectionId!,
      limit: isMobile ? 5 : 7,
      cursor: null,
      minted: null,
    },
    skip: !isCollectorChoiceMint,
  });

  const seriesTokens = tokensData?.getPublicCollection?.seriesTokens;
  const tokens = seriesTokens?.edges ?? [];
  const nextCursor = seriesTokens?.pageInfo?.endCursor;

  useEffect(() => {
    const initialImageIndex = isMobile ? 1 : 3;
    if (!selectedToken) {
      setSeriesTokenSelected(tokens[initialImageIndex]);
    }
  }, [tokens, selectedToken]);

  const successData = useSuccessModalData();
  useEffect(() => {
    if (successData) {
      const { tokensIds } = successData;
      if (!tokensIds) {
        return;
      }
      if (!isCollectorChoiceMint) {
        // we don't know tokenId for random mint in advance
        return;
      }

      // update token minted status in cache
      tokensIds.forEach((id) => setCacheTokenMinted(cache, id));
    }
  }, [successData, cache, isCollectorChoiceMint]);

  const handleImageSelected = useCallback(
    (index: number) => {
      setSelectedImageIndex(index);
      setSeriesTokenSelected(tokens[index]);
    },
    [setSelectedImageIndex, setSeriesTokenSelected, tokens]
  );

  const loadMore = useCallback(
    () =>
      fetchMore({
        variables: {
          cursor: nextCursor,
        },
      }),
    [nextCursor]
  );

  useEffect(() => {
    if (isCollectorChoiceMint) {
      return;
    }

    const newImages = [
      ...((isImported
        ? collection?.importData?.sampleImages
        : collection?.seriesImages?.urls) ?? []),
    ];
    if (!newImages.length) {
      return;
    }

    if (newImages.length % 2 === 0) {
      newImages.push("");
    }

    const initialImageIndex = Math.floor(newImages.length / 2);
    let midImage = newImages[initialImageIndex];
    if (isVideoUrl(midImage)) {
      midImage = newImages.find((url) => !isVideoUrl(url)) ?? midImage;
    }

    setImages(newImages);
    setSelectedImageIndex(initialImageIndex);
    setSeriesTokenSelected({
      imageUrl: midImage,
      minted: false,
      id: null,
    });
  }, [collection]);

  useEffect(() => {
    if (!featuredTokenId || !tokens?.length || !emblaApi) {
      return;
    }
    const featuredTokenIndex = tokens.findIndex(
      (t) => t.tokenId === featuredTokenId
    );
    if (featuredTokenIndex !== -1) {
      handleImageSelected(featuredTokenIndex);
      emblaApi.scrollTo(featuredTokenIndex, true);
    }
  }, [featuredTokenId, isCollectorChoiceMint, tokens, emblaApi]);
  const flags = useEmbedFlags();

  if (!isCollectorChoiceMint) {
    if (!images.length) {
      return (
        <Center h={520}>
          <Loader />
        </Center>
      );
    }
    return (
      <StaticCarousel
        images={images}
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        onSlideChange={handleImageSelected}
        getEmblaApi={setEmbla}
        fullHeight={isApollo}
      />
    );
  }

  if (!tokens.length) {
    return (
      <Center h={520}>
        <Loader />
      </Center>
    );
  }

  const disabledSx = {
    opacity: 0.3,
    cursor: "not-allowed",
  };

  return (
    <>
      <InfiniteCarousel
        isLoading={loading}
        tokens={tokens}
        getEmblaApi={setEmbla}
        onFetchMore={loadMore}
        hasMoreToLoad={!!nextCursor}
        onSlideChange={handleImageSelected}
        fullHeight={isApollo}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />

      <Stack spacing={8} align="center">
        <Flex w="100%" maw={440} gap="md">
          <UnstyledButton
            onClick={() => emblaApi?.scrollPrev()}
            p="sm"
            sx={selectedImageIndex ? undefined : disabledSx}
          >
            <ArrowLeftIcon />
          </UnstyledButton>
          <Center w="100%">
            {tokens[selectedImageIndex]?.name ? (
              <InternalLinkProxy
                className={classes.grow}
                to={
                  collection
                    ? getDetailPageUrl(
                        collection,
                        tokens[selectedImageIndex].tokenId ?? ""
                      )
                    : ""
                }
                isCollectorsChoice={isCollectorChoiceMint}
                minted={tokens[selectedImageIndex].minted ?? false}
              >
                <MultilineText
                  size={20}
                  weight={WEIGHT_BOLD}
                  align="center"
                  numLines="2"
                >
                  {tokens[selectedImageIndex]?.name}
                </MultilineText>
              </InternalLinkProxy>
            ) : (
              <Loader />
            )}
          </Center>
          <UnstyledButton
            onClick={() => emblaApi?.scrollNext()}
            p="sm"
            sx={
              selectedImageIndex !== tokens.length - 1 ? undefined : disabledSx
            }
          >
            <ArrowRightIcon />
          </UnstyledButton>
        </Flex>
        <Text size="xs" color={TEXT_COLOR.PLACEHOLDER}>
          {flags.strabismusTemplate
            ? "Token IDs will be randomly assigned amongst auction winners."
            : "You’ll be able to pick any unminted token you want when you mint."}
        </Text>
      </Stack>
      <CarouselHotkeys emblaApi={emblaApi} />
    </>
  );
};

const CarouselHotkeys = ({ emblaApi }: { emblaApi: Embla | null }) => {
  useHotkeys([
    ["ArrowLeft", () => emblaApi?.scrollPrev()],
    ["ArrowRight", () => emblaApi?.scrollNext()],
  ]);

  return null;
};

export const isVideoUrl = (url: string) => {
  if (!url) {
    return false;
  }
  const ext = url.split(".").pop() ?? "";
  return ["mp4", "webm"].includes(ext);
};

export default MintSeriesHeader;
