import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, } from "react";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import { Refresh } from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, Flex, Grid, Group, NumberInput, Space, Stack, Text, useMantineTheme, } from "@mantine/core";
import { BidModal, BidStep } from "@reservoir0x/reservoir-kit-ui";
import { parseUnits } from "ethers/lib/utils";
import { _CollectionType, NftContractStandard, TransactionType, } from "../../../apollo/graphql.generated";
import { getMintPageUrl, USER_ROUTES } from "../../../config";
import { noExponents } from "../../../utils/numbers";
import EmailUpdates from "../../email-updates/EmailUpdates";
import { useTransactionsDispatch } from "../../evm-tx/TransactionContext";
import CryptoCurrencyInput from "../../input/CryptoCurrencyInput";
import { useModalStack } from "../../modal";
import ExpirationOptionSelect from "../components/ExpirationOptionSelect";
import MarketplaceConnectButton, { useMarketplaceAuth, } from "../components/MarketplaceConnectButton";
import MarketplaceSummaryCard from "../components/MarketplaceSummaryCard";
const BidModalHighlight = (props) => {
    var _a;
    const { collectionData, tokenId } = props;
    const ct = collectionData.collectionType;
    const isMultiContract = ct === _CollectionType.OneOfOne || ct === _CollectionType.LimitedEdition;
    const reservoirCollectionId = collectionData.standard === NftContractStandard.ERC1155 || isMultiContract
        ? collectionData.address
        : ((_a = collectionData.marketplaceId) === null || _a === void 0 ? void 0 : _a.split(/:/).slice(1).join(":")) ||
            collectionData.address;
    return (_jsx(Stack, { pt: 16, spacing: 40, children: _jsx(BidModal.Custom, { open: true, oracleEnabled: true, collectionId: reservoirCollectionId, tokenId: tokenId, chainId: collectionData === null || collectionData === void 0 ? void 0 : collectionData.chainId, children: (bidProps) => _jsx(BidModalInner, { ...bidProps, ...props }) }) }));
};
const btnTexts = {
    ["Wrapping currency"]: "Approve wrapping the currency",
    ["Approve currency"]: "Approve currency",
    ["On-chain verification"]: "Trigger On-chain verification",
    ["Authorize offer"]: "Make offer",
};
const MAX_AMOUNT = 9007199254740991;
const MIN_AMOUNT = 0.000001;
function insertDecimal(numberStr, decimals) {
    if (!/^\d+$/.test(numberStr)) {
        throw "Invalid input: not a valid integer";
    }
    // Pad the string with leading zeros if necessary
    while (numberStr.length < decimals + 1) {
        numberStr = "0" + numberStr;
    }
    // Insert the decimal point at the appropriate position
    const index = numberStr.length - decimals;
    return numberStr.slice(0, index) + "." + numberStr.slice(index);
}
const BidModalInner = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { bidData, currency, expirationOption, expirationOptions, bidStep, bidAmountPerUnit, hasEnoughNativeCurrency, hasEnoughWrappedCurrency, convertLink, canAutomaticallyConvert, transactionError, setBidAmountPerUnit, setQuantity, quantity, setExpirationOption, placeBid, totalBidAmount, stepData, collection, token, src, tokenId, onCompleted, collectionData, } = props;
    const totalTokens = (_a = token === null || token === void 0 ? void 0 : token.token) === null || _a === void 0 ? void 0 : _a.supply;
    const getNewTxnId = () => `txn-${Date.now()}`;
    const [txnId, setTxnId] = useState(getNewTxnId());
    const txHash = useMemo(() => { var _a, _b; return (_b = (_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep.items) === null || _a === void 0 ? void 0 : _a[0].txHashes) === null || _b === void 0 ? void 0 : _b[0].txHash; }, [(_c = (_b = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep.items) === null || _b === void 0 ? void 0 : _b[0].txHashes) === null || _c === void 0 ? void 0 : _c[0].txHash]);
    const transactionDispatch = useTransactionsDispatch();
    const symbol = currency.symbol;
    const { popModal, setModalProps } = useModalStack();
    const [error, setError] = useState();
    const btnText = btnTexts[(_d = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _d === void 0 ? void 0 : _d.action] ||
        "Make offer";
    const theme = useMantineTheme();
    const { isFullyAuthenticated, walletAddress } = useMarketplaceAuth();
    const floorPrice = (_e = collection === null || collection === void 0 ? void 0 : collection.floorAsk) === null || _e === void 0 ? void 0 : _e.price;
    const tokenTopBidPrice = (_g = (_f = token === null || token === void 0 ? void 0 : token.market) === null || _f === void 0 ? void 0 : _f.topBid) === null || _g === void 0 ? void 0 : _g.price;
    const collectionTopBidPrice = (_h = collection === null || collection === void 0 ? void 0 : collection.topBid) === null || _h === void 0 ? void 0 : _h.price;
    const bidAndCreateTx = useCallback(() => {
        setError("");
        if (!txnId) {
            return;
        }
        placeBid({ royaltyBps: 0 });
    }, [placeBid, txnId]);
    const is1155 = (collectionData === null || collectionData === void 0 ? void 0 : collectionData.standard) === NftContractStandard.ERC1155;
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const isWrapping = ((_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _a === void 0 ? void 0 : _a.id) === "currency-wrapping";
        const isApproving = ((_b = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _b === void 0 ? void 0 : _b.id) === "currency-approval";
        if (txHash && txnId && (isWrapping || isApproving)) {
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "START_TRANSACTION",
                payload: {
                    id: txnId,
                    entityId: (_c = collection === null || collection === void 0 ? void 0 : collection.id) !== null && _c !== void 0 ? _c : "",
                    transactionType: isWrapping
                        ? TransactionType.EVM_DEPOSIT_FUNDS
                        : TransactionType.EVM_APPROVE_TRANSFER_WRAPPED,
                    collectionType: _CollectionType.GenerativeSeries,
                },
            });
            const metadata = isWrapping
                ? {
                    symbol,
                    amount: BigInt(((_f = (_e = (_d = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.data.value) || "0").toString(),
                }
                : { symbol };
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_ARGS",
                payload: {
                    id: txnId,
                    args: (_j = (_h = (_g = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _g === void 0 ? void 0 : _g.items) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.data,
                    metadata,
                },
            });
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id: txnId,
                    hash: txHash,
                },
            });
            setTxnId(getNewTxnId());
        }
    }, [stepData]);
    const setToBestOffer = useCallback((percentage) => {
        var _a, _b;
        if (!((_a = tokenTopBidPrice === null || tokenTopBidPrice === void 0 ? void 0 : tokenTopBidPrice.amount) === null || _a === void 0 ? void 0 : _a.raw) ||
            !((_b = tokenTopBidPrice.currency) === null || _b === void 0 ? void 0 : _b.decimals))
            return;
        const current = parseUnits(tokenTopBidPrice.amount.raw, 0);
        const toAdd = parseUnits(tokenTopBidPrice.amount.raw, 0)
            .mul(percentage)
            .div(100);
        const newOffer = current.add(toAdd);
        const bestOffer = insertDecimal(newOffer.toString(), tokenTopBidPrice.currency.decimals);
        setBidAmountPerUnit(bestOffer);
    }, [tokenTopBidPrice]);
    useEffect(() => {
        var _a;
        if (!transactionError) {
            setError("");
        }
        else {
            if ((_a = transactionError === null || transactionError === void 0 ? void 0 : transactionError.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("balance too low")) {
                setError("Insufficient balance, add funds then try again");
            }
            else {
                setError(transactionError
                    .shortMessage || "Ooops, something went wrong");
            }
        }
    }, [transactionError]);
    useEffect(() => {
        if (bidStep === BidStep.Complete)
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
    }, [bidStep]);
    const floorPriceText = (_jsx(FormatCrypto, { size: "sm", amount: (_j = floorPrice === null || floorPrice === void 0 ? void 0 : floorPrice.amount) === null || _j === void 0 ? void 0 : _j.native, symbol: symbol }));
    const bestOfferText = (_jsx(FormatCrypto, { size: "sm", amount: tokenId
            ? (_k = tokenTopBidPrice === null || tokenTopBidPrice === void 0 ? void 0 : tokenTopBidPrice.amount) === null || _k === void 0 ? void 0 : _k.native
            : (_l = collectionTopBidPrice === null || collectionTopBidPrice === void 0 ? void 0 : collectionTopBidPrice.amount) === null || _l === void 0 ? void 0 : _l.native, symbol: symbol }));
    const numberInputDescription = (_jsxs(Flex, { sx: { whiteSpace: "pre" }, children: ["Floor price: ", floorPriceText, " \u00B7 Best offer: ", bestOfferText] }));
    const actionButton = useMemo(() => {
        var _a, _b, _c, _d;
        if (!isFullyAuthenticated)
            return _jsx(MarketplaceConnectButton, {});
        if (bidStep === BidStep.Complete) {
            return (_jsxs(_Fragment, { children: [_jsx(EmailUpdates, { imageUrl: (_a = collection === null || collection === void 0 ? void 0 : collection.image) !== null && _a !== void 0 ? _a : "", autoOpen: true, description: "Never miss an offer\u2014sign up for email notifications. Receive real-time offer alerts and offers for your items." }), _jsxs(Grid, { children: [_jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", href: collectionData.id
                                        ? getMintPageUrl(collectionData)
                                        : "", onClick: () => popModal(), w: "100%", px: "md", color: SECONDARY_COLOR, size: "xl", children: "View collection" }) }), _jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", size: "xl", w: "100%", px: "md", href: USER_ROUTES.user.marketplace.sentOffers.replace(":slug", walletAddress !== null && walletAddress !== void 0 ? walletAddress : ""), target: "_blank", children: "Manage offers" }) })] })] }));
        }
        if (((_b = token === null || token === void 0 ? void 0 : token.token) === null || _b === void 0 ? void 0 : _b.kind) !== "erc1155" &&
            (walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase()) === ((_d = (_c = token === null || token === void 0 ? void 0 : token.token) === null || _c === void 0 ? void 0 : _c.owner) === null || _d === void 0 ? void 0 : _d.toLowerCase())) {
            return (_jsx(Button, { size: "xl", disabled: true, children: "Can't bid on owned tokens" }));
        }
        if (!hasEnoughNativeCurrency) {
            return (_jsx(Button, { component: "a", target: "_blank", href: convertLink, size: "xl", children: "Add funds" }));
        }
        if (hasEnoughNativeCurrency &&
            !hasEnoughWrappedCurrency &&
            !canAutomaticallyConvert) {
            return (_jsx(Button, { component: "a", target: "_blank", href: convertLink, size: "xl", children: "Convert Manually" }));
        }
        return (_jsx(Button, { size: "xl", onClick: bidAndCreateTx, disabled: !bidAmountPerUnit || bidAmountPerUnit === "0", loading: bidStep === BidStep.Offering && !transactionError, children: btnText }));
    }, [
        isFullyAuthenticated,
        bidStep,
        hasEnoughWrappedCurrency,
        hasEnoughNativeCurrency,
        canAutomaticallyConvert,
        transactionError,
        convertLink,
        bidAmountPerUnit,
        token,
        walletAddress,
        placeBid,
    ]);
    useEffect(() => {
        if (bidStep === BidStep.Complete) {
            setModalProps({
                title: (_jsx(Text, { size: "md", fw: WEIGHT_BOLD, children: "Offer sent" })),
            });
        }
    }, [bidStep]);
    return (_jsxs(_Fragment, { children: [_jsx(MarketplaceSummaryCard, { symbol: symbol, floorPrice: (_m = floorPrice === null || floorPrice === void 0 ? void 0 : floorPrice.amount) === null || _m === void 0 ? void 0 : _m.native, price: bidStep === BidStep.Complete ? bidAmountPerUnit : undefined, collectionName: collection === null || collection === void 0 ? void 0 : collection.name, imageUrl: src, tokenId: tokenId, quantity: quantity, validUntil: (bidData === null || bidData === void 0 ? void 0 : bidData.expirationTime)
                    ? parseInt(bidData.expirationTime) * 1000
                    : undefined, subtext: tokenId
                    ? null
                    : bidStep !== BidStep.Complete && (_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Anyone who owns a token from this collection can accept your offer and fulfill it with a token of their choice from this collection" })) }), bidStep !== BidStep.Complete && (_jsxs(_Fragment, { children: [is1155 && (_jsxs(Stack, { spacing: 4, children: [_jsx(NumberInput, { label: "Number of tokens to bid for", placeholder: "1", onChange: (value) => setQuantity(parseInt(String(value !== null && value !== void 0 ? value : 1))), defaultValue: 1, value: quantity, min: 1, max: parseInt(String(totalTokens !== null && totalTokens !== void 0 ? totalTokens : 1)), styles: {
                                    input: {
                                        paddingRight: 24,
                                    },
                                    rightSection: {
                                        paddingRight: 0,
                                    },
                                } }), _jsxs(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: ["You can bid for up to ", totalTokens, " tokens"] })] })), (collection === null || collection === void 0 ? void 0 : collection.chainId) && (_jsx(CryptoCurrencyInput, { chainId: collection.chainId, missingChainIdPlaceholder: "Unknown chain", label: `Your offer ${is1155 ? "per token" : ""}`, value: parseFloat(bidAmountPerUnit || "0"), max: MAX_AMOUNT, min: MIN_AMOUNT, onChange: (amount) => {
                            if (!amount || (amount < MAX_AMOUNT && amount > MIN_AMOUNT)) {
                                setBidAmountPerUnit(noExponents(amount || 0));
                            }
                            else if (amount >= MAX_AMOUNT) {
                                setBidAmountPerUnit(MAX_AMOUNT.toString());
                            }
                            else {
                                setBidAmountPerUnit(MIN_AMOUNT.toString());
                            }
                        }, precision: 5, description: numberInputDescription, placeholder: "0.1" })), tokenTopBidPrice && (_jsx(Group, { spacing: "sm", children: [0, 5, 10, 15].map((percentage) => (_jsx(Button, { size: "xl", px: "md", sx: percentage ? { flexGrow: 1 } : undefined, color: SECONDARY_COLOR, onClick: () => setToBestOffer(percentage), children: percentage ? `Best offer +${percentage}%` : _jsx(Refresh, {}) }, percentage))) })), _jsx(ExpirationOptionSelect, { expirationOptions: expirationOptions, expirationOption: expirationOption, setExpirationOption: setExpirationOption, label: "Offer duration" }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "lg", fw: WEIGHT_BOLD, children: "Total" }), _jsx(FormatCrypto, { amount: totalBidAmount, symbol: symbol, size: "lg", fw: WEIGHT_BOLD })] })] })), _jsxs(Stack, { spacing: 8, children: [_jsx(Space, { h: 8, mx: -16, sx: {
                            borderTop: `1px solid ${theme.colors.divider[0]}`,
                            width: "auto !important",
                        } }), actionButton, error ? (_jsx(Text, { size: "xs", align: "center", color: theme.colors.errorStatus[0], children: error })) : (_jsx(Text, { size: "xs", ta: "center", children: "You can change or cancel your offer for free on Highlight" }))] })] }));
};
export default BidModalHighlight;
