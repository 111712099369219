import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Info } from "@hl/base-components/lib/assets/icons.generated";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Card, Group, Stack, Text } from "@mantine/core";
import { buildTxUrl, networkLookup } from "../../utils/blockExplorer";
const SwapInProgressBox = ({ txHash = "", chainId, }) => {
    const blockchain = networkLookup(chainId);
    const txUrl = buildTxUrl(blockchain.type, txHash);
    return (_jsx(Card, { bg: STATUS_COLOR.INFORMATIVE_WASH, sx: (theme) => ({
            borderColor: theme.fn.themeColor(STATUS_COLOR.INFORMATIVE),
        }), children: _jsxs(Group, { align: "center", noWrap: true, children: [_jsx(Info, {}), _jsxs(Stack, { spacing: 4, children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Your transaction has started" }), _jsxs(Text, { size: "xs", children: ["The ", blockchain.displayName, " network is processing your transaction, which can take a little while. See the transaction", " ", _jsx(Anchor, { size: "xs", fw: WEIGHT_BOLD, underline: false, href: txUrl, target: "_blank", rel: "noreferrer", children: "here" })] })] })] }) }));
};
export default SwapInProgressBox;
