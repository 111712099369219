import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { SlashCircle } from "@hl/base-components/lib/assets/icons.generated";
import { DESTRUCTIVE_COLOR, SECONDARY_COLOR, } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, Grid, Space, Stack, Text, useMantineTheme, } from "@mantine/core";
import { CancelBidModal, CancelBidStep, CancelListingStep, } from "@reservoir0x/reservoir-kit-ui";
import { useModalStack } from "../../modal";
import MarketplaceConnectButton, { useMarketplaceAuth, } from "../components/MarketplaceConnectButton";
import MarketplaceSummaryCard from "../components/MarketplaceSummaryCard";
import BidModalHighlight from "./BidModal";
const CancelBidModalHighlight = (props) => (_jsx(Stack, { pt: 16, spacing: 40, children: _jsx(CancelBidModal.Custom, { chainId: props.chainId, open: true, bidId: props.id, children: (innerProps) => _jsx(CancelBidModalInner, { ...props, ...innerProps }) }) }));
const CancelBidModalInner = (props) => {
    var _a, _b, _c, _d;
    const { bid, cancelStep, transactionError, cancelOrder, collectionName, imageUrl, tokenId, chainId, onCancel, onClose, floorPrice, id, } = props;
    const [error, setError] = useState();
    const price = (_b = (_a = bid === null || bid === void 0 ? void 0 : bid.price) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 : _b.native;
    const symbol = (_d = (_c = bid === null || bid === void 0 ? void 0 : bid.price) === null || _c === void 0 ? void 0 : _c.currency) === null || _d === void 0 ? void 0 : _d.symbol;
    const theme = useMantineTheme();
    const { isFullyAuthenticated, walletAddress } = useMarketplaceAuth();
    const { popModal, setModalProps, pushModal } = useModalStack();
    const actionButton = useMemo(() => {
        var _a;
        if (!isFullyAuthenticated)
            return _jsx(MarketplaceConnectButton, {});
        if (bid && (walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase()) !== ((_a = bid === null || bid === void 0 ? void 0 : bid.maker) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
            return (_jsx(Button, { size: "xl", disabled: true, children: "You're not the bidder" }));
        }
        return (_jsxs(Grid, { children: [_jsx(Grid.Col, { span: 6, children: _jsx(Button, { onClick: () => {
                            popModal();
                            if (cancelStep === CancelListingStep.Complete)
                                onClose === null || onClose === void 0 ? void 0 : onClose();
                        }, size: "xl", w: "100%", color: SECONDARY_COLOR, children: "Manage offers" }) }), _jsx(Grid.Col, { span: 6, children: cancelStep !== CancelBidStep.Complete ? (_jsxs(Button, { size: "xl", w: "100%", color: DESTRUCTIVE_COLOR, onClick: () => {
                            setError("");
                            cancelOrder();
                        }, loading: cancelStep === CancelBidStep.Approving, disabled: cancelStep === CancelBidStep.Approving, children: [cancelStep !== CancelBidStep.Approving && (_jsx(SlashCircle, { style: { marginRight: 8 } })), "Cancel offer"] })) : (_jsx(Button, { size: "xl", onClick: () => {
                            if ((bid === null || bid === void 0 ? void 0 : bid.contract) && chainId) {
                                onClose === null || onClose === void 0 ? void 0 : onClose();
                                popModal();
                                pushModal(_jsx(BidModalHighlight, { src: imageUrl, tokenId: tokenId, collectionData: {
                                        name: collectionName,
                                        address: bid === null || bid === void 0 ? void 0 : bid.contract,
                                        chainId,
                                        id,
                                        // TODO: get onchainId
                                        onchainId: null,
                                    } }), {
                                    size: "lg",
                                    title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Create offer" }),
                                });
                            }
                        }, w: "100%", children: "Create offer" })) })] }));
    }, [
        isFullyAuthenticated,
        transactionError,
        cancelStep,
        bid,
        walletAddress,
        id,
        collectionName,
        chainId,
        imageUrl,
        tokenId,
        cancelOrder,
    ]);
    useEffect(() => {
        if (cancelStep === CancelBidStep.Complete) {
            setModalProps({ title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Offer cancelled" }) });
            onCancel === null || onCancel === void 0 ? void 0 : onCancel();
        }
    }, [cancelStep]);
    useEffect(() => {
        if (!transactionError) {
            setError("");
        }
        else {
            setError(transactionError
                .shortMessage || "Ooops, something went wrong");
        }
    }, [transactionError]);
    return (_jsxs(_Fragment, { children: [_jsx(MarketplaceSummaryCard, { symbol: symbol, price: price, imageUrl: imageUrl, tokenId: tokenId, collectionName: collectionName, validUntil: (bid === null || bid === void 0 ? void 0 : bid.expiration) ? bid.expiration * 1000 : undefined, floorPrice: floorPrice, subtext: cancelStep === CancelBidStep.Complete ? (_jsx(Text, { color: theme.colors.errorStatus[0], size: "sm", children: "Offer cancelled" })) : undefined }), _jsx(Stack, { children: _jsxs(Stack, { spacing: 8, children: [_jsx(Space, { h: 8, mx: -16, sx: {
                                borderTop: `1px solid ${theme.colors.divider[0]}`,
                                width: "auto !important",
                            } }), _jsx(Stack, { sx: { overflow: "hidden" }, children: actionButton }), !!error && (_jsx(Text, { size: "xs", align: "center", color: theme.colors.errorStatus[0], children: error }))] }) })] }));
};
export default CancelBidModalHighlight;
