import { useCallback } from "react";
import { parseEther, zeroAddress } from "viem";
import { ampli } from "../../ampli";
import { _CollectionType, TransactionType, } from "../../apollo/graphql.generated";
import { useOnChainTransaction } from "../../hooks/useOnChainTransaction";
import { getCurrencySymbol } from "../../utils/currency";
import { CurrencyType } from "./BlockchainSelect";
export const useSwapCurrency = ({ walletAddress, fromChain, toChain, fromCurrency, toCurrency, value, tradeType, onFinish, }) => {
    const { txHash, txInProgress, isDone, error, startTransaction, buttonLabel, buttonLoading, clearError: clearTxError, } = useOnChainTransaction({
        collectionType: _CollectionType.OpenEdition,
        entityId: walletAddress !== null && walletAddress !== void 0 ? walletAddress : "",
        onFinish,
        refetchQueries: [],
        transactionType: TransactionType.EVM_SWAP_CURRENCY,
        actionLabel: `Swap`,
    });
    const handleSwap = useCallback(async () => {
        if (!fromChain ||
            !toChain ||
            !fromCurrency ||
            !toCurrency ||
            !value ||
            !walletAddress ||
            !tradeType) {
            return;
        }
        const action = getUserAction({
            fromChain,
            toChain,
            fromCurrency,
            toCurrency,
        });
        const amount = parseEther(value.toString()).toString();
        await startTransaction({
            fromCurrencyAddress: fromCurrency.address,
            fromChain: fromChain.chainId,
            fromValue: tradeType === "EXACT_INPUT" ? amount : undefined,
            args: {
                address: toCurrency.address,
                chainId: toChain.chainId,
                value: tradeType === "EXACT_OUTPUT" ? amount : undefined,
                args: "",
            },
            metadata: {
                amount: tradeType === "EXACT_INPUT" ? value.toString() : undefined,
                outputAmount: tradeType === "EXACT_OUTPUT" ? value.toString() : undefined,
                fromCurrencySymbol: fromCurrency.symbol,
                toCurrencySymbol: toCurrency.symbol,
                fromChain: fromChain.name,
                toChain: toChain.name,
                isWrapping: action === "wrap",
                isSwapping: action === "swap",
            },
        });
        if (ampli.isLoaded) {
            ampli.swapCurrencies({
                swapType: action,
                pagePath: location.pathname,
                tradedBack: `${fromChain.chainId}:${fromCurrency.symbol}`,
                tradedIn: `${toChain.chainId}:${toCurrency.symbol}`,
            });
        }
    }, [fromChain, toChain, value, fromCurrency, toCurrency, walletAddress]);
    return {
        handleSwap,
        error: error,
        buttonLabel,
        buttonLoading,
        clearError: clearTxError,
        userAction: getUserAction({
            fromChain,
            toChain,
            fromCurrency,
            toCurrency,
        }),
        getCurrencies,
        txHash,
        txInProgress,
        isDone,
    };
};
const getUserAction = ({ fromChain, toChain, fromCurrency, toCurrency, }) => {
    if (!fromChain || !toChain || !fromCurrency || !toCurrency)
        return "swap";
    if (fromChain.chainId !== toChain.chainId)
        return "swap";
    if (fromCurrency.type === CurrencyType.NATIVE &&
        toCurrency.type === CurrencyType.WRAPPED) {
        return "wrap";
    }
    if (fromCurrency.type === CurrencyType.WRAPPED &&
        toCurrency.type === CurrencyType.NATIVE) {
        return "unwrap";
    }
    return "swap";
};
const getCurrencies = (chain) => {
    if (!chain)
        return [];
    const supportedCurrencies = chain.supportedPaymentCurrencies.map((currency) => ({
        address: currency.address,
        symbol: getCurrencySymbol(chain.chainId, currency.symbol, false),
        type: currency.address === zeroAddress
            ? CurrencyType.NATIVE
            : CurrencyType.ERC20,
    }));
    if (chain.wrappedCurrency) {
        supportedCurrencies.push({
            address: chain.wrappedCurrency.address,
            symbol: chain.wrappedCurrency.symbol,
            type: CurrencyType.WRAPPED,
        });
    }
    return supportedCurrencies;
};
