import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const AllTransactionArgFieldsFragmentDoc = gql `
  fragment AllTransactionArgFields on TransactionArgs {
    chainId
    address
    args
    method
    value
    bytecode
    gasLimit
  }
`;
export const CreateEvmSaleTransactionDocument = gql `
  mutation CreateEvmSaleTransaction($input: CreateEvmSaleTransactionInput!) {
    createEvmSaleTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmSaleTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmSaleTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmSaleTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmSaleTransactionMutation, { data, loading, error }] = useCreateEvmSaleTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmSaleTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmSaleTransactionDocument, options);
}
export const CreateEvmApproveTransferTransactionDocument = gql `
  mutation CreateEvmApproveTransferTransaction(
    $input: CreateEvmApproveTransferTransactionInput!
  ) {
    createEvmApproveTransferTransaction(input: $input) {
      status
      txHash
    }
  }
`;
/**
 * __useCreateEvmApproveTransferTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmApproveTransferTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmApproveTransferTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmApproveTransferTransactionMutation, { data, loading, error }] = useCreateEvmApproveTransferTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmApproveTransferTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmApproveTransferTransactionDocument, options);
}
export const CreateEvmApproveTransferWrappedTransactionDocument = gql `
  mutation CreateEvmApproveTransferWrappedTransaction(
    $input: CreateEvmApproveTransferWrappedTransactionInput!
  ) {
    createEvmApproveTransferWrappedTransaction(input: $input) {
      status
      txHash
    }
  }
`;
/**
 * __useCreateEvmApproveTransferWrappedTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmApproveTransferWrappedTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmApproveTransferWrappedTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmApproveTransferWrappedTransactionMutation, { data, loading, error }] = useCreateEvmApproveTransferWrappedTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmApproveTransferWrappedTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmApproveTransferWrappedTransactionDocument, options);
}
export const CreateEvmDepositFundsTransactionDocument = gql `
  mutation CreateEvmDepositFundsTransaction(
    $input: CreateEvmDepositFundsTransactionInput!
  ) {
    createEvmDepositFundsTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmDepositFundsTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmDepositFundsTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmDepositFundsTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmDepositFundsTransactionMutation, { data, loading, error }] = useCreateEvmDepositFundsTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmDepositFundsTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmDepositFundsTransactionDocument, options);
}
export const CreateEvm721MintTransactionDocument = gql `
  mutation CreateEvm721MintTransaction(
    $input: CreateEvm721MintTransactionInput!
  ) {
    createEvm721MintTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvm721MintTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvm721MintTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvm721MintTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvm721MintTransactionMutation, { data, loading, error }] = useCreateEvm721MintTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvm721MintTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvm721MintTransactionDocument, options);
}
export const CreateEvm721BidTransactionDocument = gql `
  mutation CreateEvm721BidTransaction(
    $input: CreateEvm721BidTransactionInput!
  ) {
    createEvm721BidTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvm721BidTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvm721BidTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvm721BidTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvm721BidTransactionMutation, { data, loading, error }] = useCreateEvm721BidTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvm721BidTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvm721BidTransactionDocument, options);
}
export const CreateEvm721RankedAuctionBidTransactionDocument = gql `
  mutation CreateEvm721RankedAuctionBidTransaction(
    $input: CreateEvm721RankedAuctionBidTransactionInput!
  ) {
    createEvm721RankedAuctionBidTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvm721RankedAuctionBidTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvm721RankedAuctionBidTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvm721RankedAuctionBidTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvm721RankedAuctionBidTransactionMutation, { data, loading, error }] = useCreateEvm721RankedAuctionBidTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvm721RankedAuctionBidTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvm721RankedAuctionBidTransactionDocument, options);
}
export const CreateEvm721RankedAuctionReclaimBidFundsTxDocument = gql `
  mutation CreateEvm721RankedAuctionReclaimBidFundsTx(
    $input: CreateEvm721RankedAuctionBidTransactionInput!
  ) {
    createEvm721RankedAuctionReclaimBidFundsTx(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvm721RankedAuctionReclaimBidFundsTxMutation__
 *
 * To run a mutation, you first call `useCreateEvm721RankedAuctionReclaimBidFundsTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvm721RankedAuctionReclaimBidFundsTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvm721RankedAuctionReclaimBidFundsTxMutation, { data, loading, error }] = useCreateEvm721RankedAuctionReclaimBidFundsTxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvm721RankedAuctionReclaimBidFundsTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvm721RankedAuctionReclaimBidFundsTxDocument, options);
}
export const CreateEvm721RankedAuctionClaimEarningsTxDocument = gql `
  mutation CreateEvm721RankedAuctionClaimEarningsTx(
    $input: CreateEvm721AuctionTransactionInput!
  ) {
    createEvm721RankedAuctionClaimEarningsTx(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvm721RankedAuctionClaimEarningsTxMutation__
 *
 * To run a mutation, you first call `useCreateEvm721RankedAuctionClaimEarningsTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvm721RankedAuctionClaimEarningsTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvm721RankedAuctionClaimEarningsTxMutation, { data, loading, error }] = useCreateEvm721RankedAuctionClaimEarningsTxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvm721RankedAuctionClaimEarningsTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvm721RankedAuctionClaimEarningsTxDocument, options);
}
export const CreateEvm721AuctionTransactionDocument = gql `
  mutation CreateEvm721AuctionTransaction(
    $input: CreateEvm721AuctionTransactionInput!
  ) {
    createEvm721AuctionTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvm721AuctionTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvm721AuctionTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvm721AuctionTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvm721AuctionTransactionMutation, { data, loading, error }] = useCreateEvm721AuctionTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvm721AuctionTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvm721AuctionTransactionDocument, options);
}
export const UpdateEvm721MintTransactionDocument = gql `
  mutation UpdateEvm721MintTransaction($txHash: String!) {
    updateEvm721MintTx(txHash: $txHash) {
      evmTxGraphql {
        txHash
        status
        chainId
      }
    }
  }
`;
/**
 * __useUpdateEvm721MintTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvm721MintTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvm721MintTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvm721MintTransactionMutation, { data, loading, error }] = useUpdateEvm721MintTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvm721MintTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvm721MintTransactionDocument, options);
}
export const UpdateEvm721BidTransactionDocument = gql `
  mutation UpdateEvm721BidTransaction($txHash: String!) {
    updateEvm721BidTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvm721BidTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvm721BidTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvm721BidTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvm721BidTransactionMutation, { data, loading, error }] = useUpdateEvm721BidTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvm721BidTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvm721BidTransactionDocument, options);
}
export const UpdateEvm721RankedAuctionBidTransactionDocument = gql `
  mutation UpdateEvm721RankedAuctionBidTransaction($txHash: String!) {
    updateEvm721RankedAuctionBidTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvm721RankedAuctionBidTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvm721RankedAuctionBidTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvm721RankedAuctionBidTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvm721RankedAuctionBidTransactionMutation, { data, loading, error }] = useUpdateEvm721RankedAuctionBidTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvm721RankedAuctionBidTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvm721RankedAuctionBidTransactionDocument, options);
}
export const UpdateEvm721RankedAuctionClaimEarningsTxDocument = gql `
  mutation UpdateEvm721RankedAuctionClaimEarningsTx($txHash: String!) {
    updateEvm721RankedAuctionClaimAuctionEarningsTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvm721RankedAuctionClaimEarningsTxMutation__
 *
 * To run a mutation, you first call `useUpdateEvm721RankedAuctionClaimEarningsTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvm721RankedAuctionClaimEarningsTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvm721RankedAuctionClaimEarningsTxMutation, { data, loading, error }] = useUpdateEvm721RankedAuctionClaimEarningsTxMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvm721RankedAuctionClaimEarningsTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvm721RankedAuctionClaimEarningsTxDocument, options);
}
export const UpdateEvmSaleTxDocument = gql `
  mutation UpdateEvmSaleTx($txHash: String!) {
    updateEvmSaleTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmSaleTxMutation__
 *
 * To run a mutation, you first call `useUpdateEvmSaleTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmSaleTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmSaleTxMutation, { data, loading, error }] = useUpdateEvmSaleTxMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmSaleTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmSaleTxDocument, options);
}
export const UpdateEvmApproveTransferTxDocument = gql `
  mutation UpdateEvmApproveTransferTx($txHash: String!) {
    updateEvmApproveTransferTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmApproveTransferTxMutation__
 *
 * To run a mutation, you first call `useUpdateEvmApproveTransferTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmApproveTransferTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmApproveTransferTxMutation, { data, loading, error }] = useUpdateEvmApproveTransferTxMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmApproveTransferTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmApproveTransferTxDocument, options);
}
export const UpdateEvmApproveTransferWrappedTxDocument = gql `
  mutation UpdateEvmApproveTransferWrappedTx($txHash: String!) {
    updateEvmApproveTransferWrappedTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmApproveTransferWrappedTxMutation__
 *
 * To run a mutation, you first call `useUpdateEvmApproveTransferWrappedTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmApproveTransferWrappedTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmApproveTransferWrappedTxMutation, { data, loading, error }] = useUpdateEvmApproveTransferWrappedTxMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmApproveTransferWrappedTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmApproveTransferWrappedTxDocument, options);
}
export const UpdateEvmDepositFundsTxDocument = gql `
  mutation UpdateEvmDepositFundsTx($txHash: String!) {
    updateEvmDepositFundsTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmDepositFundsTxMutation__
 *
 * To run a mutation, you first call `useUpdateEvmDepositFundsTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmDepositFundsTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmDepositFundsTxMutation, { data, loading, error }] = useUpdateEvmDepositFundsTxMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmDepositFundsTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmDepositFundsTxDocument, options);
}
export const CreateEvmDeployTransactionDocument = gql `
  mutation CreateEvmDeployTransaction($data: CreateEvmDeployTransactionInput!) {
    createEvmDeployTransaction(data: $data) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmDeployTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmDeployTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmDeployTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmDeployTransactionMutation, { data, loading, error }] = useCreateEvmDeployTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEvmDeployTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmDeployTransactionDocument, options);
}
export const UpdateEvmDeployTransactionDocument = gql `
  mutation UpdateEvmDeployTransaction($txHash: String!) {
    updateEvmDeployTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmDeployTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmDeployTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmDeployTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmDeployTransactionMutation, { data, loading, error }] = useUpdateEvmDeployTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmDeployTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmDeployTransactionDocument, options);
}
export const UpdateEvm721AuctionTransactionDocument = gql `
  mutation UpdateEvm721AuctionTransaction($txHash: String!) {
    updateEvm721AuctionTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvm721AuctionTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvm721AuctionTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvm721AuctionTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvm721AuctionTransactionMutation, { data, loading, error }] = useUpdateEvm721AuctionTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvm721AuctionTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvm721AuctionTransactionDocument, options);
}
export const CreateEvmUpdateRoyaltyTransactionDocument = gql `
  mutation CreateEvmUpdateRoyaltyTransaction(
    $input: CreateEvmUpdateRoyaltyTransactionInput!
  ) {
    createEvmUpdateRoyaltyTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmUpdateRoyaltyTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmUpdateRoyaltyTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmUpdateRoyaltyTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmUpdateRoyaltyTransactionMutation, { data, loading, error }] = useCreateEvmUpdateRoyaltyTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmUpdateRoyaltyTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmUpdateRoyaltyTransactionDocument, options);
}
export const UpdateEvmUpdateRoyaltyTransactionDocument = gql `
  mutation UpdateEvmUpdateRoyaltyTransaction($txHash: String!) {
    updateEvmUpdateRoyaltyTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmUpdateRoyaltyTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmUpdateRoyaltyTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmUpdateRoyaltyTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmUpdateRoyaltyTransactionMutation, { data, loading, error }] = useUpdateEvmUpdateRoyaltyTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmUpdateRoyaltyTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmUpdateRoyaltyTransactionDocument, options);
}
export const CreateEvmSetBaseUriTransactionDocument = gql `
  mutation CreateEvmSetBaseUriTransaction(
    $input: CreateEvmSetBaseUriTransactionInput!
  ) {
    createEvmSetBaseUriTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmSetBaseUriTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmSetBaseUriTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmSetBaseUriTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmSetBaseUriTransactionMutation, { data, loading, error }] = useCreateEvmSetBaseUriTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmSetBaseUriTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmSetBaseUriTransactionDocument, options);
}
export const UpdateEvmSetBaseUriTransactionDocument = gql `
  mutation UpdateEvmSetBaseUriTransaction($txHash: String!) {
    updateEvmSetBaseUriTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmSetBaseUriTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmSetBaseUriTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmSetBaseUriTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmSetBaseUriTransactionMutation, { data, loading, error }] = useUpdateEvmSetBaseUriTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmSetBaseUriTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmSetBaseUriTransactionDocument, options);
}
export const CreateEvmUpdateCollectionTransactionDocument = gql `
  mutation CreateEvmUpdateCollectionTransaction(
    $input: CreateEvmUpdateCollectionTransactionInput!
  ) {
    createEvmUpdateCollectionTransaction(input: $input) {
      txHash
      status
      type
    }
  }
`;
/**
 * __useCreateEvmUpdateCollectionTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmUpdateCollectionTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmUpdateCollectionTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmUpdateCollectionTransactionMutation, { data, loading, error }] = useCreateEvmUpdateCollectionTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmUpdateCollectionTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmUpdateCollectionTransactionDocument, options);
}
export const UpdateEvmUpdateCollectionTransactionDocument = gql `
  mutation UpdateEvmUpdateCollectionTransaction($txHash: String!) {
    updateEvmUpdateCollectionTx(txHash: $txHash) {
      txHash
      status
      chainId
      type
    }
  }
`;
/**
 * __useUpdateEvmUpdateCollectionTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmUpdateCollectionTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmUpdateCollectionTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmUpdateCollectionTransactionMutation, { data, loading, error }] = useUpdateEvmUpdateCollectionTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmUpdateCollectionTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmUpdateCollectionTransactionDocument, options);
}
export const UpdateEvmCreateMintVectorTransactionDocument = gql `
  mutation UpdateEvmCreateMintVectorTransaction($txHash: String!) {
    updateEvmCreateMintVectorTx(txHash: $txHash) {
      txHash
      status
      chainId
    }
  }
`;
/**
 * __useUpdateEvmCreateMintVectorTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmCreateMintVectorTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmCreateMintVectorTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmCreateMintVectorTransactionMutation, { data, loading, error }] = useUpdateEvmCreateMintVectorTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmCreateMintVectorTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmCreateMintVectorTransactionDocument, options);
}
export const CreateEvmMintCreatorReservesTransactionDocument = gql `
  mutation CreateEvmMintCreatorReservesTransaction(
    $input: CreateEvmMintCreatorReservesTransactionInput!
  ) {
    createEvmMintCreatorReservesTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmMintCreatorReservesTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmMintCreatorReservesTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmMintCreatorReservesTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmMintCreatorReservesTransactionMutation, { data, loading, error }] = useCreateEvmMintCreatorReservesTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmMintCreatorReservesTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmMintCreatorReservesTransactionDocument, options);
}
export const UpdateEvmMintCreatorReservesTransactionDocument = gql `
  mutation UpdateEvmMintCreatorReservesTransaction($txHash: String!) {
    updateEvmMintCreatorReservesTx(txHash: $txHash) {
      evmTxGraphql {
        txHash
        status
        chainId
      }
    }
  }
`;
/**
 * __useUpdateEvmMintCreatorReservesTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmMintCreatorReservesTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmMintCreatorReservesTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmMintCreatorReservesTransactionMutation, { data, loading, error }] = useUpdateEvmMintCreatorReservesTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmMintCreatorReservesTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmMintCreatorReservesTransactionDocument, options);
}
export const GetGaslessTransactionStatusDocument = gql `
  query GetGaslessTransactionStatus(
    $type: String!
    $getGaslessTransactionStatusId: String!
    $chainId: Float!
  ) {
    getGaslessTransactionStatus(
      type: $type
      id: $getGaslessTransactionStatusId
      chainId: $chainId
    ) {
      hash
      id
      status
    }
  }
`;
/**
 * __useGetGaslessTransactionStatusQuery__
 *
 * To run a query within a React component, call `useGetGaslessTransactionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGaslessTransactionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGaslessTransactionStatusQuery({
 *   variables: {
 *      type: // value for 'type'
 *      getGaslessTransactionStatusId: // value for 'getGaslessTransactionStatusId'
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetGaslessTransactionStatusQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetGaslessTransactionStatusDocument, options);
}
export function useGetGaslessTransactionStatusLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetGaslessTransactionStatusDocument, options);
}
export const CreateEvmCurrencyAllowanceTransactionDocument = gql `
  mutation CreateEvmCurrencyAllowanceTransaction(
    $input: CreateEvmCurrencyAllowanceTransactionInput!
  ) {
    createEvmCurrencyAllowanceTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmCurrencyAllowanceTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmCurrencyAllowanceTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmCurrencyAllowanceTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmCurrencyAllowanceTransactionMutation, { data, loading, error }] = useCreateEvmCurrencyAllowanceTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmCurrencyAllowanceTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmCurrencyAllowanceTransactionDocument, options);
}
export const UpdateEvmCurrencyAllowanceTransactionDocument = gql `
  mutation UpdateEvmCurrencyAllowanceTransaction($txHash: String!) {
    updateEvmCurrencyAllowanceTx(txHash: $txHash) {
      txHash
      status
    }
  }
`;
/**
 * __useUpdateEvmCurrencyAllowanceTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmCurrencyAllowanceTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmCurrencyAllowanceTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmCurrencyAllowanceTransactionMutation, { data, loading, error }] = useUpdateEvmCurrencyAllowanceTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmCurrencyAllowanceTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmCurrencyAllowanceTransactionDocument, options);
}
export const CreateEvmSponsorMintTransactionDocument = gql `
  mutation CreateEvmSponsorMintTransaction(
    $input: CreateEvmSponsorMintTransactionInput!
  ) {
    createEvmSponsorMintTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmSponsorMintTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmSponsorMintTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmSponsorMintTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmSponsorMintTransactionMutation, { data, loading, error }] = useCreateEvmSponsorMintTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmSponsorMintTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmSponsorMintTransactionDocument, options);
}
export const UpdateEvmSponsorMintTransactionDocument = gql `
  mutation UpdateEvmSponsorMintTransaction($txHash: String!) {
    updateEvmSponsorMintTx(txHash: $txHash) {
      txHash
      status
    }
  }
`;
/**
 * __useUpdateEvmSponsorMintTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmSponsorMintTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmSponsorMintTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmSponsorMintTransactionMutation, { data, loading, error }] = useUpdateEvmSponsorMintTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmSponsorMintTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmSponsorMintTransactionDocument, options);
}
export const CreateEvmCrosschainBurnTransactionDocument = gql `
  mutation CreateEvmCrosschainBurnTransaction(
    $input: CreateEvmCrosschainBurnTransactionInput!
  ) {
    createEvmCrosschainBurnTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmCrosschainBurnTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmCrosschainBurnTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmCrosschainBurnTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmCrosschainBurnTransactionMutation, { data, loading, error }] = useCreateEvmCrosschainBurnTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmCrosschainBurnTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmCrosschainBurnTransactionDocument, options);
}
export const UpdateEvmCrosschainBurnTransactionDocument = gql `
  mutation UpdateEvmCrosschainBurnTransaction($txHash: String!) {
    updateEvmCrosschainBurnTx(txHash: $txHash) {
      txHash
      status
      evmCrosschainBurnMetadata {
        redeemRelayerTaskId
      }
    }
  }
`;
/**
 * __useUpdateEvmCrosschainBurnTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmCrosschainBurnTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmCrosschainBurnTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmCrosschainBurnTransactionMutation, { data, loading, error }] = useUpdateEvmCrosschainBurnTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmCrosschainBurnTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmCrosschainBurnTransactionDocument, options);
}
export const CreateEvmSeedRefreshTransactionDocument = gql `
  mutation CreateEvmSeedRefreshTransaction(
    $input: CreateEvmSeedRefreshTransactionInput!
  ) {
    createEvmSeedRefreshTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmSeedRefreshTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmSeedRefreshTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmSeedRefreshTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmSeedRefreshTransactionMutation, { data, loading, error }] = useCreateEvmSeedRefreshTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmSeedRefreshTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmSeedRefreshTransactionDocument, options);
}
export const UpdateEvmSeedRefreshTransactionDocument = gql `
  mutation UpdateEvmSeedRefreshTransaction($txHash: String!) {
    updateEvmSeedRefreshTx(txHash: $txHash) {
      txHash
      status
    }
  }
`;
/**
 * __useUpdateEvmSeedRefreshTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmSeedRefreshTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmSeedRefreshTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmSeedRefreshTransactionMutation, { data, loading, error }] = useUpdateEvmSeedRefreshTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmSeedRefreshTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmSeedRefreshTransactionDocument, options);
}
export const CreateEvmTransferTransactionDocument = gql `
  mutation CreateEvmTransferTransaction(
    $input: CreateEvmTransferTransactionInput!
  ) {
    createEvmTransferTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmTransferTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmTransferTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmTransferTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmTransferTransactionMutation, { data, loading, error }] = useCreateEvmTransferTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmTransferTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmTransferTransactionDocument, options);
}
export const UpdateEvmTransferTransactionDocument = gql `
  mutation UpdateEvmTransferTransaction($txHash: String!) {
    updateEvmTransferTx(txHash: $txHash) {
      txHash
      status
    }
  }
`;
/**
 * __useUpdateEvmTransferTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmTransferTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmTransferTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmTransferTransactionMutation, { data, loading, error }] = useUpdateEvmTransferTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmTransferTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmTransferTransactionDocument, options);
}
export const CreateEvmWithdrawDutchAuctionFundsTransactionDocument = gql `
  mutation CreateEvmWithdrawDutchAuctionFundsTransaction(
    $input: CreateEvmWithdrawDutchAuctionFundsTransactionInput!
  ) {
    createEvmWithdrawDutchAuctionFundsTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmWithdrawDutchAuctionFundsTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmWithdrawDutchAuctionFundsTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmWithdrawDutchAuctionFundsTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmWithdrawDutchAuctionFundsTransactionMutation, { data, loading, error }] = useCreateEvmWithdrawDutchAuctionFundsTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmWithdrawDutchAuctionFundsTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmWithdrawDutchAuctionFundsTransactionDocument, options);
}
export const UpdateEvmWithdrawDutchAuctionFundsTxDocument = gql `
  mutation UpdateEvmWithdrawDutchAuctionFundsTx($txHash: String!) {
    updateEvmWithdrawDutchAuctionFundsTx(txHash: $txHash) {
      txHash
      status
    }
  }
`;
/**
 * __useUpdateEvmWithdrawDutchAuctionFundsTxMutation__
 *
 * To run a mutation, you first call `useUpdateEvmWithdrawDutchAuctionFundsTxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmWithdrawDutchAuctionFundsTxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmWithdrawDutchAuctionFundsTxMutation, { data, loading, error }] = useUpdateEvmWithdrawDutchAuctionFundsTxMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmWithdrawDutchAuctionFundsTxMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmWithdrawDutchAuctionFundsTxDocument, options);
}
export const CreateEvmSwapCurrencyTransactionDocument = gql `
  mutation CreateEvmSwapCurrencyTransaction(
    $input: CreateEvmSwapCurrencyTransactionInput!
  ) {
    createEvmSwapCurrencyTransaction(input: $input) {
      txHash
      status
    }
  }
`;
/**
 * __useCreateEvmSwapCurrencyTransactionMutation__
 *
 * To run a mutation, you first call `useCreateEvmSwapCurrencyTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvmSwapCurrencyTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvmSwapCurrencyTransactionMutation, { data, loading, error }] = useCreateEvmSwapCurrencyTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvmSwapCurrencyTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CreateEvmSwapCurrencyTransactionDocument, options);
}
export const UpdateEvmSwapCurrencyTransactionDocument = gql `
  mutation UpdateEvmSwapCurrencyTransaction($txHash: String!) {
    updateEvmSwapCurrencyTx(txHash: $txHash) {
      txHash
      status
    }
  }
`;
/**
 * __useUpdateEvmSwapCurrencyTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEvmSwapCurrencyTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvmSwapCurrencyTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvmSwapCurrencyTransactionMutation, { data, loading, error }] = useUpdateEvmSwapCurrencyTransactionMutation({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useUpdateEvmSwapCurrencyTransactionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateEvmSwapCurrencyTransactionDocument, options);
}
