var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback, forwardRef, useMemo, useState, } from "react";
import { SearchIcon, CheckVerified, CloseXSm, CheckImported, } from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { Autocomplete, Center, Group, Loader, Stack, Text, UnstyledButton, useMantineTheme, } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { debounce } from "lodash";
import { ampli } from "../../ampli";
import ProfilePlaceholderImage from "../../assets/images/profile-placeholder.png";
import { getMintPageUrl, USER_ROUTES } from "../../config";
import { formatNumber } from "../../utils/format";
import { getProfileDisplayName } from "../../utils/profile";
import { ResizedImage } from "../image";
import { CurrencyLogo } from "./CurrencyLogo";
import { useGlobalSearchQuery } from "./queries/search.graphql.generated";
const MIN_QUERY_LEN = 3;
const LOADING = "[loading]";
export const GlobalSearch = ({ mobile, cancelBtn }) => {
    const [value, setValue] = useState("");
    const [query] = useDebouncedValue(value, 200);
    const invalidQuery = !query || query.length < MIN_QUERY_LEN;
    const { data: result, loading } = useGlobalSearchQuery({
        variables: {
            query: query,
            limit: 4,
        },
        skip: invalidQuery,
    });
    const searchResults = result === null || result === void 0 ? void 0 : result.globalSearch;
    const data = useMemo(() => {
        var _a, _b;
        if (loading) {
            return [{ value: LOADING }];
        }
        return [
            ...((_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.collections) !== null && _a !== void 0 ? _a : []).map((col) => {
                var _a, _b, _c, _d;
                return ({
                    id: col.id,
                    onchainId: col.onchainId,
                    value: col.name,
                    group: "Collections",
                    imageUrl: (_c = (_a = col.logoUrl) !== null && _a !== void 0 ? _a : (_b = col.importData) === null || _b === void 0 ? void 0 : _b.logoUrl) !== null && _c !== void 0 ? _c : "",
                    creator: (_d = col.creatorAccountSettings) !== null && _d !== void 0 ? _d : undefined,
                    collection: {
                        chainId: col.chainId,
                        supply: col.supply,
                    },
                });
            }),
            ...((_b = searchResults === null || searchResults === void 0 ? void 0 : searchResults.accountSettings) !== null && _b !== void 0 ? _b : []).map((item) => {
                var _a, _b, _c, _d, _e;
                return ({
                    id: (_a = item.wallet.ensName) !== null && _a !== void 0 ? _a : item.wallet.address,
                    onchainId: null,
                    value: (_c = (_b = item.displayName) !== null && _b !== void 0 ? _b : item.wallet.ensName) !== null && _c !== void 0 ? _c : item.wallet.address,
                    group: "Profiles",
                    imageUrl: item.displayAvatar,
                    profile: {
                        verified: (_d = item.verified) !== null && _d !== void 0 ? _d : false,
                        imported: (_e = item.imported) !== null && _e !== void 0 ? _e : false,
                    },
                });
            }),
        ];
    }, [loading, searchResults === null || searchResults === void 0 ? void 0 : searchResults.collections, searchResults === null || searchResults === void 0 ? void 0 : searchResults.accountSettings]);
    const handleSelect = useCallback((item) => {
        if (item.group === "Collections") {
            window.location.href = getMintPageUrl(item);
        }
        if (item.group === "Profiles") {
            window.location.href = USER_ROUTES.user.profile.replace(":slug", item.id);
        }
        if (ampli.isLoaded) {
            ampli.searchSelect({
                itemClicked: item.value,
            });
        }
    }, []);
    const theme = useMantineTheme();
    const filter = useCallback((_value, _item) => {
        return true;
    }, []);
    const clearInput = useCallback(() => {
        setValue("");
    }, []);
    const clearBtn = value ? (_jsx(UnstyledButton, { onClick: clearInput, children: _jsx(CloseXSm, { color: theme.fn.themeColor(TEXT_COLOR.PLACEHOLDER) }) })) : null;
    const saveAnalytics = (value) => {
        if (!ampli.isLoaded) {
            return;
        }
        ampli.search({
            searchValue: value,
        });
    };
    const debounceAnalytics = useCallback(debounce(saveAnalytics, 2000), []);
    const setSearchValue = useCallback((value) => {
        setValue(value);
        debounceAnalytics(value);
    }, []);
    return (_jsx(Autocomplete, { value: value, onChange: setSearchValue, itemComponent: SearchItem, placeholder: "Search for artists or collections", data: data !== null && data !== void 0 ? data : [], filter: filter, maw: mobile ? undefined : 480, onItemSubmit: handleSelect, nothingFound: !invalidQuery && !loading ? "Nothing found" : undefined, icon: _jsx(SearchIcon, { width: 16, height: 16 }), rightSection: cancelBtn !== null && cancelBtn !== void 0 ? cancelBtn : clearBtn, mx: 12, sx: {
            flexGrow: 1,
        }, styles: {
            input: {
                marginTop: 0,
                height: mobile ? 53 : 40,
                ...(mobile && {
                    border: 0,
                }),
            },
            separatorLabel: {
                fontWeight: 500,
                color: `${theme.fn.themeColor(TEXT_COLOR.SECONDARY)} !important`,
                textTransform: "uppercase",
                "&:after": {
                    borderTop: 0,
                },
            },
            dropdown: mobile
                ? {
                    border: 0,
                    boxShadow: "none",
                }
                : {
                    minWidth: 400,
                },
        } }));
};
// next.js loads images differently
const defaultImgPath = (_a = ProfilePlaceholderImage.src) !== null && _a !== void 0 ? _a : ProfilePlaceholderImage;
const SearchItem = forwardRef(({ value, group, imageUrl, creator, collection, ...others }, ref) => {
    const theme = useMantineTheme();
    if (value === LOADING) {
        return (_jsx(Center, { h: 60, children: _jsx(Loader, {}) }));
    }
    if (group === "Collections" && collection) {
        return (_jsx("div", { ref: ref, ...others, children: _jsxs(Group, { p: 12, spacing: 10, children: [_jsx(ResizedImage, { width: 30, height: 30, src: imageUrl, styles: {
                            image: {
                                borderRadius: 6,
                            },
                        } }), _jsxs(Stack, { spacing: 0, children: [_jsx(Text, { size: "sm", children: value }), _jsxs(Group, { spacing: 6, children: [creator && (_jsxs(_Fragment, { children: [_jsx(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: getProfileDisplayName(creator.displayName, creator.walletAddresses) }), creator.verified && !creator.imported && (_jsx(CheckVerified, { height: 14, width: 14 })), creator.imported && (_jsx(CheckImported, { height: 14, width: 14 }))] })), _jsx(Text, { size: "sm", mx: 4, color: TEXT_COLOR.SECONDARY, children: " · " }), _jsx(CurrencyLogo, { chainId: collection.chainId, size: 14, color: theme.fn.themeColor(TEXT_COLOR.SECONDARY) }), collection.supply && (_jsxs(Text, { mr: 4, size: "sm", color: TEXT_COLOR.SECONDARY, children: [formatNumber(collection.supply), " Items"] }))] })] })] }) }));
    }
    if (group === "Profiles") {
        return (_jsx("div", { ref: ref, ...others, children: _jsxs(Group, { p: 12, spacing: 10, children: [_jsx(ResizedImage, { width: 30, height: 30, src: imageUrl || defaultImgPath, styles: {
                            image: {
                                borderRadius: "50%",
                            },
                        } }), _jsx(Text, { size: "sm", children: value }), (creator === null || creator === void 0 ? void 0 : creator.verified) && !creator.imported && (_jsx(CheckVerified, { height: 14, width: 14 })), (creator === null || creator === void 0 ? void 0 : creator.imported) && _jsx(CheckVerified, { height: 14, width: 14 })] }) }));
    }
    return (_jsx("div", { ref: ref, ...others, children: value }));
});
