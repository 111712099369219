import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useMemo, useState } from "react";
import SimpleIntersectionObserver from "@hl/base-components/lib/SimpleIntersectionObserver";
import { CheckCircle, CursorBox, FilterLines, SlashCircle, SwitchVertical, } from "@hl/base-components/lib/assets/icons.generated";
import { AFFIRMATIVE_COLOR, DESTRUCTIVE_COLOR, } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, Center, Flex, Group, Loader, ScrollArea, Select, Stack, Table, Text, useMantineTheme, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { NftContractStandard, OrderSortOptions, SortDirection, } from "../../../apollo/graphql.generated";
import usePercentDifference from "../../../hooks/usePercentDifference";
import { useAuth } from "../../auth";
import { User } from "../../auth/User";
import { useModalStack } from "../../modal";
import MarketplacePrice from "../components/MarketplacePrice";
import { useOwnerOffersQuery } from "../queries/marketplace.graphql.generated";
import AcceptBidModalHighlight, { AcceptBidModalTitle } from "./AcceptBidModal";
import CancelBidModalHighlight from "./CancelBidModal";
const sortingOptions = [
    {
        value: "exp_asc",
        label: "Expiring soonest",
        sortBy: OrderSortOptions.EXPIRING,
        sortDirection: SortDirection.ASC,
    },
    {
        value: "date_desc",
        label: "Newest first",
        sortBy: OrderSortOptions.EXPIRING,
        sortDirection: SortDirection.DESC,
    },
    {
        value: "price_desc",
        label: "Highest price",
        sortBy: OrderSortOptions.PRICE,
        sortDirection: SortDirection.DESC,
    },
    {
        value: "price_asc",
        label: "Lowest price",
        sortBy: OrderSortOptions.PRICE,
        sortDirection: SortDirection.ASC,
    },
    {
        value: "highest_above_floor",
        label: "Highest above floor",
        sortBy: OrderSortOptions.HIGHEST_ABOVE_FLOOR,
        sortDirection: SortDirection.DESC,
    },
];
const filteringOptions = [
    { value: "all", label: "All" },
    { value: "above_floor", label: "Above floor" },
];
export const ManageOffersModalTitle = ({ tokenName, collectionName, }) => (_jsxs(Text, { fw: WEIGHT_BOLD, children: ["Manage offers for ", tokenName, " \u00B7 ", collectionName] }));
const ManageOffersModal = ({ collectionData, tokenId, onCompleted = () => null, }) => {
    var _a, _b, _c;
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs - 1}px)`);
    const isTabletAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const [sorting, setSorting] = useState(sortingOptions[0].value);
    const [filtering, setFiltering] = useState(filteringOptions[0].value);
    const selectedSort = useMemo(() => sortingOptions.find((sortingOption) => sortingOption.value === sorting), [sorting]);
    const getAboveFloor = () => {
        if (filtering === "above_floor") {
            return true;
        }
        return null;
    };
    const { data, fetchMore, loading, refetch } = useOwnerOffersQuery({
        variables: {
            chainId: collectionData.chainId,
            createdAfter: null,
            tokenId: tokenId !== null && tokenId !== void 0 ? tokenId : "",
            tokenAddress: collectionData.address,
            aboveFloor: getAboveFloor(),
            sortDirection: selectedSort.sortDirection,
            sortBy: selectedSort.sortBy,
            after: null,
            first: 10,
            tokenOrCollectionName: null,
        },
    });
    console.log("data", data);
    const nextCursor = (_a = data === null || data === void 0 ? void 0 : data.getOwnerOffers) === null || _a === void 0 ? void 0 : _a.pageInfo.endCursor;
    const hasNextPage = (_c = (_b = data === null || data === void 0 ? void 0 : data.getOwnerOffers) === null || _b === void 0 ? void 0 : _b.pageInfo) === null || _c === void 0 ? void 0 : _c.hasNextPage;
    const bids = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.getOwnerOffers) === null || _a === void 0 ? void 0 : _a.edges) || []; }, [data]);
    const tableHeaders = (_jsxs("tr", { children: [_jsx("th", { children: "Offer Price" }), _jsx("th", { children: "Expires In" }), isTabletAndUp && _jsx("th", { children: "From" }), _jsx("th", {})] }));
    const tableRows = bids.map((bid, i) => (_jsx(ReceivedOfferRow, { bid: bid, onCompleted: () => {
            refetch();
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
        }, collectionData: collectionData }, i)));
    return (_jsxs(Stack, { children: [_jsxs(Flex, { direction: isMobile ? "column" : "row", justify: "space-between", children: [_jsx(Select, { icon: _jsx(FilterLines, {}), value: filtering, onChange: setFiltering, data: filteringOptions }), _jsx(Select, { icon: _jsx(SwitchVertical, {}), value: sorting, onChange: setSorting, data: sortingOptions })] }), loading ? (_jsx(Center, { py: "xl", children: _jsx(Loader, {}) })) : bids.length ? (_jsxs(ScrollArea, { children: [_jsxs(Table, { verticalSpacing: "md", miw: 540, sx: {
                            "thead tr th": {
                                color: theme.colors.placeholderText[0],
                                paddingLeft: 0,
                                fontSize: 12,
                            },
                            "tbody tr td": {
                                paddingLeft: 0,
                            },
                            "tbody tr td:last-child": {
                                paddingRight: 0,
                            },
                        }, children: [_jsx("thead", { children: tableHeaders }), _jsx("tbody", { children: tableRows })] }), _jsx(SimpleIntersectionObserver, { onIntersection: () => hasNextPage && fetchMore({ variables: { cursor: nextCursor } }) })] })) : (_jsx(Center, { py: "xl", children: _jsx(Text, { fw: WEIGHT_BOLD, children: "No offers created yet" }) }))] }));
};
function ReceivedOfferRow({ bid, onCompleted, collectionData, }) {
    var _a, _b, _c, _d;
    const is1155 = collectionData.standard === NftContractStandard.ERC1155;
    const { pushModal } = useModalStack();
    const theme = useMantineTheme();
    const isTabletAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const floorPrice = bid.floorPrice;
    const { isDiffPositive, diffWording } = usePercentDifference((_b = (_a = bid === null || bid === void 0 ? void 0 : bid.price) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 : _b.native, floorPrice);
    const { walletAddress } = useAuth();
    const isBidMaker = ((_c = bid === null || bid === void 0 ? void 0 : bid.makerAddress) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === (walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase());
    const token = (_d = bid === null || bid === void 0 ? void 0 : bid.tokens) === null || _d === void 0 ? void 0 : _d[0];
    return (_jsxs("tr", { children: [_jsxs("td", { children: [_jsxs(Group, { spacing: 6, children: [_jsx(MarketplacePrice, { size: "sm", price: bid.price }), is1155 && !!bid.quantityRemaining && (_jsxs(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: ["(x", bid.quantityRemaining, ")"] }))] }), _jsx(Text, { size: "sm", color: isDiffPositive
                            ? theme.colors.affirmativeStatus[0]
                            : theme.colors.warningStatus[0], children: diffWording })] }), _jsx("td", { children: bid.expiredAt && (_jsxs(_Fragment, { children: [_jsx(Text, { size: "sm", children: dayjs(bid.expiredAt).format("MMM. DD @ h:mma z") }), _jsx(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: dayjs().to(bid.expiredAt) })] })) }), isTabletAndUp && (_jsx("td", { children: _jsx(User, { enableLink: false, walletAddresses: [bid.makerAddress] }) })), _jsx("td", { align: "right", children: isBidMaker ? (_jsx(Button, { w: "100%", size: "lg", px: 12, maw: "160px", color: DESTRUCTIVE_COLOR, onClick: () => {
                        var _a, _b, _c, _d, _e;
                        return pushModal(_jsx(CancelBidModalHighlight, { collectionName: (_a = collectionData === null || collectionData === void 0 ? void 0 : collectionData.name) !== null && _a !== void 0 ? _a : "", imageUrl: token
                                ? (_b = token.imageUrl) !== null && _b !== void 0 ? _b : ""
                                : (_d = (_c = bid.collection) === null || _c === void 0 ? void 0 : _c.imageUrl) !== null && _d !== void 0 ? _d : "", id: bid.reservoirId, floorPrice: (_e = bid.floorPrice) !== null && _e !== void 0 ? _e : undefined, tokenId: token === null || token === void 0 ? void 0 : token.id, chainId: bid.chainId, onCancel: () => setTimeout(() => onCompleted(), 5000) }), {
                            size: "lg",
                            title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Cancel your offer" }),
                        });
                    }, children: _jsxs(Group, { spacing: "xs", children: [_jsx(SlashCircle, { width: 16 }), "Cancel offer"] }) })) : (_jsx(Button, { size: "lg", px: 12, color: AFFIRMATIVE_COLOR, w: "100%", maw: "160px", onClick: () => pushModal(_jsx(AcceptBidModalHighlight, { bid: bid, onCompleted: onCompleted, collectionData: collectionData }), {
                        size: "lg",
                        title: (_jsx(AcceptBidModalTitle, { address: bid.makerAddress, isCollection: bid.criteriaKind !== "token" })),
                    }), children: bid.criteriaKind === "token" ? (_jsxs(Group, { spacing: "xs", children: [_jsx(CheckCircle, { width: 16 }), " Accept"] })) : (_jsxs(Group, { spacing: "xs", children: [_jsx(CursorBox, {}), " Choose token"] })) })) })] }, bid.createdAt));
}
export default ManageOffersModal;
