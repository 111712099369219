export const CLIENT_VERSION = 1;

const getRequiredEnvVar = (name: string) => {
  const value = import.meta.env[name];
  if (!value) {
    console.error(`env var ${name} is not set`);
  }
  return value as string;
};

const getOptionalEnvVar = (name: string) => {
  const value = import.meta.env[name];
  if (!value) {
    return null;
  }
  return value as string;
};

export const envConfig = {
  graphql: {
    httpUrl: getRequiredEnvVar("REACT_APP_WEB_GRAPHQL_URL"),
  },
  isDevelopment: import.meta.env.REACT_APP_ENV !== "production",
};

const TOOLS_BASE_URL = getRequiredEnvVar("REACT_APP_TOOLS_BASE_URL");
const USER_BASE_URL = getRequiredEnvVar("REACT_APP_USER_BASE_URL");
const HOME_BASE_URL = getRequiredEnvVar("REACT_APP_HOME_BASE_URL");

/* URLs */
export const MINT_BASE_URL = getRequiredEnvVar("REACT_APP_MINT_BASE_URL");
export const MINT_APP_CDN_URL = getOptionalEnvVar("CDN_URL") || MINT_BASE_URL;
export const MINT_URL = Object.freeze({
  tokens: {
    mint: "/:collectionId",
    mintByOnChainId: "/on-chain-id/:onChainId",
    metadata: "/:collectionId/metadata",
    detail: "/:collectionId/t/:tokenId",
    detailByRevealId: "/:collectionId/tokens/:tokenId",
    reveal: "/:collectionId/reveal",
  },
});

const mintBaseUrl = new URL(MINT_BASE_URL);

const ONCHAIN_URLS_ENABLED =
  getOptionalEnvVar("REACT_APP_ONCHAIN_URLS_ENABLED") === "true";

type UrlOptions = {
  withBaseUrl?: boolean;
};

export const getMintPageUrl = (
  {
    id,
    onchainId,
  }: {
    id: string;
    onchainId: string;
  },
  {
    withBaseUrl = false,
    marketplace = false,
    reveal = false,
  }: UrlOptions & { marketplace?: boolean; reveal?: boolean } = {}
) => {
  const baseUrl = withBaseUrl ? mintBaseUrl : "";
  const query = reveal ? "/reveal" : marketplace ? "?marketplace=true" : "";

  if (ONCHAIN_URLS_ENABLED) {
    return `${baseUrl}/${onchainId}${query}`;
  }
  return `${baseUrl}/${id}${query}`;
};

export const getDetailPageUrl = (
  collection: { id: string; onchainId: string },
  tokenId: string,
  urlOptions: UrlOptions = {}
) => `${getMintPageUrl(collection, urlOptions)}/t/${tokenId}`;

export const TOOLS_URL = Object.freeze({
  collections: {
    manage: `${TOOLS_BASE_URL}/collections/:collectionId/manage`,
  },
  base: TOOLS_BASE_URL,
});

export const USER_URL = Object.freeze({
  user: {
    marketplace: {
      default: `${USER_BASE_URL}/@:slug`,
      receivedOffers: `${USER_BASE_URL}/@:slug/received-offers`,
      listings: `${USER_BASE_URL}/@:slug/listings`,
    },
  },
  base: USER_BASE_URL,
});

export const HOME_PAGE_URL = Object.freeze({
  base: HOME_BASE_URL,
});

export const EXTERNAL_URL = Object.freeze({
  SUPPORT: "https://support.highlight.xyz",
  OPENSEA: "https://opensea.io/assets",
  OPENSEA_TESTNET: "https://testnets.opensea.io/assets",
  POLYGONSCAN: "https://polygonscan.com",
  MUMBAISCAN: "https://mumbai.polygonscan.com/",
  ETHERSCAN: "https://etherscan.io",
  GOERLISCAN: "https://goerli.etherscan.io/",
  SEPOLIASCAN: "https://sepolia.etherscan.io",
  ARBISCAN: "https://arbiscan.io/",
  ARBIGOERLISCAN: "https://goerli.arbiscan.io/",
  OPTIMISMSCAN: "https://optimistic.etherscan.io/",
  OPTIMISTICGOERLISCAN: "https://goerli-optimism.etherscan.io/",
  BASEGOERLISCAN: "https://goerli.basescan.org/",
  BASESEPOLIASCAN: "https://sepolia.basescan.org/",
  HIGHLIGHT: "https://highlight.xyz",
  RANDOMIZATION_ALGO_HELP_ARTICLE:
    "https://support.highlight.xyz/knowledge-base/highlight-toolkit/other-faqs/randomization-algorithm",
  IPFS_GATEWAY: "https://ipfs.io/ipfs",
  ARWEAVE_GATEWAY: "https://arweave.net",
});

/* STORAGE KEYS */
export const STORAGE_KEYS = Object.freeze({
  auth: "highlight.auth",
  analyticsDevice: "highlight.analytics.device",
});

/* SEO Configs */
export const HIGHLIGHT_PAGE_TITLE_SIGNATURE = " | Highlight";

export const JWT_EXPIRATION_DAYS = 10; // in days
export const IN_DEVELOPMENT = import.meta.env.REACT_APP_ENV !== "production";
export const GOOGLE_ANALYTICS_ID =
  import.meta.env.REACT_APP_GOOGLE_ANALYTICS_ID || null;

export enum ApolloClientErrors {
  CLIENT_VERSION_DONT_MATCH = "ClientVersionDontMatch",
}

export const DEFAULT_ERROR_MESSAGE =
  "A server error occurred, please try again. If this error persists, contact us at support@highlight.xyz with details.";
