import { NetworkType } from "../apollo/graphql.generated";
const EXTERNAL_URL = Object.freeze({
    SUPPORT: "https://support.highlight.xyz",
    OPENSEA: "https://opensea.io/assets",
    OPENSEA_TESTNET: "https://testnets.opensea.io/assets",
    OPENSEA_COLLECTION: "https://opensea.io/collection",
    OPENSEA_TESTNET_COLLECTION: "https://testnets.opensea.io/collection",
    POLYGONSCAN: "https://polygonscan.com",
    MUMBAISCAN: "https://mumbai.polygonscan.com",
    ETHERSCAN: "https://etherscan.io",
    GOERLISCAN: "https://goerli.etherscan.io",
    SEPOLIASCAN: "https://sepolia.etherscan.io",
    ARBISCAN: "https://arbiscan.io",
    ARBIGOERLISCAN: "https://goerli.arbiscan.io",
    OPTIMISMSCAN: "https://optimistic.etherscan.io",
    OPTIMISTICGOERLISCAN: "https://goerli-optimism.etherscan.io",
    BASEGOERLISCAN: "https://goerli.basescan.org",
    BASESEPOLIASCAN: "https://sepolia.basescan.org",
    BASESCAN: "https://basescan.org",
    ZORAGOERLISCAN: "https://testnet.explorer.zora.co",
    ZORASCAN: "https://explorer.zora.energy",
    FORMASCAN: "https://explorer.forma.art",
    MANTLESCAN: "https://mantlescan.xyz",
    SCROLLSCAN: "https://scrollscan.com",
    ZK_SYNC_EXPLORER: "https://explorer.zksync.io",
    SHAPESCAN: "https://shapescan.xyz",
    CYBERSCAN: "https://cyberscan.co",
    ZERO_EXPLORER: "https://explorer.zero.network",
});
// TODO: chain, block type (tx, address, block, token)
const getBaseBlockExplorerUrl = (network) => {
    switch (network) {
        case NetworkType.ETHEREUM:
            return EXTERNAL_URL.ETHERSCAN;
        case NetworkType.GOERLI:
            return EXTERNAL_URL.GOERLISCAN;
        case NetworkType.SEPOLIA:
            return EXTERNAL_URL.SEPOLIASCAN;
        case NetworkType.MUMBAI:
            return EXTERNAL_URL.MUMBAISCAN;
        case NetworkType.POLYGON:
            return EXTERNAL_URL.POLYGONSCAN;
        case NetworkType.ARBITRUM:
            return EXTERNAL_URL.ARBISCAN;
        case NetworkType.ARBITRUM_GOERLI:
            return EXTERNAL_URL.ARBIGOERLISCAN;
        case NetworkType.OPTIMISM:
            return EXTERNAL_URL.OPTIMISMSCAN;
        case NetworkType.OPTIMISM_GOERLI:
            return EXTERNAL_URL.OPTIMISTICGOERLISCAN;
        case NetworkType.BASE:
            return EXTERNAL_URL.BASESCAN;
        case NetworkType.BASE_GOERLI:
            return EXTERNAL_URL.BASEGOERLISCAN;
        case NetworkType.BASE_SEPOLIA:
            return EXTERNAL_URL.BASESEPOLIASCAN;
        case NetworkType.ZORA:
            return EXTERNAL_URL.ZORASCAN;
        case NetworkType.ZORA_GOERLI:
            return EXTERNAL_URL.ZORAGOERLISCAN;
        case NetworkType.FORMA:
            return EXTERNAL_URL.FORMASCAN;
        case NetworkType.MANTLE:
            return EXTERNAL_URL.MANTLESCAN;
        case NetworkType.SCROLL:
            return EXTERNAL_URL.SCROLLSCAN;
        case NetworkType.ZK_SYNC_ERA:
            return EXTERNAL_URL.ZK_SYNC_EXPLORER;
        case NetworkType.SHAPE:
            return EXTERNAL_URL.SHAPESCAN;
        case NetworkType.CYBER:
            return EXTERNAL_URL.CYBERSCAN;
        case NetworkType.ZERO:
            return EXTERNAL_URL.ZERO_EXPLORER;
        default:
            return EXTERNAL_URL.ETHERSCAN;
    }
};
const buildContractUrl = (network, addr) => {
    if ([NetworkType.ZK_SYNC_ERA, NetworkType.FORMA, NetworkType.SHAPE].includes(network)) {
        return buildAddressUrl(network, addr);
    }
    else {
        return buildContractTokenUrl(network, addr);
    }
};
const buildContractTokenUrl = (network, addr) => `${getBaseBlockExplorerUrl(network)}/token/${addr}`;
const buildAddressUrl = (network, addr) => `${getBaseBlockExplorerUrl(network)}/address/${addr}`;
const buildTxUrl = (network, txHash) => `${getBaseBlockExplorerUrl(network)}/tx/${txHash}`;
const buildOnceUponUrl = (chainId, address, mintManager) => {
    return `https://onceupon.xyz/${address}:${chainId}~1001*${mintManager}`;
};
const baseOpenSeaUrl = (network) => {
    if ([
        NetworkType.GOERLI,
        NetworkType.MUMBAI,
        NetworkType.OPTIMISM_GOERLI,
        NetworkType.ARBITRUM_GOERLI,
        NetworkType.BASE_GOERLI,
        NetworkType.ZORA_GOERLI,
        NetworkType.SEPOLIA,
        NetworkType.BASE_SEPOLIA,
    ].includes(network)) {
        return EXTERNAL_URL.OPENSEA_TESTNET;
    }
    return EXTERNAL_URL.OPENSEA;
};
const baseOpenSeaCollectionUrl = (network) => {
    if ([
        NetworkType.GOERLI,
        NetworkType.MUMBAI,
        NetworkType.OPTIMISM_GOERLI,
        NetworkType.ARBITRUM_GOERLI,
        NetworkType.BASE_GOERLI,
        NetworkType.ZORA_GOERLI,
        NetworkType.SEPOLIA,
        NetworkType.BASE_SEPOLIA,
    ].includes(network)) {
        return EXTERNAL_URL.OPENSEA_TESTNET_COLLECTION;
    }
    return EXTERNAL_URL.OPENSEA_COLLECTION;
};
const buildOpenSeaCollectionUrl = (slug, network) => `${baseOpenSeaCollectionUrl(network)}/${slug}`;
const buildOpenSeaSearchUrl = (contractAddress, network) => `${baseOpenSeaUrl(network)}?search%5Bquery%5D=${contractAddress}`;
const networkByNameLookup = (network) => {
    const chainId = chainIdLookup(network || NetworkType.ETHEREUM);
    return networkLookup(chainId);
};
const networkLookup = (chainId) => {
    switch (chainId.toString()) {
        case "1":
            return {
                chainId: 1,
                type: NetworkType.ETHEREUM,
                displayName: "Ethereum",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "5":
            return {
                chainId: 5,
                type: NetworkType.GOERLI,
                displayName: "Goerli",
                currencyName: "Goerli Eth",
                currencySymbol: "GoerliETH",
            };
        case "11155111":
            return {
                chainId: 11155111,
                type: NetworkType.SEPOLIA,
                displayName: "Sepolia",
                currencyName: "Sepolia Eth",
                currencySymbol: "SepoliaETH",
            };
        case "80001":
            return {
                chainId: 80001,
                type: NetworkType.MUMBAI,
                displayName: "Mumbai",
                currencyName: "Matic",
                currencySymbol: "MATIC",
            };
        case "137":
            return {
                chainId: 137,
                type: NetworkType.POLYGON,
                displayName: "Polygon",
                currencyName: "Matic",
                currencySymbol: "MATIC",
            };
        case "42161":
            return {
                chainId: 42161,
                type: NetworkType.ARBITRUM,
                displayName: "Arbitrum",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "421613":
            return {
                chainId: 421613,
                type: NetworkType.ARBITRUM_GOERLI,
                displayName: "Arbitrum Goerli",
                currencyName: "Eth",
                currencySymbol: "AGOR",
            };
        case "10":
            return {
                chainId: 10,
                type: NetworkType.OPTIMISM,
                displayName: "Optimism",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "420":
            return {
                chainId: 420,
                type: NetworkType.OPTIMISM_GOERLI,
                displayName: "Optimism Goerli",
                currencyName: "Goerli Eth",
                currencySymbol: "GoerliETH",
            };
        case "8453":
            return {
                chainId: 8453,
                type: NetworkType.BASE,
                displayName: "Base",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "84531":
            return {
                chainId: 84531,
                type: NetworkType.BASE_GOERLI,
                displayName: "Base Goerli",
                currencyName: "Goerli Eth",
                currencySymbol: "GoerliETH",
            };
        case "84532":
            return {
                chainId: 84532,
                type: NetworkType.BASE_SEPOLIA,
                displayName: "Base Sepolia",
                currencyName: "Sepolia Eth",
                currencySymbol: "SepoliaETH",
            };
        case "7777777":
            return {
                chainId: 7777777,
                type: NetworkType.ZORA,
                displayName: "Zora",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "999":
            return {
                chainId: 999,
                type: NetworkType.ZORA_GOERLI,
                displayName: "Zora Goerli",
                currencyName: "GöETH",
                currencySymbol: "GöETH",
            };
        case "984122":
            return {
                chainId: 984122,
                type: NetworkType.FORMA,
                displayName: "Forma",
                currencyName: "TIA",
                currencySymbol: "TIA",
            };
        case "5000":
            return {
                chainId: 5000,
                type: NetworkType.MANTLE,
                displayName: "Mantle",
                currencyName: "MNT",
                currencySymbol: "MNT",
            };
        case "534352":
            return {
                chainId: 534352,
                type: NetworkType.SCROLL,
                displayName: "Scroll",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "324":
            return {
                chainId: 324,
                type: NetworkType.ZK_SYNC_ERA,
                displayName: "ZKsync Era",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "360":
            return {
                chainId: 360,
                type: NetworkType.SHAPE,
                displayName: "Shape",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "7560":
            return {
                chainId: 7560,
                type: NetworkType.CYBER,
                displayName: "Cyber",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        case "543210":
            return {
                chainId: 543210,
                type: NetworkType.ZERO,
                displayName: "ZERO",
                currencyName: "Eth",
                currencySymbol: "ETH",
            };
        default:
            return {
                chainId: 1,
                type: NetworkType.ETHEREUM,
                displayName: "Unknown Ethereum",
                currencyName: "UnkE",
                currencySymbol: "UNKE",
            };
    }
};
export const BlockchainTestnets = [
    NetworkType.GOERLI,
    NetworkType.MUMBAI,
    NetworkType.ARBITRUM_GOERLI,
    NetworkType.OPTIMISM_GOERLI,
    NetworkType.BASE_GOERLI,
    NetworkType.ZORA_GOERLI,
    NetworkType.SEPOLIA,
    NetworkType.BASE_SEPOLIA,
];
export const UnsupportedGoerliNetworks = [
    NetworkType.GOERLI,
    NetworkType.BASE_GOERLI,
    NetworkType.ARBITRUM_GOERLI,
    NetworkType.ZORA_GOERLI,
    NetworkType.OPTIMISM_GOERLI,
];
export const GoerliBasedNetworks = [
    NetworkType.GOERLI,
    NetworkType.BASE_GOERLI,
    NetworkType.ARBITRUM_GOERLI,
    NetworkType.ZORA_GOERLI,
    NetworkType.OPTIMISM_GOERLI,
    NetworkType.MUMBAI,
];
export const isTestnetNetwork = (network) => BlockchainTestnets.includes(network);
export const isGoerliTestnetNetwork = (network) => UnsupportedGoerliNetworks.includes(network);
const chainIdLookup = (network) => {
    switch (network) {
        case NetworkType.ETHEREUM:
            return 1;
        case NetworkType.GOERLI:
            return 5;
        case NetworkType.SEPOLIA:
            return 11155111;
        case NetworkType.MUMBAI:
            return 80001;
        case NetworkType.POLYGON:
            return 137;
        case NetworkType.ARBITRUM:
            return 42161;
        case NetworkType.ARBITRUM_GOERLI:
            return 421613;
        case NetworkType.OPTIMISM:
            return 10;
        case NetworkType.OPTIMISM_GOERLI:
            return 420;
        case NetworkType.BASE:
            return 8453;
        case NetworkType.BASE_GOERLI:
            return 84531;
        case NetworkType.BASE_SEPOLIA:
            return 84532;
        case NetworkType.ZORA:
            return 7777777;
        case NetworkType.ZORA_GOERLI:
            return 999;
        case NetworkType.FORMA:
            return 984122;
        case NetworkType.MANTLE:
            return 5000;
        case NetworkType.SCROLL:
            return 534352;
        case NetworkType.ZK_SYNC_ERA:
            return 324;
        case NetworkType.SHAPE:
            return 360;
        case NetworkType.CYBER:
            return 7560;
        case NetworkType.ZERO:
            return 543210;
        default:
            return 1;
    }
};
const getBlockExplorerName = (network) => {
    switch (network) {
        case NetworkType.ETHEREUM:
        case NetworkType.GOERLI:
        case NetworkType.SEPOLIA:
            return "Etherscan";
        case NetworkType.POLYGON:
        case NetworkType.MUMBAI:
            return "Polygonscan";
        case NetworkType.ARBITRUM:
        case NetworkType.ARBITRUM_GOERLI:
            return "Arbiscan";
        case NetworkType.OPTIMISM:
        case NetworkType.OPTIMISM_GOERLI:
            return "Optimistic Etherscan";
        case NetworkType.BASE:
        case NetworkType.BASE_GOERLI:
        case NetworkType.BASE_SEPOLIA:
            return "Basescan";
        case NetworkType.ZORA:
        case NetworkType.ZORA_GOERLI:
            return "Zora Explorer";
        case NetworkType.FORMA:
            return "Forma Explorer";
        case NetworkType.MANTLE:
            return "Mantlescan";
        case NetworkType.SCROLL:
            return "Scrollscan";
        case NetworkType.ZK_SYNC_ERA:
            return "zkSync Explorer";
        case NetworkType.SHAPE:
            return "Shapescan";
        default:
            return "Block Explorer";
    }
};
export { buildAddressUrl, buildContractUrl, buildContractTokenUrl, buildTxUrl, networkLookup, networkByNameLookup, chainIdLookup, getBlockExplorerName, buildOpenSeaCollectionUrl, buildOpenSeaSearchUrl, buildOnceUponUrl, };
