import { memo, useCallback, useEffect, useState } from "react";

import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { css } from "@emotion/react";
import {
  ArrowRightWhite,
  ArrowLeftWhite,
} from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { lightTheme } from "@hl/base-components/lib/theme/light/theme";
import { Metadata } from "@hl/shared-features/lib/features/gen-art/preview";
import { useResizedImageUrl } from "@hl/shared-features/lib/features/image";
import { logError } from "@hl/shared-features/lib/services/logger";
import {
  buildTxUrl,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import { Carousel, Embla } from "@mantine/carousel";
import {
  Modal,
  Text,
  Center,
  Stack,
  Button,
  Group,
  Box,
  Loader,
  createStyles,
  Flex,
} from "@mantine/core";
import { get } from "lodash";
import pRetry from "p-retry";

import { TransactionType } from "../../apollo/graphql.generated";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark.svg";
import { getDetailPageUrl } from "../../config";
import { useMintPage } from "../../hooks/useMintPage";
import {
  Modal as ModalWrapper,
  ModalType,
  modalVar,
  resetModalVar,
} from "../layout/Modal/modal";

import CollectionInfo, { EvmAddress } from "./CollectionInfo";
import { RevealFlags } from "./embed/customize";
import { useMintEmbedEvents } from "./embed/embed-events";

export interface SuccessModalData {
  imageUrl: string;
  collectionName: string;
  editionSize: number;
  creatorAddresses?: EvmAddress[] | null;
  creatorEns?: string | null;
  collectionId: string;
  onchainId: string;
  contractAddress: string;
  txHash: string;
  chainId: number;
  editionTransferability?: boolean | null;
  numTokensMinted: number;
  tokensIds?: string[] | null;
  collectionOnChainBaseUri: string | null;
  isSeries: boolean;
  isGenSeries: boolean;
  reveal: boolean | null;
  openSeaUrl: string;
  transactionType?: string;
}

type AssetProps = {
  src: string;
  onLoad?: () => void;
};
const ASSET_HEIGHT = "60vh";

const useStyles = createStyles((theme) => ({
  asset: {
    width: "100%",
    minHeight: 400,
    objectFit: "scale-down",
    [theme.fn.largerThan("sm")]: {
      maxWidth: "70vw",
      maxHeight: ASSET_HEIGHT,
    },
  },
  button: {
    backgroundColor: get(lightTheme, ["colors", "primaryButton", "1"]),
    [theme.fn.smallerThan("xs")]: {
      flex: "1 1 100%",
      maxWidth: 300,
    },
  },
}));

export const AssetImage: React.FC<AssetProps> = ({ src, onLoad }) => {
  const { classes } = useStyles(undefined);
  return (
    <img
      src={useResizedImageUrl(src, 1000)}
      className={classes.asset}
      onLoad={onLoad}
    />
  );
};

export type SuccessModalProps = ReactiveVar<ModalWrapper<SuccessModalData>>;

const SuccessModal = ({ flags = {} }: { flags?: RevealFlags }) => {
  const { classes, theme } = useStyles(undefined);
  const { showModal, data } = useReactiveVar(modalVar as SuccessModalProps);

  const txUrl = buildTxUrl(networkLookup(data.chainId).type, data.txHash);
  const [tokenIndex, setTokenIndex] = useState(0);
  const tokenIds = data.tokensIds ?? [];
  const viewOnHighlight = data.isSeries || data.isGenSeries;

  const numberMintedBox = (
    <Box>
      <Text size="sm" color={TEXT_COLOR.INVERTED_PRIMARY}>
        {data.numTokensMinted} token
        {data.numTokensMinted > 1 ? "s" : ""} minted
      </Text>
    </Box>
  );

  return (
    <Modal
      id="highlight-mint-success-modal"
      centered
      opened={showModal == ModalType.MINT_SUCCESS}
      onClose={resetModalVar}
      overlayOpacity={0.9}
      withCloseButton={true}
      size="xl"
      fullScreen
      styles={{
        modal: {
          backgroundColor: "rgba(0,0,0, 0.9)",
        },
        body: {
          backgroundColor: "transparent",
          maxHeight: "100%",
        },
        close: {
          color: theme.colors.invertedPrimaryText[0],
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "black",
          },
        },
      }}
    >
      <Stack w="100%" justify="center" align="center" spacing={8}>
        <Group noWrap spacing={8}>
          <Checkmark
            css={css`
              color: ${theme.fn.themeColor(TEXT_COLOR.INVERTED_PRIMARY)};
            `}
          />
          <Text color={TEXT_COLOR.INVERTED_PRIMARY} align="center">
            Minting complete
          </Text>
        </Group>
        <Text
          color={TEXT_COLOR.INVERTED_PRIMARY}
          weight={500}
          size={35}
          align="center"
        >
          {data.collectionName}
        </Text>
        <Center mt={24}>
          {(data.isSeries && data.reveal) || data.isGenSeries ? (
            data.tokensIds && (
              <SeriesImages
                mintedTokenIds={data.tokensIds}
                collectionOnChainBaseUri={data.collectionOnChainBaseUri}
                flags={flags}
                onSlideChange={setTokenIndex}
              />
            )
          ) : (
            <Center mb={26}>
              <AssetImage src={data.imageUrl} />
            </Center>
          )}
        </Center>
        <Center w={"100%"}>
          {flags.hideRevealCreatedBy ? (
            numberMintedBox
          ) : (
            <CollectionInfo
              editionSize={data.editionSize}
              creatorAddresses={data.creatorAddresses}
              creatorEns={data.creatorEns}
              textProps={{ color: TEXT_COLOR.INVERTED_PRIMARY }}
              nonTransferable={data.editionTransferability}
              rightContent={numberMintedBox}
            />
          )}
        </Center>
        <Center mt={26}>
          <Flex justify="center" gap={20} wrap="wrap">
            {flags.showReturnToSalesPage && (
              <Button
                onClick={() => {
                  resetModalVar();
                }}
                className={classes.button}
              >
                <Text
                  color={TEXT_COLOR.INVERTED_PRIMARY}
                  weight={300}
                  size="sm"
                >
                  Return to sale page
                </Text>
              </Button>
            )}
            <Button
              className={classes.button}
              onClick={() => window.open(txUrl, "_blank")}
            >
              <Text color={TEXT_COLOR.INVERTED_PRIMARY} weight={300} size="sm">
                View transaction
              </Text>
            </Button>
            {!flags.hideViewOnMarketplace && tokenIds.length && (
              <Button
                className={classes.button}
                onClick={() => {
                  const tokenUrl = viewOnHighlight
                    ? getDetailPageUrl(
                        { id: data.collectionId, onchainId: data.onchainId },
                        tokenIds[tokenIndex],
                        { withBaseUrl: true }
                      )
                    : data.openSeaUrl;
                  window.open(tokenUrl, "_blank");
                }}
              >
                <Text
                  color={TEXT_COLOR.INVERTED_PRIMARY}
                  weight={300}
                  size="sm"
                >
                  View on {viewOnHighlight ? "Highlight" : "OpenSea"}
                </Text>
              </Button>
            )}
          </Flex>
        </Center>
      </Stack>
    </Modal>
  );
};

type MintedTokensDisplayInfo = {
  mintedTokenIds: string[];
  collectionOnChainBaseUri: string | null;
  flags: RevealFlags;
  onSlideChange: (index: number) => void;
};

export const SeriesImages = ({
  mintedTokenIds,
  collectionOnChainBaseUri,
  onSlideChange,
  flags,
}: MintedTokensDisplayInfo) => {
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  const handleImageLoad = useCallback(() => {
    embla?.reInit();
  }, [embla]);

  const { data } = useReactiveVar(modalVar as SuccessModalProps);
  const { collectionId } = data;

  const { emitTokenRevealed } = useMintEmbedEvents({
    collectionId,
    contractAddress: data.contractAddress,
    chainId: data.chainId,
  });

  const handleReveal = useCallback(
    (tokenId: string, metadata: Metadata) => {
      emitTokenRevealed(tokenId, metadata);
      setImageUrls((urlMap) => {
        return {
          ...urlMap,
          [tokenId]: metadata.image,
        };
      });
    },
    [emitTokenRevealed, setImageUrls]
  );

  useTokenReveal(collectionId, mintedTokenIds, handleReveal);

  if (!mintedTokenIds?.length || !collectionOnChainBaseUri) return <></>;

  const hasMultipleTokens = mintedTokenIds.length > 1;

  return (
    <Stack align="center" justify="center" h={ASSET_HEIGHT}>
      <Carousel
        getEmblaApi={setEmbla}
        styles={{
          slide: {
            alignSelf: "center",
            justifySelf: "center",
            maxHeight: "60vh",
            display: "flex",
            justifyContent: "center",
          },
        }}
        slideSize={"100%"}
        align="center"
        draggable={hasMultipleTokens}
        withControls={false}
        withIndicators={false}
        onSlideChange={onSlideChange}
      >
        {mintedTokenIds.map((tokenId, index) => (
          <Carousel.Slide key={index}>
            <SeriesImage
              tokenId={tokenId}
              flags={flags}
              imageUrl={imageUrls[tokenId]}
              loadingImage={!imageUrls[tokenId]}
              onImageLoad={handleImageLoad}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
      <Group mb={20} align="center" position="center">
        {hasMultipleTokens && (
          <ArrowLeftWhite
            onClick={() => embla?.scrollPrev()}
            style={{ cursor: "pointer" }}
          />
        )}
        {hasMultipleTokens && (
          <ArrowRightWhite
            onClick={() => embla?.scrollNext()}
            style={{ cursor: "pointer" }}
          />
        )}
      </Group>
    </Stack>
  );
};

export const useTokenReveal = (
  collectionId: string,
  tokenIds: string[],
  onReveal: (tokenId: string, metadata: Metadata) => void
) => {
  const { collection } = useMintPage(collectionId);
  const collectionOnChainBaseUri = collection?.onChainBaseUri;

  const fetchMetadata = useCallback(
    async (tokenId: string) => {
      if (!collectionOnChainBaseUri) {
        return;
      }
      const response = await fetch(`${collectionOnChainBaseUri}/${tokenId}`);
      const metadata: Metadata = await response.json();
      onReveal(tokenId, metadata);
    },
    [onReveal, collectionOnChainBaseUri]
  );

  useEffect(() => {
    if (!tokenIds.length) return;
    const controller = new AbortController();
    let timeoutId: NodeJS.Timeout;
    const fetchWithDelay = async () => {
      await new Promise((resolve) => {
        timeoutId = setTimeout(resolve, 5 * 1000); // Wait before checking for metadata
      });
      tokenIds.forEach((tokenId) => {
        pRetry(() => fetchMetadata(tokenId), {
          retries: 10,
          minTimeout: 3 * 1000,
          maxTimeout: 60 * 1000,
          signal: controller.signal,
        }).catch((error) =>
          logError(error, `Error fetching metadata for token ${tokenId}`)
        );
      });
    };

    fetchWithDelay();
    return () => {
      clearTimeout(timeoutId);
      controller.abort();
    };
  }, [tokenIds, fetchMetadata]);
};

export type MintedTokenDisplayInfo = {
  tokenId: string;
  flags: RevealFlags;
  imageUrl?: string;
  loadingImage: boolean;
  onImageLoad: () => void;
};

const CrosschainRedeemMessages = [
  "Processing cross-chain burn/redeem.",
  "Burning tokens on Base…",
  "Redeeming tokens on Ethereum mainnet. This may take a while…",
];

export const SeriesImage = ({
  flags,
  imageUrl,
  loadingImage,
  onImageLoad,
}: MintedTokenDisplayInfo) => {
  const { data } = useReactiveVar(modalVar as SuccessModalProps);
  const showRedeemLoader =
    data.transactionType === TransactionType.EVM_CROSSCHAIN_BURN;
  const [redeemMessageIndex, setRedeemMessageIndex] = useState(0);
  const message =
    showRedeemLoader && CrosschainRedeemMessages[redeemMessageIndex]
      ? CrosschainRedeemMessages[redeemMessageIndex]
      : "Assigning metadata...";

  useEffect(() => {
    if (showRedeemLoader) {
      const interval = setInterval(() => {
        setRedeemMessageIndex((index) => index + 1);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [showRedeemLoader]);

  if (loadingImage)
    return (
      <Stack align="center" justify="center" w="100%" h={ASSET_HEIGHT}>
        <Loader color={TEXT_COLOR.INVERTED_PRIMARY} size={24} />
        <Text
          color={TEXT_COLOR.INVERTED_PRIMARY}
          weight={500}
          size="lg"
          align="center"
          w="100%"
        >
          {message}
        </Text>
        {!flags.hideRevealHelp && (
          <Text
            color={TEXT_COLOR.INVERTED_PRIMARY}
            size="sm"
            align="center"
            w="100%"
            maw={472}
          >
            Your token&apos;s metadata will be available shortly.
          </Text>
        )}
      </Stack>
    );

  return <AssetImage src={imageUrl!} onLoad={onImageLoad} />;
};

export default memo(SuccessModal);
