import { useEffect, useState } from "react";
import { parseEther } from "viem";
import { getRelayClient } from "../cross-chain-tx/client";
export const useCurrencyRate = ({ fromValue, fromChain, toChain, walletAddress, fromCurrency, toCurrency, tradeType, insufficientBalance, }) => {
    const [priceValue, setPriceValue] = useState(0);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!fromChain ||
            !toChain ||
            !walletAddress ||
            !fromValue ||
            !fromCurrency ||
            !toCurrency ||
            !tradeType ||
            fromChain.testnet !== toChain.testnet ||
            insufficientBalance) {
            return;
        }
        const fetchPrice = async () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            setLoading(true);
            setError("");
            try {
                const relayClient = getRelayClient(fromChain.chainId);
                const price = await relayClient.actions.getPrice({
                    user: walletAddress,
                    originChainId: fromChain.chainId,
                    destinationChainId: toChain.chainId,
                    amount: parseEther(fromValue.toString()).toString(),
                    originCurrency: fromCurrency.address,
                    destinationCurrency: toCurrency.address,
                    tradeType,
                });
                if (tradeType === "EXACT_INPUT") {
                    const priceOut = (_b = (_a = price.details) === null || _a === void 0 ? void 0 : _a.currencyOut) === null || _b === void 0 ? void 0 : _b.amountFormatted;
                    if (priceOut)
                        return setPriceValue(parseFloat(priceOut));
                }
                if (tradeType === "EXACT_OUTPUT") {
                    const priceIn = (_d = (_c = price.details) === null || _c === void 0 ? void 0 : _c.currencyIn) === null || _d === void 0 ? void 0 : _d.amountFormatted;
                    if (priceIn)
                        return setPriceValue(parseFloat(priceIn));
                }
                if ((_e = price.errors) === null || _e === void 0 ? void 0 : _e.length) {
                    setError(((_g = (_f = price.steps) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.error) ? (_j = (_h = price.steps) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.error : "");
                }
            }
            catch (err) {
                console.log(err);
                setError("Error fetching rate");
            }
            finally {
                setLoading(false);
            }
        };
        fetchPrice();
    }, [fromChain, toChain, walletAddress, fromValue, fromCurrency, toCurrency]);
    return { priceValue, error, loading };
};
