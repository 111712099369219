import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const HistoryTxFieldsFragmentDoc = gql `
  fragment HistoryTxFields on EvmTx {
    txHash
    status
    chainId
    type
    createdAt
    updatedAt
    transaction {
      from
      to
    }
    evmDeployCollectionMetadata {
      collectionId
    }
    evm721MintMetadata {
      collectionId
      mintedTokenIds
    }
    evm721BidMetadata {
      collectionId
    }
    evm721AuctionMetadata {
      collectionId
    }
    evmTransferMetadata {
      collectionId
      fromAddress
      nftContractAddress
      toAddress
      tokenId
    }
    evmUpdateRoyaltyMetadata {
      collectionId
    }
    evmSetBaseUriMetadata {
      collectionId
    }
    evmUpdateCollectionMetadata {
      collectionId
    }
    evmMintCreatorReservesMetadata {
      collectionId
    }
    evmSaleMetadata {
      collectionId
      tokenId
      nftContractAddress
      saleKind
    }
    evmDepositFundsMetadata {
      amount
      symbol
    }
    evmApproveTransferMetadata {
      nftContractAddress
      tokenId
      collectionId
    }
    evmApproveTransferWrappedMetadata {
      symbol
    }
    evmCurrencyAllowanceMetadata {
      chainId
      currency
    }
    evmSponsoredMintMetadata {
      sponsoredVectorId
      collectionId
    }
    evmCrosschainBurnMetadata {
      burnId
      collectionId
      redeemRelayerTaskId
    }
    evmSwapCurrencyMetadata {
      amount
      outputAmount
      fromCurrencySymbol
      toCurrencySymbol
      fromChain
      toChain
      isWrapping
      isSwapping
    }
  }
`;
export const GetPendingEvmTransactionsDocument = gql `
  query GetPendingEvmTransactions {
    getPendingEvmTransactions {
      ...HistoryTxFields
    }
  }
  ${HistoryTxFieldsFragmentDoc}
`;
/**
 * __useGetPendingEvmTransactionsQuery__
 *
 * To run a query within a React component, call `useGetPendingEvmTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingEvmTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingEvmTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingEvmTransactionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetPendingEvmTransactionsDocument, options);
}
export function useGetPendingEvmTransactionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetPendingEvmTransactionsDocument, options);
}
export const GetRecentEvmTransactionsDocument = gql `
  query GetRecentEvmTransactions($hours: Int!) {
    getRecentEvmTransactions(hours: $hours) {
      ...HistoryTxFields
    }
  }
  ${HistoryTxFieldsFragmentDoc}
`;
/**
 * __useGetRecentEvmTransactionsQuery__
 *
 * To run a query within a React component, call `useGetRecentEvmTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentEvmTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentEvmTransactionsQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useGetRecentEvmTransactionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetRecentEvmTransactionsDocument, options);
}
export function useGetRecentEvmTransactionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetRecentEvmTransactionsDocument, options);
}
export const CollectionForHistoryDropdownDocument = gql `
  query CollectionForHistoryDropdown($collectionId: String!) {
    getPublicCollection(collectionId: $collectionId) {
      address
      name
      chainId
    }
  }
`;
/**
 * __useCollectionForHistoryDropdownQuery__
 *
 * To run a query within a React component, call `useCollectionForHistoryDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionForHistoryDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionForHistoryDropdownQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useCollectionForHistoryDropdownQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionForHistoryDropdownDocument, options);
}
export function useCollectionForHistoryDropdownLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionForHistoryDropdownDocument, options);
}
